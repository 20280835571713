import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';


// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import IfProxyStripe from '../../IfProxyStripe';

import HeadFooter from '../HeadFooter';

import { InputAdornment } from '@mui/material';

// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const Product = (props) => {

  const [product, setProduct] = useState(0);


  const [boost, setBoost] = useState(0);
  const [rating, setRating] = useState(1);
  const [estimatedAmount, setEstimatedAmount] = useState(0);

  const [agree, setAgree] = useState(0);
  const [share, setShare] = useState(0);
  const ifProxyStripe = new IfProxyStripe();



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  }, []);


  const loadData = (props) => {


    // データコール
    ifProxy.getProductByid(params.id).then((result) => {
      setProduct(result);
      setEstimatedAmount(result.requestAmount)
      setAgree(result.agree)
    })
  }


  const stripePaymentSessionCall = () => {
    const item = {
      toUid: product.toUid,
      requestId: product.requestId,
      productId: product.id,
      estimatedAmount: estimatedAmount
    }
    ifProxyStripe.settlementRequest(item).then((result) => {
      navigate('/Product/' + params.id);
    })
  }



  const handleBoost = (event) => {
    setBoost(event.target.value);
    let boostValue = parseInt(event.target.value)

    let amountValue = parseInt(product.requestAmount)
    if ((boostValue + amountValue) < amountValue) {
      //最終金額 マイナスを入れられたら最初の値段
      setEstimatedAmount(pre => {
        return amountValue
      })

    } else {
      //最終金額
      setEstimatedAmount(pre => {
        return amountValue + boostValue || amountValue
      })

    }

  }

  // 作者だけが見れるコンポーネント
  const controlView = () => {
    if (user.uid === product.toUid) {
      return (<>
        <Card>
          <CardContent>
            <Button
              onClick={() => {
                ifProxy.deleteProduct(params.id)
                navigate('/')
              }
              }
              variant="outlined"
              color="primary">
              削除する
            </Button>
          </CardContent>

        </Card>

      </>

      )
    }

  }
  // 依頼者だけが見れるコンポーネント
  const feedbackView = () => {
    if (user.uid === product.fromUid) {
      return (<>
        <h2>評価</h2>
        <Box className={classes.leftBox}>
          <Box sx={{ margin: 2 }}>
            <label htmlFor="check">
              おしい！
            </label>
            <input
              type="checkbox"
              id="check"
              checked={rating === 0 ? true : false}
              onChange={() => setRating(prevState => 0)}
            />
          </Box>
          <Box sx={{ margin: 2 }}>
            <label htmlFor="check">
              満足
            </label>
            <input
              type="checkbox"
              id="check"
              checked={rating === 1 ? true : false}
              onChange={() => setRating(prevState => 1)}
            />
          </Box>
          <Box sx={{ margin: 2 }}>
            <label htmlFor="check">
              すごい！
            </label>
            <input
              type="checkbox"
              id="check"
              checked={rating === 2 ? true : false}
              onChange={() => setRating(prevState => 2)}
            />
          </Box>
        </Box>
        <h2>金額</h2>
        <Typography variant="body2">
          {product.requestAmount}
        </Typography>
        <h2>ブースト</h2>
        <TextField fullWidth margin="nomal" id="outlined-adornment-amount"
          label="オファー金額"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={handleBoost} variant="outlined" type="number" value={boost} sx={styleDifinition.aInputForm}
        />
        <h2>合計金額</h2>
        <h2>{estimatedAmount}</h2>
        <Typography variant="body2">
          ※ 納品された作品に対してお支払いが為されなかった場合。<br></br>
          未入金記録としてユーザー情報に記録され、未入金件数としてプロフィールから公開されます。
        </Typography>
        <h2>ステータス</h2>
        <h2>{product.status === "paied" ? "決済済み" : "支払い待ち"}</h2>
        {product.status !== "paied" && <Button
          onClick={() => {
            let result = window.confirm("支払い画面に移動します")
            if (result) {
              stripePaymentSessionCall()
              navigate('/Product/' + params.id)
            }
          }
          }
          variant="contained"
          color="primary">
          決済する
        </Button>}
      </>

      )
    }

  }




  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    const twitterShareUrl = "http://twitter.com/share?url=" + window.location.origin + '/Product/' + params.id + "&text=気になったワンドロをシェア&hashtags=ワンリク"


    return (
      <HeadFooter>

        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <Box className={classes.centerBox}>
              <CardMedia
                component="img"
                image={product.image}
                alt="product"
              />
            </Box>
            {controlView()}
            {product.status !== "paied" && feedbackView()}


            <h2>リクエスト</h2>

            <h3>リクエストの内容</h3>
            <Typography variant="body2">
              {product.requestThema}
            </Typography>
            <Button
              onClick={() => { navigate("/Request/" + product.requestId) }
              }
              variant="outlined"
              color="primary">
              リクエストの詳細を見る
            </Button>

            <h2>リアクションを送る</h2>
            <Box className={classes.leftBox}>
              <Button
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  ifProxy.productAgreeCount(params.id)
                  setAgree(pre => pre + 1)
                }
                }
                variant="outlined"
                color="primary">
                好き！
              </Button>
              <h1>{agree}</h1>
            </Box>
            <Typography variant="body2">
              ボタンを押して共感をクリエイターに伝えます<br></br>
              (賛同者は匿名です)
            </Typography>
            <Box className={classes.leftBox}>
              <Button
                sx={{
                  width: "60%",
                  margin: 1
                }}
                onClick={() => {
                  ifProxy.productShareCount(params.id, user.uid, window.location.origin + "/Product/" + params.id)
                  setShare(pre => pre + 1)
                  ifProxy.updateUserRtcount(user.uid)
                  window.open(twitterShareUrl)
                }
                }
                variant="outlined"
                color="secondary">
                シェアする <img width="50" src={`${process.env.PUBLIC_URL}/twitterIcon.png`} alt="twitterLogo"></img>
              </Button>
              <h1>{share}</h1>

            </Box>
            <Typography variant="body2">
              シェアした数はユーザーランキングに反映されます
            </Typography>


            {/** フォローボタン 依頼者をフォローする*/}
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.leftBox}>

                  <Avatar sx={{ width: 100, height: 100 }} src={product.toIcon} onClick={() => navigate('/User/' + product.toUid)} />
                  <Box >
                    クリエイター名：{product.toName}
                  </Box>
                </Box>


              </CardContent>
            </Card>

          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default Product;
const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    maxWidth: "700px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
