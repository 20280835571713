import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

import TagInput from '../comp/TagInput'

import { useDropzone } from 'react-dropzone';


// Material UI
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';

// タグインプットに関する設定
import { WithContext as ReactTags } from 'react-tag-input';
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];


// パラメータ
// params にsuggestedIdが入っている場合は、そのidのリクエストに対する投稿
// undefinedの場合は、通常の新規投稿
const Create = (props) => {

  const [listItems, setListItems] = useState(0);
  const maxSize = 10 * 1024 * 1024;

  const [imageData, setImageData] = useState(0);
  const [fileUrl, setFileUrl] = useState();

  // 登録データのフォーム
  const [forIcon, setForIcon] = useState(true);
  const [forIconAmount, setForIconAmount] = useState(0);
  const [forMaterial, setForMaterial] = useState(false);
  const [forMaterialAmount, setForMaterialAmount] = useState(0);
  const [forProcessing, setForProcessing] = useState(false);
  const [forProcessingAmount, setForProcessingAmount] = useState(0);
  const [forCommarce, setForCommarce] = useState(false);
  const [forCommarceAmount, setForCommarceAmount] = useState(0);
  const [forElse, setForElse] = useState("");
  const [sales, setSales] = useState(true);
  const [nsfw, setNsfw] = useState(false);
  const [dedicated, setDedicated] = useState(false);
  const [pv, setPv] = useState(0);
  const [bookmark, setBookmark] = useState(0);

  // 無料フラグ　free_flg
  const [freeFlg, setFreeFlg] = useState(true);
  // 月額フラグフラグ　
  const [monthlyFlg, setMonthlyFlg] = useState(false);
  // 媒体フラグ　
  const [mediaEnableFlg, setMediaEnableFlg] = useState(false);
  // 連打防止
  const [clicking, setClicking] = useState(false);

  // タグ設定系
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([
    { id: 'オリジナル', text: 'オリジナル' },
  ]);

  // searchTag系の情報
  const [janru, setJanru] = useState([]);
  const [zokusei, setZokusei] = useState([]);
  const [situation, setSituation] = useState([]);






  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();
  const ifProxy = new IfProxy();

  // クリエイター登録されているか
  const [stripeUserEnabled, setStripeUserEnabled] = useState(false);


  useEffect((props) => {
    getUserData();
    return () => {
      setTags([]);
      setSuggestions([]);
    }
  }, []);

  const getUserData = async () => {
    const userData = await ifProxy.getUserData(user.uid);
    if (userData.stripeId) {
      if (userData.stripeId != "") setStripeUserEnabled(true);

    } else {
      setStripeUserEnabled(false);
    }

  }



  // ドラッグアンドドロップからファイルアップロード
  // https://qiita.com/reika_i/items/c6ec71f16c13ca295b28

  const onDrop = useCallback((acceptedFiles) => {

    if (acceptedFiles.length > 0) {
      setImageData(acceptedFiles[0]);
      const src = URL.createObjectURL(acceptedFiles[0]);
      setFileUrl(src);
    }

  }, []);
  // ドラッグアンドドロップでアップロードファイルパスを登録
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg, image/gif, image/jpg',
    minSize: 1,
    maxSize: maxSize
  });

  // データ追加
  const postContent = () => {


    //作品投稿を実行
    //ifProxy.addKpi("addSelling", "");
    // ボタンをクリック中にする
    setClicking(true);
    const tagStrings = tags.map((data, index) => {
      return data.text
    })
    console.log("Registrating TagString")
    console.log(tags);
    console.log(tagStrings);

    const janruStrings = janru.map((data, index) => {
      return data.text
    })
    const zokuseiStrings = zokusei.map((data, index) => {
      return data.text
    })
    const situationStrings = situation.map((data, index) => {
      return data.text
    })


    let data =
    {
      uid: user.uid,
      blob: imageData,

      freeFlg: freeFlg, //商品種別: 無料公開
      monthlyFlg: monthlyFlg,//商品種別: 通常レンタル
      mediaEnableFlg: mediaEnableFlg,//商品種別: 媒体単位許諾  

      forIcon: forIcon,
      forIconAmount: forIconAmount,
      forMaterial: forMaterial,
      forMaterialAmount: forMaterialAmount,
      forProcessing: forProcessing,
      forProcessingAmount: forProcessingAmount,
      forCommarce: forCommarce,
      forCommarceAmount: forCommarceAmount,
      forElse: forElse,
      dedicated: dedicated,

      suggestedId: params.suggestedId || "",

      sales: sales,
      nsfw: nsfw,

      tags: tags, //データとして取り扱うためのマップ配列
      tagStrings: tagStrings, // 検索値として取得するための文字列配列

      // search系タグ
      janru: janru,
      zokusei: zokusei,
      situation: situation,
      janruStrings: janruStrings,
      zokuseiStrings: zokuseiStrings,
      situationStrings: situationStrings,

      pv: pv,
      bookmark: bookmark,
    }
    console.log("登録データ")
    console.log(data);
    ifProxy.postContent(data).then(async (result) => {
      setClicking(false);

      console.log("======")
      console.log("======")
      console.log("======")
      console.log(params.suggestedId)
      console.log(params.suggestedId != undefined)
      if (params.suggestedId != undefined) {
        await parcialCreate(result.id);
        navigate('/Request/' + params.suggestedId);
      } else {
        navigate('/');

      }

    })
  };
  const cancelBtn = () => {
    navigate('/');
  };

  // フォーム要素の操作
  //  アイコン使用について
  const handleForIconAmount = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    console.log(event.target.value);
    setForIconAmount(event.target.value);
  }
  const handleForIcon = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(value)
    setForIcon(event.target.value);
  }
  // 素材使用について
  const handleForMaterialAmount = (event) => {
    console.log("FORM要素:handleForMaterialAmount event.target.value =====");
    console.log(event.target.value);
    setForMaterialAmount(event.target.value);
  }
  const handleForMaterial = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(value)
    setForMaterial(event.target.value);
  }
  // 加工の許諾について
  const handleForProcessingAmount = (event) => {
    console.log("FORM要素:handleForProcessingAmount event.target.value =====");
    console.log(event.target.value);
    setForProcessingAmount(event.target.value);
  }
  const handleForProcessing = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(value)
    setForProcessing(event.target.value);
  }
  // 商用利用の許諾
  const handleForCommarceAmount = (event) => {
    console.log("FORM要素:handleForCommarceAmount event.target.value =====");
    console.log(event.target.value);
    setForCommarceAmount(event.target.value);
  }
  const handleForCommarce = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(value)
    setForCommarce(event.target.value);
  }

  const handleforElse = (event) => {
    console.log("FORM要素:forElse event.target.value =====");
    console.log(event.target.value);
    setForElse(event.target.value);
  }

  const handleNsfw = (event) => {
    console.log("FORM要素:handleNsfw event.target.value =====");
    console.log(event.target.value);
    setNsfw(event.target.value);
  }
  const handleDedicated = (event) => {
    console.log("FORM要素:handleDedicated event.target.value =====");
    console.log(event.target.value);
    setDedicated(event.target.value);
  }

  // タグ設定まわり
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  }

  const handleAddition = (tag) => {
    setTags((pre) => {
      return [...pre, tag]
    })
  }

  // 動いていないかも
  const handleDrag = (tag, currPos, newPos) => {
    const tagsData = tags;
    const newTags = tagsData.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  }

  const tokoButton = () => {
    // クリック中はdisableにする
    if (clicking) {
      return (
        <Button
          variant="outlined"
          color="primary"
          disabled
          onClick={postContent}>
          投稿する
        </Button>
      )

    } else {
      // タグがなければ登録させない
      if (tags.length === 0) {
        return (
          <Button
            variant="outlined"
            color="primary"
            disabled
            onClick={postContent}>
            投稿する(タグを設定してください)
          </Button>
        )
      } else {
        // 保存できるボタン
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={postContent}>
            投稿する
          </Button>
        )
      }

    }

  }
  const tagInputForm = () => {
    return (
      <Box
        sx={{
          fontSize: 20,
          color: 'gray',
          marginTop: 5,
          marginBottom: 5,
          border: tags.length == 0 ? "5px dashed #888" : null,
          borderColor: 'red'
        }}
      >

        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          イラストの内容説明タグ
        </Typography>
        <Typography sx={{ fontSize: 12, marginTop: 3 }} color="text.secondary" gutterBottom>
          「タグ」は、本サイト内で作品を探す唯一の検索方法になります。<br></br>この作品の名前。キャラクター名。属性名。等、探すときに手がかりとしたいキーワードを、カンマ区切り、または一つずつエンターで追加してください
        </Typography>
        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          例:  山本画伯,ツインテ,女の子,制服,アイコン,二次元でも恋がしたい,麗奈ちゃん,無料
        </Typography>
        <Typography sx={{ fontSize: 12, marginTop: 0 }} color="text.secondary" gutterBottom>
          → 作家名（あなたのペンネーム）や、検索用タグでまだ表現できていない、その他アピールすべき点（無料)など、ご自由にご利用ください
        </Typography>
        {tags.length == 0 && <Typography sx={{ fontSize: 20, marginTop: 3 }} color="red" gutterBottom>
          入力後エンターで確定してください
        </Typography>}
        <ReactTags tags={tags}
          suggestions={suggestions}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          delimiters={delimiters}
        />

        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          エンターで追加
        </Typography>

      </Box>
    )
  }
  /**
   * 検索用タグ入力コンポーネント
   * @returns 
   */
  const searchTags = () => {
    return (
      <>
        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          検索用タグ
        </Typography>
        <Typography sx={{ fontSize: 12, marginTop: 3 }} color="text.secondary" gutterBottom>
          イラストを利用しようと思った時、「欲しいイメージにあっているか」どうかで利用者は絵を探します。<br></br>
          ３つの条件を設定して、あなたの絵が欲しい誰かに届くようにしましょう<br></br>
          それぞれ複数のキーワードを登録しても問題ございません
        </Typography>
        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          ジャンル
        </Typography>
        <TagInput type="form" resultTags={(tags) => returntagJanru(tags)} suggestion={[{ id: 'ファンタジー', text: 'ファンタジー' }, { id: 'SF', text: 'SF' },]}></TagInput>
        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          キャラクターの属性や特徴
        </Typography>
        <TagInput type="form" resultTags={(tags) => returntagZokusei(tags)} suggestion={[{ id: '俺様', text: '俺様' }, { id: 'メガネ', text: 'メガネ' }, { id: '水着', text: '水着' }]}></TagInput>
        <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
          シチュエーション
        </Typography>
        <TagInput type="form" resultTags={(tags) => returntagSituation(tags)} suggestion={[{ id: '放課後', text: '放課後' }, { id: 'プール', text: 'プール' }, { id: '戦闘', text: '戦闘' }]}></TagInput>
      </>
    )
  }

  const returntagJanru = (tags) => {
    setJanru(tags)
  }
  const returntagZokusei = (tags) => {
    setZokusei(tags)
  }
  const returntagSituation = (tags) => {
    setSituation(tags)
  }



  // ファイルアップロードホバー中の表示を切り替え
  const uploadAreaStyle = useMemo(() => (
    { fileUploadStyle, ...(isDragActive ? borderDragStyle : fileUploadStyle) }
  ), [isDragActive]);

  // 無料版投稿用のフォーム
  const freemeumFormImput = () => {

    return (
      <FormControl component="fieldset" sx={{ width: "80%" }}>
        <FormGroup>
          {searchTags()}
          {tagInputForm()}



          <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
            無料
          </Typography>

          {autoInput()} {/* 利用制限の自動設定ボタン */}

          <TextField
            id="outlined-required"
            label="許可する利用範囲"
            value={forElse}
            defaultValue=""
            maxRows={4}
            onChange={handleforElse}
            placeholder="アイコン利用。動画では立ち絵にご利用いただいて構いません。Live2Dへの加工は有料コースにて許可します、無料版ではご遠慮ください。この画像を二次利用して作成したもののダウンロード販売は許可しません。利用時はクレジット表記をお願いします。その場合は次の文言を記載してください https://xxx.xxxx.com たけなか画伯"

          />
          <FormControlLabel control={<Checkbox onChange={handleNsfw} />} label="NSFW" />
          <FormControlLabel control={<Checkbox onChange={handleDedicated} disabled />} label="独占使用設定（同時に使用可能な利用者は１名限定）" />
        </FormGroup>
      </FormControl>

    )
  }

  // 通常の投稿フォーム
  const standardFormInput = () => {
    return (
      <FormControl component="fieldset" sx={{ width: "80%" }} >
        <FormGroup>
          {searchTags()}

          {tagInputForm()}

          <Typography sx={{ fontSize: 20, marginBottom: 5 }} color="text.secondary" gutterBottom>
            通常投稿<br></br>
            月額制でイラストの利用許諾をレンタルする
          </Typography>


          <FormControlLabel control={<Checkbox defaultChecked onChange={handleForIcon} />} label="SNSアイコンとしての使用" />
          <TextField
            id="outlined-required"
            label="SNSアイコンとしての使用料/月額"
            value={forIconAmount}
            type="number"
            defaultValue=""
            onChange={handleForIconAmount}
          />
          <FormControlLabel control={<Checkbox onChange={handleForMaterial} />} label="画像素材としての利用(icon以外のSNS・ブログ・動画での利用)" />
          <TextField
            id="outlined-required"
            label="素材使用料/月額"
            value={forMaterialAmount}
            type="number"
            defaultValue=""
            onChange={handleForMaterialAmount}
          />
          <FormControlLabel control={<Checkbox onChange={handleForProcessing} />} label="加工(SNS投稿時に発生するトリミング以外の加工)" />
          <TextField
            id="outlined-required"
            label="加工許可オプション/月額"
            value={forProcessingAmount}
            type="number"
            defaultValue=""
            onChange={handleForProcessingAmount}
          />
          <FormControlLabel control={<Checkbox onChange={handleForCommarce} />} label="商用利用" />
          <TextField
            id="outlined-required"
            label="商用利用/月額"
            value={forCommarceAmount}
            type="number"
            defaultValue=""
            onChange={handleForCommarceAmount}
          />
          <br></br>
          <br></br>
          {autoInput()} {/* 利用制限の自動設定ボタン */}

          <TextField
            id="outlined-required"
            label="その他使用制限"
            value={forElse}
            maxRows={4}
            defaultValue=""
            onChange={handleforElse}
            placeholder="自作発言はご遠慮ください。利用時はクレジット表記をお願いします。その場合は次の文言を記載してください https://xxx.xxxx.com たけなか画伯"

          />
        </FormGroup>
      </FormControl>

    )

  }
  const addElseText = (addText) => {
    setForElse((preSetforElse) => (
      preSetforElse + addText))
  }
  const clearElseText = () => {
    setForElse("");
  }
  const autoInput = () => {
    return (
      <>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          テンプレート
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            addElseText("・アイコンでの利用のみを許可します");;
          }}>
          ・アイコンでの利用のみを許可します
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            addElseText("・頒布する同人誌への掲載は許可しますが、レンタルした作品だけを自らの作品として頒布するのは禁止します");
          }}
        >
          ・頒布する同人誌への掲載は許可しますが、レンタルした作品だけを自らの作品として頒布するのは禁止します
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            addElseText("・政治的な主張に絡めた利用は禁止します");
          }}
        >
          ・政治的な主張に絡めた利用は禁止します
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            addElseText("・グッズへの利用は同人の範囲で許可します。商業流通での販売は禁止しますす");
          }}
        >
          ・グッズへの利用は同人の範囲で許可します。商業流通での販売は禁止します
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            addElseText("・SNS内での利用に限定してください");
          }}
        >
          ・SNS内での利用に限定してください
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            clearElseText();
          }}
        >
          クリア
        </Button>


      </>
    )
  }

  // 媒体登録の投稿フォーム
  const mediaFormImput = () => {
    return (
      <FormControl sx={{ width: "80%" }} component="fieldset">
        <FormGroup>
          {searchTags()}

          {tagInputForm()}

          <Typography sx={{ fontSize: 20, marginBottom: 5 }} color="text.secondary" gutterBottom>
            媒体利用<br></br>
            期限指定なし（権利者が公開を終了するまで）<br></br>
            掲載するメディア(１アカウント/１チャンネル)ごとに利用を許諾(許可する場合のみ登録)
          </Typography>


          <TextField
            id="outlined-required"
            label="SNSアイコンとしての使用料"
            value={forIconAmount}
            type="number"
            defaultValue=""
            onChange={handleForIconAmount}
          />
          <FormControlLabel control={<Checkbox onChange={handleForMaterial} />} label="画像素材としての利用(icon以外のSNS・ブログ・動画での利用)" />
          <TextField
            id="outlined-required"
            label="素材使用料"
            value={forMaterialAmount}
            type="number"
            defaultValue=""
            onChange={handleForMaterialAmount}
          />
          <FormControlLabel control={<Checkbox onChange={handleForProcessing} />} label="加工(SNS投稿時に発生するトリミング以外の加工)" />
          <TextField
            id="outlined-required"
            label="加工許可オプション"
            value={forProcessingAmount}
            type="number"
            defaultValue=""
            onChange={handleForProcessingAmount}
          />
          <FormControlLabel control={<Checkbox onChange={handleForCommarce} />} label="商用利用" />
          <TextField
            id="outlined-required"
            label="商用利用"
            value={forCommarceAmount}
            type="number"
            defaultValue=""
            onChange={handleForCommarceAmount}
          />
          <br></br>
          <br></br>
          {autoInput()} {/* 利用制限の自動設定ボタン */}
          <TextField
            id="outlined-required"
            label="その他使用制限"
            value={forElse}
            defaultValue=""
            maxRows={4}
            placeholder="自作発言はご遠慮ください。利用時はクレジット表記をお願いします。その場合は次の文言を記載してください https://xxx.xxxx.com たけなか画伯"
            onChange={handleforElse}
          />
          <FormControlLabel control={<Checkbox onChange={handleNsfw} />} label="NSFW" />
          <FormControlLabel control={<Checkbox onChange={handleDedicated} disabled />} label="独占使用設定（同時に使用可能な利用者は１名限定）" />


        </FormGroup>
      </FormControl>
    )
  }
  // =========================================
  // 部分変更リクエストに関連する処理 =============

  const suggestionView = () => {

    if (params.suggestedId) {
      return (
        <>
          <h2>部分変更リクエストによる投稿</h2>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              navigate('/Request/' + params.suggestedId)
            }}>
            関連するリクエスト
          </Button>
        </>
      )
    }

  }

  /**
   * 部分変更リクエストによって投稿されたillustとしての更新
   * @param {*} illustId リクエストに応じて作成されたイラストのid
   */
  const parcialCreate = async (illustId) => {

    if (params.suggestedId) {
      //リクエストにillust docidを返す
      // リクエスト側では、suggestedId として、ここで作成されたillustのidを持つ
      // requestのステータス更新はこっちでやる
      await ifProxy.settlement(params.suggestedId, illustId)
    }
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Box className={classes.leftBoxCol}>
              {suggestionView()}
              <h1>{listItems.title}</h1>
              <Box className={classes.centerBox}>
                {imageData ? <div><CardMedia
                  component="img"
                  image={fileUrl}
                  alt="投稿画像"
                  sx={{
                    width: 500,
                    padding: 2,
                    boxShadow: 1,
                    alignItems: 'center'

                  }}
                /></div> : <div {...getRootProps({
                  className: fileUploadStyle
                })} style={uploadAreaStyle}>
                  <input {...getInputProps()} />
                  <p>ここへファイルをドラッグしてアップロード (10MBまで)</p>
                </div>
                }
              </Box>

              {isDragReject ? <div className="alert alert-danger" role="alert">ファイルタイプが一致しません</div> : null}
              {fileRejections.length > 0 ? <div className="alert alert-danger" role="alert">
                {fileRejections[0].errors[0].message}
              </div> : null}
            </Box>
            <Box className={classes.leftBox}>
              <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                許諾する使用権と月額料金の設定
              </Typography>
            </Box>
            <Box className={classes.leftBox}>

              <Button
                variant={freeFlg ? "contained" : "outlined"}
                color="secondary"
                onClick={() => {
                  setFreeFlg(true);
                  setMediaEnableFlg(false);
                  setMonthlyFlg(false);
                }}>
                無料で許諾する
              </Button>
              {stripeUserEnabled && <Button
                variant={monthlyFlg ? "contained" : "outlined"}
                color="secondary"
                onClick={() => {
                  setFreeFlg(false);
                  setMediaEnableFlg(false);
                  setMonthlyFlg(true);
                }}>
                通常レンタル(月額レンタル)
              </Button>}
              {stripeUserEnabled && <Button
                variant={mediaEnableFlg ? "contained" : "outlined"}
                color="secondary"
                onClick={() => {
                  setFreeFlg(false);
                  setMediaEnableFlg(true);
                  setMonthlyFlg(false);
                }}>
                媒体(チャンネルやアカウントごとに無期限で登録)
              </Button>}
            </Box>





            <Box className={classes.leftBox}>
              {freeFlg && <Grid item xs={12} sm={12} md={12} >{freemeumFormImput()}</Grid>}
              {monthlyFlg && <Grid item xs={12} sm={12} md={12} >{standardFormInput()}</Grid>}
              {mediaEnableFlg && <Grid item xs={12} sm={12} md={12} >{mediaFormImput()}</Grid>}
            </Box>
            <Box className={classes.leftBox}>
              {tokoButton()}
            </Box>
            <Box className={classes.leftBox}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={cancelBtn}>
                キャンセル
              </Button>
            </Box>
          </Box>
        </Box>









      </HeadFooter >

    );
  }




}
export default Create;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  leftBoxCol: {
    display: 'flex',
    flexDirection: 'column',
    margin: "0 auto",
  },
  card: {
  },
  image: {
    width: 400,
  }
}));

// アップロードのためのドラッグエリア
const fileUploadStyle = {
  width: "100%",
  height: 150,
  border: "10px dotted #888",
  color: 'blue'
};
// ドラッグエリアにホバーした時のスタイル
const borderDragStyle = {
  width: "100%",
  height: 150,
  backgroundColor: '#E5E5FF',
  border: "1px solid #E5E5FF",
  transition: 'border .5s ease-in-out'
};