import React from "react";
import { useRef } from 'react';
import { useState } from 'react';

// 標準(A~E)はファイル配置の階層が深くなるため、読み込みパスに注意
import IfProxy from './../../IfProxy';
import { useAuthContext } from './../../AuthContext';

import { Link, Navigate, useNavigate } from "react-router-dom";

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function (props) {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();

  // もし既にログイン中であれば、サインアップ画面ではなくメインに飛ばす
  if (user) {
    return <Navigate to="/" />;
  } else {
    return <Login {...props} user={user} navigate={navigate} classes={classes} />;
  }
}


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      errorText: ""
    };

    this.handleEmailForm = this.handleEmailForm.bind(this);
    this.handleEmailPassword = this.handleEmailPassword.bind(this);

    console.log("USER ==== FROM ==== CONTEXT")
    console.log(this.props.user)
    this.setState({ user: this.props.user })
  }
  handleEmailForm = (event) => {
    this.setState({ email: event.target.value });

  }
  handleEmailPassword = (event) => {
    this.setState({ password: event.target.value });

  }

  handleTwitterLogin = async (e) => {
    console.log("CLICKED")
    let ifProxy = new IfProxy();
    //pvサインアップ、またはログイン数
    //ifProxy.addKpi("addSignup", "");

    ifProxy.twitterSignup().then((result) => {
      console.log("LOGIN_USER_BY_OAUTH")
      console.log(result);
      console.log("First User Check");

      ifProxy.getUserData(result.uid).then((userData) => {
        if (userData.length == 0) {
          console.log("新規ユーザ");
          console.log(userData);
          console.log(userData.length);
          this.props.navigate(`/UserEdit/${result.id}`);
        } else {
          console.log("既存ユーザ");
          this.props.navigate('/TmpTwitterToEmailLogin');
        }
      })

    })
    // 認証では初めてのユーザの場合はプロフィール作成。
    // 初めてではなければMainにコールバックで遷移させる
  }

  handleEmailLogin = async (e) => {
    console.log("CLICKED")
    let ifProxy = new IfProxy();
    //pvサインアップ、またはログイン数
    //ifProxy.addKpi("addSignup", "");

    ifProxy.login(this.state.email, this.state.password).then((result) => {
      console.log("handleEmailLogin")
      console.log(result);
      console.log("First User Check");

      ifProxy.getUserData(result.uid).then((userData) => {
        if (userData.length == 0) {
          console.log("新規ユーザ");
          console.log(userData);
          console.log(userData.length);
          this.props.navigate(`/UserEdit/${result.id}`);
        } else {
          console.log("既存ユーザ");
          this.props.navigate('../MainList');
        }
      })

    }).catch((e) => {
      console.log("ログインエラーを検知")
      console.log(e)
      this.setState({ error: true, errorText: "ログイン情報が間違っています" });


      console.log(e)
    })
    // 認証では初めてのユーザの場合はプロフィール作成。
    // 初めてではなければMainにコールバックで遷移させる
  }
  handleEmailUserCreate = async (e) => {
    console.log("CLICKED")
    let ifProxy = new IfProxy();
    //pvサインアップ、またはログイン数
    //ifProxy.addKpi("addSignup", "");

    ifProxy.createUser(this.state.email, this.state.password).then((result) => {
      console.log("LOGIN_USER_BY_EMAIL")
      console.log(result);
      console.log("First User Check");

      ifProxy.getUserData(result.uid).then((userData) => {
        if (userData.length == 0) {
          console.log("新規ユーザ");
          console.log(userData);
          console.log(userData.length);
          this.props.navigate(`/UserEdit/${result.id}`);
        } else {
          console.log("既存ユーザ");
          this.props.navigate('../MainList');
        }
      })

    }).catch((e) => {
      console.log("新規登録に失敗を検知")
      console.log(e)
      this.setState({ error: true, errorText: "メールアドレスが誤っているかすでに登録されています" });

    })
  }


  render() {

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} className={this.props.classes.logo} alt="Logo" />

          <Typography component="h1" variant="h5">
            ログイン・登録
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              id="filled-multiline-flexible"
              fullWidth
              label="メールアドレス"
              onChange={this.handleEmailForm}
              variant="filled"
              placeholder=""
              value={this.state.email}
              sx={{ marginTop: 1 }}
            />
            <TextField
              id="filled-multiline-flexible"
              fullWidth
              label="password"
              onChange={this.handleEmailPassword}
              variant="filled"
              placeholder=""
              value={this.state.password}
              sx={{ marginTop: 1 }}
            />


            <Button
              fullWidth
              disabled={(this.state.email == "" || this.state.password == "")}
              variant="contained"
              color="primary"
              onClick={this.handleEmailLogin}
              sx={{ mt: 3, mb: 2, marginTop: 5 }}
            >
              ログイン
            </Button>
            <Button
              fullWidth
              disabled={(this.state.email == "" || this.state.password == "")}
              variant="contained"
              color="primary"
              onClick={this.handleEmailUserCreate}
              sx={{ mt: 3, mb: 2, marginTop: 5 }}
            >
              新規登録
            </Button>
            {this.state.error ? <p style={{ color: 'red' }}>{this.state.errorText}</p> : ""}


            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={this.handleTwitterLogin}
              sx={{ mt: 3, mb: 2, marginTop: 5 }}
            >
              Twitter連携
            </Button>
            <Grid container>
              <Link to="/Kiyaku" className={this.props.classes.underLink}>利用規約</Link>
              <Link to="/Tokushoho" className={this.props.classes.underLink}>特定商取引法に基づく表示</Link>
              <Link to="/Privacypolicy" className={this.props.classes.underLink}>プライバシーポリシー</Link>
              ※ ユーザ登録によって利用規約への同意がなされたものとします
            </Grid>


          </Box>
        </Box>
      </Container>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  logo: {
    width: 200,
    margin: 20,
  },
  underLink: {
    margin: 5,
  },
}));
