import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@mui/icons-material/Search';



import Typography from '@mui/material/Typography';
const cardHeight = 300;
const cardWidth = 300;
const imageHeight = 150;
const cardMargin = 5;

/**
 * 
 * @returns 
 * 
 * 検索画面へのクエリパラメータ
 * navigate('/SearchedListView?キー=値');
 * 新着全件検索結果：　/SearchedListView
 * 新着フォロー中の新着：　/SearchedListView?type=follow
 * タグ検索：/SearchedListView?type=tag&word=検索値
 * 注目：/SearchedListView?type=trend (24時間の増分PVが高い)
 * フリーテキスト：/SearchedListView?type=search&word=検索値
 * ユーザ検索: /SearchedListView?type=usersearch&word=検索値
 * パラメータの取得方法
 * console.log("USE PARAMS======-")
    パスパラメータの取得：console.log(params)
    クエリパラメータの取得
    const query2 = new URLSearchParams(search);
    console.log(query2.get('test'));
    console.log(query2.get('test2'));
 */
const SearchedListView = () => {

  const [listItems, setListItems] = useState(""); //表示するカードのリストが入っている
  const [listItemData, setListItemData] = useState(""); // カードのリストの元になるデータのリスト、addの時既存データにpushする時に使う

  const [viewName, setViewName] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [searchUserName, setSearchUserName] = useState("");

  const [searchMode, setSearchMode] = useState(true);

  // ページング用、最後に表示したデータ
  const [lastVisible, setLastVisible] = useState("");

  const [nsfwFlg, setNsfwFlg] = useState(false);

  const [janru, setJanru] = useState("");
  const [zokusei, setZokusei] = useState("");
  const [situation, setSituation] = useState("");


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();

  // url及びクエリパラメータ
  const params = useParams();
  const location = useLocation();
  const search = useLocation().search;

  const ifProxy = new IfProxy();
  useEffect(() => {
    loadData();
    return () => {
      setListItems({});
      setSearchUserName({});
    }
  }, [listItems.no]);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = () => {
    const query = new URLSearchParams(search);
    console.log("クエリ:type" + query.get('type'));
    const queryType = query.get('type');
    console.log("クエリ:word" + query.get('word'));
    const queryWord = query.get('word');

    // タグ検索　/SearchedListView?type=tag
    if (queryType === "tag") {
      setViewName("タグ検索: " + queryWord);
      loadTagSearch();

      // ３軸検索
    } else if (queryType === "andSearch1") {
      //navigate(`/SearchedListView?type=andSearch1&janru=${janruE}&zokusei=${zokuseiE}&situation=${situationE}&`);
      andSearch();

    }
    // 注目：/SearchedListView?type=trend (24時間の増分PVが高い)
    else if (queryType === "trend") {
      setViewName("最新の注目投稿");
      loadTrendList();

    }
    // フォロー：/SearchedListView?type=follow 
    else if (queryType === "follow") {
      setViewName("フォロー中の新着");
      loadFollowData();

    }
    // フリーテキスト：/SearchedListView?type=search&word=検索値
    else if (queryType === "search") {
      setViewName("検索: " + queryWord);
      getIllustrationByWord();

    }
    // ユーザ検索: /SearchedListView?type=usersearch&word=検索値
    else if (queryType === "usersearch") {
      setViewName("ユーザ検索: " + queryWord);
      console.log("====")
      console.log("====")
      console.log(queryWord)
      console.log("====")
      getUserByDisplayName(queryWord);
    } else
    // 新着リスト /SearchedListView
    {
      setViewName("新着");
      loadFullData();
    }
  }

  // 検索する ===============================================
  // フリーワードフォーム
  const handleSearchForm = (event) => {
    console.log("FORM要素:handleSearchForm event.target.value =====");
    console.log(event.target.value);
    setSearchParam(event.target.value);
  }
  // ユーザを検索
  const handleSearchUserForm = (event) => {
    console.log("FORM要素:handleSearchForm event.target.value =====");
    console.log(event.target.value);
    setSearchUserName(event.target.value);

  }
  const searchAction = () => {
    console.log("searchAction");
    const encoded = encodeURIComponent(searchParam);
    navigate(`/SearchedListView?type=search&word=${encoded}`);
    window.location.reload();

  }
  const searchUserAction = () => {
    console.log("searchAction");
    const encoded = encodeURIComponent(searchUserName);
    navigate(`/SearchedListView?type=usersearch&word=${encoded}`);
    window.location.reload();

  }
  const searchAndAction = () => {
    console.log("searchAndAction");
    const janruE = encodeURIComponent(janru);
    const zokuseiE = encodeURIComponent(zokusei);
    const situationE = encodeURIComponent(situation);
    navigate(`/SearchedListView?type=andSearch1&janru=${janruE}&zokusei=${zokuseiE}&situation=${situationE}`);
    window.location.reload();

  }

  const standardSearchForm = () => {
    return (
      <Grid container alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>

        <Grid item columns={{ xs: 6, sm: 12, md: 6 }}>
          <TextField
            id="standard-basic"
            label="イラストの内容で検索する"
            margin="dense"
            type="search"
            size="small"
            defaultValue=""
            onChange={handleSearchForm}
          />
          <IconButton edge="start" onClick={searchAction} color="inherit" >
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item columns={{ xs: 6, sm: 12, md: 6 }}>
          <TextField
            id="standard-basic"
            label="ユーザ名で検索する"
            margin="dense"
            type="search"
            size="small"
            defaultValue=""
            onChange={handleSearchUserForm}
          />
          <IconButton edge="start" onClick={searchUserAction} color="inherit" >
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }

  const andSearchForm = () => {
    return (
      <Box className={classes.centerBox}>
        <Box className={classes.centerFlatBox}>

          <TextField
            sx={{ margin: "5px" }}
            id="standard-basic"
            label="ジャンル"
            type="search"
            defaultValue=""
            onChange={(event) => setJanru(event.target.value)}
          />
          <TextField
            sx={{ margin: "5px" }}
            id="standard-basic"
            label="キャラの属性"
            type="search"
            defaultValue=""
            onChange={(event) => setZokusei(event.target.value)}
          />
          <TextField
            sx={{ margin: "5px" }}
            id="standard-basic"
            label="シチュエーション"
            type="search"
            defaultValue=""
            onChange={(event) => setSituation(event.target.value)}
          />
        </Box>
        <Box

          className={classes.centerFlatBox}>
          <Button
            sx={{ width: "300px", margin: "50px" }}
            disabled={(janru + zokusei + situation === "") ? true : false}
            variant="contained"
            color="secondary"
            onClick={() => { searchAndAction() }}
          >探す</Button>
        </Box>

      </Box >
    )
  }

  // データ取得系 =======================================================
  // 全件リストを作成
  const loadFullData = () => {
    /*
    console.log("USE PARAMS======-")
    console.log(params)
    console.log(location)
    const query2 = new URLSearchParams(search);
    console.log(query2.get('test'));
    console.log(query2.get('test2'));
    */
    ifProxy.getIllustlationFullList().then(async (result) => {
      console.log(result);
      setListItemData(result);
      createSetListItems(result);
    })
  }

  // フォローしているデータを取得
  const loadFollowData = () => {
    ifProxy.getFollowIllustrationList(user.uid).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      setListItemData(result);
      createSetListItems(result);
    })
  }
  // タグ検索
  const loadTagSearch = () => {
    const query = new URLSearchParams(search);
    console.log("クエリ:word" + query.get('word'));
    const tagName = query.get('word');
    ifProxy.getIllustrationByTag(tagName).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      setListItemData(result);
      createSetListItems(result);
    })
  }

  // ３軸　タグ検索
  const andSearch = () => {
    //`/SearchedListView?type=andSearch1&janru=${janruE}&zokusei=${zokuseiE}&situation=${situationE}

    const query = new URLSearchParams(search);
    const janru = query.get('janru');
    const zokusei = query.get('zokusei');
    const situation = query.get('situation');
    ifProxy.getIllustrationByAndTag({ janru, zokusei, situation }).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      setListItemData(result);
      createSetListItems(result);
    })
  }

  // フリーワード取得
  const getIllustrationByWord = () => {
    const query = new URLSearchParams(search);
    console.log("クエリ:word" + query.get('word'));
    const word = query.get('word');
    ifProxy.getIllustrationByWord(word).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      setListItemData(result);
      createSetListItems(result);
    })
  }

  // ユーザ取得
  const getUserByDisplayName = () => {
    const query = new URLSearchParams(search);
    console.log("getUserByDisplayName クエリ:word" + query.get('word'));
    const word = query.get('word');
    ifProxy.getUserDataByDisplayName(word).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      createSetUserListItems(result);
    })
  }

  // 注目取得
  const loadTrendList = () => {
    ifProxy.getIllustrationByTrend().then(async (result) => {
      console.log(result);
      setListItemData(result);
      createSetListItems(result);
    })
  }

  // 表示リスト・カードの生成=============================================

  /**
   * 取得したリスト情報からブラックリスト系データを排除し、表示カードのリストを作成
   * @param {*} result dbから取得したリスト情報
   */
  const createSetListItems = async (result) => {

    let localstorageFlgNsfw = null;
    // まずローカルストレージからNSFWフラグを取得しフィルタをセット
    await ifProxy.checkNsfwFlg().then((result) => {
      // 更新が非同期となり安定しないため、直接変数にフラグを持つ
      localstorageFlgNsfw = result;
      setNsfwFlg(result);
    })


    const lastData = result[result.length - 1]

    setLastVisible(lastData);
    // ブラックリスト処理 
    // 表示データからブロックリストデータを排除     
    let reportList = await ifProxy.filterBlockData(result, user.uid);
    console.log("フィルタ結果")
    console.log(reportList);

    // nsfwFlgがFalseのときは nsfw=trueのデータをフィルタする
    let nsfwFilterd = [];
    if (!localstorageFlgNsfw) {
      nsfwFilterd = reportList.filter((output, index) => {
        return !output.nsfw; // falseのものは除外される
      })
    } else {
      nsfwFilterd = reportList
    }
    console.log("フィルタ結果2")
    console.log(nsfwFilterd);

    // カードを作成
    let listItems = nsfwFilterd.map((item) =>
      createCard(item)
    );
    console.log(listItems);
    setListItems(listItems);

  }

  const onClickCard = (id) => {
    console.log("カードがクリックされた")
    console.log(id);
    navigate('/Detail/' + id);
  }

  const createCard = (item) => {
    return (

      <Grid item key={item.id}>
        <Card className={classes.card} onClick={() => onClickCard(item.id)}>
          <CardContent>
            <CardMedia
              component="img"
              height={imageHeight}
              image={item.thumbnail}
              alt="イラスト"
            />
            {item.sales &&
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                販売中
              </Typography>
            }
            {!item.sales &&
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                受付停止中
              </Typography>
            }
            {item.freeFlg &&
              <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
                無料
              </Typography>
            }
            {item.mediaEnableFlg &&
              <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
                媒体単位利用
              </Typography>
            }
            {item.monthlyFlg &&
              <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
                レンタル利用
              </Typography>
            }
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              pv数 : {item.pv}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              お気に入り数: {item.bookmark}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small"
              to={`/Detail/${item.id}`}
            >詳細
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  //　ユーザのリスト・カードの生成=============================================
  /**
   * 取得したユーザリスト情報からブラックリスト系データを排除し、表示カードのリストを作成
   * @param {*} result UserData[] dbから取得したリスト情報
   */
  const createSetUserListItems = async (result) => {

    const lastData = result[result.length - 1]

    setLastVisible(lastData);
    // ブラックリスト処理 
    // 表示データからブロックリストデータを排除     
    let blockList = await ifProxy.checkBlock(user.uid)
    // ブラックリストをフィルタする
    let reportList = [];
    if (blockList != null) {
      reportList = result.filter((output, index) => {
        let result = true;

        for (let blackListData of blockList) {
          console.log(blackListData)
          // ブラックリストに含まれている場合falseにする
          if (output.uid.includes(blackListData.blockUid)) {
            result = false
          }
        }
        return result;
      })
    } else {
      // ブラックリストを登録していない場合
      reportList = result;
    }
    console.log("フィルタ結果")
    console.log(reportList);

    // カードを作成
    let listItems = reportList.map((item) =>
      createUserCard(item)
    );
    console.log(listItems);
    setListItems(listItems);

  }

  const onClickUserCard = (id) => {
    console.log("カードがクリックされた")
    console.log(id);
    navigate('/User/' + id);
  }


  const createUserCard = (item) => {
    return (

      <Grid item key={item.id}>
        <Card className={classes.card} onClick={() => onClickUserCard(item.uid)}>
          <CardContent>
            <Avatar
              src={item.photoURL}
              sx={{ width: 100, height: 100 }}
            />
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {item.displayName}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small"
              to={`/User/${item.uid}`}
            >詳細
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  // ページング ================================================
  /**
   * 追加読み込み
   */
  const onMoreLoad = () => {
    // ページングの追加ロード。オフセット設定で、Firebaseから取得し、現在のitemsListの後に追加する
    console.log("onMoreLoad")
    const query = new URLSearchParams(search);
    console.log("クエリ:type" + query.get('type'));
    const queryType = query.get('type');
    console.log("クエリ:word" + query.get('word'));
    const queryWord = query.get('word');


    // タグ検索　/SearchedListView?type=tag
    if (queryType === "tag") {
      setViewName("タグ検索: " + queryWord);
      loadTagSearchAdd();

    }
    // 注目：/SearchedListView?type=trend (24時間の増分PVが高い)
    else if (queryType === "trend") {
      setViewName("最新の注目投稿");
      // なし

    }
    // フォロー：/SearchedListView?type=follow 
    else if (queryType === "follow") {
      setViewName("フォロー中の新着");
      loadFollowDataAdd();

    }
    // フリーテキスト：/SearchedListView?type=search&word=検索値
    else if (queryType === "search") {
      setViewName("検索: " + queryWord);
      getIllustrationByWordAdd();

    }
    // ユーザ検索: /SearchedListView?type=usersearch&word=検索値
    else if (queryType === "usersearch") {
      setViewName("ユーザ検索: " + queryWord);
      // ユーザ検索はページングは無理
      // startAtをユーザ名の曖昧検索に使ってしまっているため
    } else
    // 新着リスト /SearchedListView
    {
      setViewName("新着");
      loadFullDataAdd();
    }
  }
  // フォローしているデータを取得
  const loadFollowDataAdd = () => {
    const query = new URLSearchParams(search);
    console.log("クエリ:word" + query.get('word'));
    const param = query.get('word');
    const type = "tag"
    ifProxy.getPagingIllustrationList(type, lastVisible, param).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      let updateList = listItemData;
      for (let data of result) {
        updateList.push(data);
      }
      createSetListItems(updateList);
    })
  }

  // タグ検索
  const loadTagSearchAdd = () => {
    const query = new URLSearchParams(search);
    console.log("クエリ:word" + query.get('word'));
    const param = query.get('word');
    const type = "tag"
    ifProxy.getPagingIllustrationList(type, lastVisible, param).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      let updateList = listItemData;
      for (let data of result) {
        updateList.push(data);
      }
      createSetListItems(updateList);
    })
  }

  // フリーワード取得
  const getIllustrationByWordAdd = () => {
    const query = new URLSearchParams(search);
    console.log("クエリ:word" + query.get('word'));
    const word = query.get('word');
    const type = "word"
    ifProxy.getPagingIllustrationList(type, lastVisible, word).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      let updateList = listItemData;
      for (let data of result) {
        updateList.push(data);
      }
      console.log("======updateList====")
      console.log(updateList);
      createSetListItems(updateList);
    })
  }

  // 全件リストを作成
  const loadFullDataAdd = () => {
    const param = ""
    ifProxy.getPagingIllustrationList("full", lastVisible, param).then(async (result) => {

      let updateList = listItemData;
      for (let data of result) {
        updateList.push(data);
      }

      createSetListItems(updateList);
    })
  }


  const loadMoreButton = () => {
    // もっと表示するのないページ
    const query = new URLSearchParams(search);
    console.log("クエリ:type" + query.get('type'));
    const queryType = query.get('type');
    if (queryType === 'trend' || queryType === 'usersearch') {
      return null;
    } else {
      return (
        <Button fullWidth variant="outlined" onClick={onMoreLoad}>もっと表示する</Button>
      )
    }

  }

  // 画面 =====================================================
  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {


    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Box className={classes.centerBox}>
              <Button
                sx={{ margin: "10px" }}
                variant="contained"
                color={searchMode ? "primary" : "secondary"}
                onClick={() => setSearchMode((pre) => !pre)}
              >
                あなたのイメージに近い絵を探す {searchMode ? "(標準検索)" : "(組み合わせ検索)"}
              </Button>
            </Box>
            {searchMode && standardSearchForm()}
            {!searchMode && andSearchForm()}

            <h2>{viewName}</h2>
            <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>

              {listItems}

              <Grid item
                xs={12}
                sx={{
                  padding: 2,
                  alignItems: 'center'
                }}>
                {loadMoreButton()}
              </Grid>
            </Grid>

          </Box>

        </Box>
      </HeadFooter>

    );
  }




}
export default SearchedListView;
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  centerFlatBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  loadButton: {
    padding: 1,
  },
  cardList: {
    margin: '0 auto',
  },
  card: {
    width: cardWidth,
    height: cardHeight,
    margin: cardMargin,
  },
}));