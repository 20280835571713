import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import Typography from '@mui/material/Typography';


/**
 * 
 * @param {*} props uidを入れているが、実はcontextから取っているのでいらない
 * @returns 
 */
const NotificationList = (props) => {

  const [listData, setListData] = useState(0);
  const [officialNotificationList, setOfficialNotificationList] = useState(0);
  const [offView, setOffView] = useState(false); // 公式通知と、標準の通知の表示を切り替える tが公式 Fはユーザ通知

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();


  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  }, []);

  const loadData = async (props) => {
    //console.log(location);
    let notificationList = await ifProxy.getNotification(user.uid);
    let listItems = notificationList.map((item) => {
      if (item.type === "info") {
        return createOfficialInfo(item);
      } else {
        return createCard(item);
      }

    });
    setListData(listItems);
    // 公式通知を取得
    let resultOfficial = await ifProxy.getOfficialNotification();
    console.log("======TEST")
    let officialList = resultOfficial.map((item) => {
      console.log(item)
      return createOfficialcard(item);
    });
    setOfficialNotificationList(officialList);
  }

  const createOfficialcard = (item) => {
    return (
      <Grid item xs={12} sm={12} md={12} >
        <Card className={classes.card}>
          <CardContent>

            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {item.discription}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )

  }

  const openUser = (param) => {
    // ユーザ詳細に遷移する
    navigate('/User/' + param.fromUid);


  }
  const openDetail = (param) => {
    // 投稿詳細に遷移する
    navigate('/Detail/' + param.fromIllustrationId);
  }
  const readFlg = (param) => {
    // 既読にする
    ifProxy.readNotification(user.uid).then(() => {
      window.location.reload();
    });
  }

  //  type: 投稿の種別 [info(公式通知), follow(フォローされた), bookmark(ブックマークされた), fav(いいねされた) newUser（新たにレンタルが開始される）]
  const createCard = (data) => {
    return (
      <Grid item xs={12} sm={12} md={12} >
        <Card className={classes.card}>
          <CardContent>
            {data.read === false &&
              <Typography variant="body2" sx={{ fontColor: 'red', wordWrap: 'break-word' }}>
                新着
              </Typography>
            }
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {data.type === "close" && "公開終了の作品があります。本作品は来月以降は自動的に請求・課金が終了します。本作品の使用許諾は、既に決済ずみの使用期限までで終了となります。掲載等の使用を行っている場合は、期日までに差し替え等を完了してください"}
              {data.type === "newUser" && "ユーザを獲得しました"}
              {data.type === "follow" && "次のユーザからフォローされました"}
              {data.type === "bookmark" && "次の作品がブックマークされました"}
              {data.type === "fav" && "次の作品がいいね！されました"}
              {data.type === "request" && "リクエストを受け取りました"}
              {data.type === "requestAnswerd" && "リクエストに回答がありました"}
              {data.type === "parcialRequest" && data.infomation}


            </Typography>

            <Avatar src={data.fromPhotoURL} sx={{ width: 100, height: 100 }} />
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {data.fromDisplayName}
            </Typography>

            {data.type === "follow" &&
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={() => openUser(data)}
              >
                フォローしたユーザを見る
              </Button>
            }
            {data.type === "bookmark" &&
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={() => openDetail(data)}
              >
                ブックマークされた作品
              </Button>
            }
            {data.type === "fav" &&
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={() => openDetail(data)}
              >
                いいね！された作品
              </Button>
            }
            {data.type === "close" &&
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={() => openDetail(data)}
              >
                公開終了となった作品
              </Button>
            }
            {data.type === "newUser" &&
              <Button
                variant="contained"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={() => openDetail(data)}
              >
                レンタルを開始した作品
              </Button>
            }
            {data.type === "request" &&
              < p >
                {data.infomation}
              </p>

            }
            {data.type === "request" && <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 2 }}
              onClick={() => navigate(`/user/${data.uid}`)}
            >
              プロフィールへ
            </Button>}
            {data.type === "requestAnswerd" &&
              < p >
                {data.infomation}
              </p>

            }
            {data.type === "requestAnswerd" && <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 2 }}
              onClick={() => navigate(`/user/${data.uid}`)}
            >
              プロフィールへ
            </Button>}

            {data.type === "parcialRequest" && <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 2 }}
              onClick={() => navigate(`/Request/${data.requestId}`)}
            >
              部分変更リクエスト
            </Button>}

          </CardContent>
        </Card>
      </Grid >
    )
  }
  const createOfficialInfo = (data) => {
    return (
      <Grid item xs={12} sm={12} md={12} >
        <Card className={classes.card}>
          <CardContent>
            {data.read === false &&
              <Typography variant="body2" sx={{ fontColor: 'red', wordWrap: 'break-word' }}>
                新着
              </Typography>
            }
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              お知らせがあります
            </Typography>
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {data.infomation}
            </Typography>

          </CardContent>
        </Card>
      </Grid>
    )
  }

  const showOfficial = () => {
    setOffView(true);
  }
  const closeOfficial = () => {
    setOffView(false);
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>

            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <h2>お知らせ</h2>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ margin: 2 }}
                onClick={() => readFlg()}
              >
                既読にする
              </Button>
              {!offView ?
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ margin: 2 }}
                  onClick={() => showOfficial()}
                >
                  公式のお知らせ
                </Button>
                :
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ margin: 2 }}
                  onClick={() => closeOfficial()}
                >
                  通知を表示する
                </Button>
              }
              {!offView ? listData : officialNotificationList}

            </Grid>
          </Box>
        </Box>
      </HeadFooter>
    )
  }
}
export default NotificationList;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
