import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import ProfileComp from '../comp/ProfileComp';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { WithContext as ReactTags } from 'react-tag-input';




const Controll = (props) => {


  return (
    <>
      <Typography>
        test
      </Typography>
    </>

  );
}
export default Controll;


const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
