import React from "react";
import { useRef } from 'react';
import { useState } from 'react';

// 標準(A~E)はファイル配置の階層が深くなるため、読み込みパスに注意
import IfProxy from '../../IfProxy';
import { useAuthContext } from '../../AuthContext';

import { Link, Navigate, useNavigate } from "react-router-dom";

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function (props) {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const classes = useStyles();

    // もし既にログイン中であれば、サインアップ画面ではなくメインに飛ばす
    if (user) {
        if (user.email == "") {
            // メールアドレスが未登録なら登録画面へ
            return <TmpTwitterToEmailLogin {...props} user={user} navigate={navigate} classes={classes} />;
        } else {
            // メール登録ができていればメイン画面へ
            return <Navigate to="/" />;

        }

    } else {
        return <Navigate to="/Login" />;
    }
}

/* 認証プロバイダ移行用 */
class TmpTwitterToEmailLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
        };
        this.handleEmailForm = this.handleEmailForm.bind(this);
        this.handleEmailPassword = this.handleEmailPassword.bind(this);

        console.log("USER ==== FROM ==== CONTEXT")
        console.log(this.props.user)
        this.setState({ user: this.props.user })
    }
    handleEmailForm = (event) => {
        this.setState({ email: event.target.value });

    }
    handleEmailPassword = (event) => {
        this.setState({ password: event.target.value });

    }



    updateEmailProvider = async (e) => {
        console.log("TwitterプロバイダにEmailプロバイダを関連づける")
        let ifProxy = new IfProxy();
        //pvサインアップ、またはログイン数
        //ifProxy.addKpi("addSignup", "");

        ifProxy.linkTwitterAuthProviderToEmailProvider(this.state.email, this.state.password).then((result) => {
            console.log("updateEmailProvider")
            console.log(result);
            this.props.navigate('/TmpTwitterToEmailLogin2');

        })
    }

    render() {

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Typography component="h1" variant="h5">
                        アカウントにメールアドレスとパスワードを登録する
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            id="filled-multiline-flexible"
                            fullWidth
                            label="メールアドレス"
                            onChange={this.handleEmailForm}
                            variant="filled"
                            placeholder=""
                            value={this.state.email}
                            sx={{ marginTop: 1 }}
                        />
                        <TextField
                            id="filled-multiline-flexible"
                            fullWidth
                            label="password"
                            onChange={this.handleEmailPassword}
                            variant="filled"
                            placeholder=""
                            value={this.state.password}
                            sx={{ marginTop: 1 }}
                        />


                        <Button
                            fullWidth
                            disabled={(this.state.email == "" || this.state.password == "")}
                            variant="contained"
                            color="primary"
                            onClick={this.updateEmailProvider}
                            sx={{ mt: 3, mb: 2, marginTop: 5 }}
                        >
                            メールアドレス認証をアカウントと紐づける
                        </Button>
                    </Box>
                </Box>
            </Container>
        );



    };
}

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    logo: {
        width: 200,
        margin: 20,
    },
    underLink: {
        margin: 5,
    },
}));
