import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../AuthContext';
import IfProxy from '../IfProxy';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { teal } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// 標準の画面大枠レイアウト
const StandardPage = () => {
  // コンポーネントのデザインオブジェクト（いつもレイアウトしてるやつ）はここに全部入っている
  const classes = useStyles();


  const [listItems, setListItems] = useState(0);
  const [showDrower, setShowDrower] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    loadData();
  }, [listItems.no]);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = () => {
    const ifProxy = new IfProxy();
    ifProxy.getData().then((result) => {
      /*
      let listItems = result.map((item) =>
        <li>{item.no} : {item.text}</li>
      );
      */
      let listItems = result.map((item) =>
        createCard(item)
      );
      setListItems(listItems);
    })
  }

  const createCard = (item) => {
    return (
      <Card sx={{ minWidth: 275, margin: 5 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            データ番号: {item.no}
          </Typography>
          <Typography variant="h5" component="div">
            testtesttesttesttesttesttesttesttesttesttesttest
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            adjective
          </Typography>
          <Typography variant="body2">
            テキスト内容 : {item.text}
            <br />
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Learn More</Button>
        </CardActions>
      </Card>
    )
  }

  // データ追加
  const addData = () => {
    let data =
    {
      no: 1,
      text: "testdata1"
    }
    const ifProxy = new IfProxy();
    ifProxy.insertData(data).then((result) => {

    })
  };

  const toggleOpen = () => {
    setShowDrower(!showDrower);
  }




  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    const theme = createTheme();

    return (
      <ThemeProvider theme={theme} >
        <Drawer
          anchor='right'
          open={showDrower}
          docked={false}
          width={200}
        //onRequestChange={this.handleToggle}
        >
          <MenuItem>アカウント設定</MenuItem>
          <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
          <Box sx={{
            marginTop: 10,
            display: 'flex',
            borderTop: 1,
            borderColor: "#CCCCCC"
          }}>
            <MenuItem onClick={toggleOpen}>閉じる</MenuItem>
          </Box>

        </Drawer>
        <AppBar position="static" className={classes.header}>
          <Toolbar>

            <Typography variant="h6" className={classes.applicationTitle}>
              テストアプリ
            </Typography>
            <Box className={classes.headContent} textAlign="left">Sample test  test</Box>
            <Box className={classes.headContent} textAlign="left">Sample</Box>
            <Box className={classes.headContent} textAlign="left">Sample</Box>
            <Button color="inherit" variant="outlined">Login</Button>
            <IconButton edge="start" onClick={toggleOpen} className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div>
              <h1>MainList</h1>
              <ul>
                {listItems}
              </ul>
            </div>
            <Button variant="contained" className={classes.button} onClick={addData}>データ追加</Button>
          </Box>


        </Container>
        <Box sx={{
          marginTop: 10,
          padding: 2,
          display: 'flex',
          borderTop: 1,
          borderColor: "#CCCCCC",
          height: 100
        }}>
          <a href="#" className={classes.link} > 利用規約</a>
          test
          <a href="#" className={classes.link} > プライバシーポリシー</a>
        </Box>
      </ThemeProvider>

    );
  }

}
export default StandardPage;

// シンプルにカラーを設計する手順
// コンポーネント自体の色は、MainとSubの色を使い、強調が必要な箇所はAccentを使う
//　上に載せられる（被らない）文字の色を labelとして定義しておく
// カラー選択は、muiのカラーパターンから３色のパターンを選ぶ

// muiのカラーパターン
// https://mui.com/customization/color/
// primary
//  Main 9575cd
//  sub aa90d7
//  accent 68518f
//  label fff
//
// secondary
//  Main ef5350
//  sub f27573
//  accent a73a38
//  label fff

// グラデーションの出し方
//https://developer.mozilla.org/ja/docs/Web/CSS/CSS_Images/Using_CSS_gradients
// 各コンポーネントのデザインはここ
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  root: {
    flexGrow: 1,
  },
  // 各部分とその中身
  header: {
    background: 'linear-gradient(to bottom right,#9575cd,#f27573)',
    color: "#fff"
  },
  menuButton: {
    marginRight: 10,
  },
  loginBtn: {
    color: "#00bcd4"
  },
  applicationTitle: {
    flexGrow: 1,
  },
  headContent: {
    margin: 5,
  },
  // Footer
  link: {
    margin: 5,
  },

  // コンテンツ部分
  // メイン
  button: {
    width: 200,
    borderRadius: 30,
    color: "#fff",
    background: "#f27573",
  }
  // サイドバー
}));
