import React, {useState, useEffect} from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import ProfileComp from '../comp/ProfileComp';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const CardList = () => {

  const [listItems, setListItems] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();


  useEffect(() => {
    loadData();
  },[listItems.no]);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = () => {
    const ifProxy = new IfProxy();
    ifProxy.getData().then((result) => {
      /*
      let listItems = result.map((item) =>
        <li>{item.no} : {item.text}</li>
      );
      */
      let listItems = result.map((item) =>
        createCard(item)
      );
      setListItems(listItems);
    })
  }

  const onClickCard = (id) => {
    console.log("カードがクリックされた")
    navigate('/Detail/'+id);
  }

  const createCard = (item) => {
      return(
        <Grid item >
          <Card className={classes.card} onClick={() => onClickCard("test111111")}>
            <CardContent>
              <CardMedia
                component="img"
                height="100"
                image={`${process.env.PUBLIC_URL}/logo512.png`}
                alt="green iguana"
              />
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                データ番号: {item.no}
              </Typography>
              <Typography variant="h5" component="div">
                testtesttesttesttesttesttesttesttesttesttesttest
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                adjective
              </Typography>
              <Typography variant="body2">
                テキスト内容 : {item.text}
                <br />
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions>
          </Card>
        </Grid>
    )
  }

  // データ追加
  const addData = () => {
    let data =
      {
      no: 1,
      text: "testdata1"
      }
    const ifProxy = new IfProxy();
    ifProxy.insertData(data).then((result) => {

    })
  };

  const onMoreLoad = () => {
    // ページングの追加ロード。オフセット設定で、Firebaseから取得し、現在のitemsListの後に追加する
    console.log("onMoreLoad")
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <h1>MainList</h1>
              <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>
                {listItems}
                <Grid item
                  xs={12}
                  sx={{
                    padding:2,
                    alignItems:'center'
                }}>
                  <Button fullWidth variant="outlined"  onClick={onMoreLoad}>もっと表示する</Button>
                </Grid>

              </Grid>
          </Box>
          <Box sx={{
            width:200,
            height:500,
            marginTop:10,
            padding:2,
            boxShadow: 1,
            alignItems:'center'

          }}>
            <ProfileComp uid={user.uid}/>
          </Box>
        </Box>

      </HeadFooter>

    );
  }




}
  export default CardList;

  const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper:{
      display: 'flex'
    },
    mainBox:{

    },
    loadButton:{
      padding:1,
    },
    cardList: {
      margin:'0 auto',
    },
    card:{
      width:250,
      height:200,
      margin:20,
    },
  }));
