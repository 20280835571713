import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import ProductCard from '../comp/ProductCard';


// Material UI
import { makeStyles } from '@mui/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();


const Request = (props) => {

  const REQUESTSTATUS = Object.freeze({
    UNENTRUSTED: "UNENTRUSTED",
    ENTRUSTED: "ENTRUSTED",
    UNSETTLEMENT: "UNSETTLEMENT",
    COMPLETE: "COMPLETE",
    CLOSE: "CLOSE"

  })


  const [requestdata, setRequestData] = useState({});
  const [id, setId] = useState(0);
  const [status, setStatus] = useState("UNENTRUSTED");
  const [targetIllust, setTargetIllust] = useState(0);
  const [suggestedIllust, setSuggestedIllust] = useState(0);


  const [loading, setLoading] = useState(true);


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
    setLoading(true)

  }, []);


  const loadData = (props) => {


    // データコール
    console.log(params.id)
    ifProxy.getRequestByid(params.id).then((requestData) => {

      setRequestData(requestData);
      setId(requestData.id);
      setStatus(requestData.status)

      ifProxy.getIllustlation(requestData.targetId).then((result) => {
        setTargetIllust(result)
        setLoading(false);
      })

      if (requestData.suggestedId != "") {
        ifProxy.getIllustlation(requestData.suggestedId).then((result) => {
          setSuggestedIllust(result)
        })
      }

    })
  }

  // 決済リンクを作成
  const goStripeSettlement = () => {
    ifProxy.pacialRequestSettlement({ requestId: id, toStripeId: requestdata.stripeId, amount: requestdata.amount })

  }

  /**
   * ステータス変更時に行うアクション
   * @param {*} toStatus 
   */
  const statusUpdateAction = async (toStatus) => {
    const fromRequestUser = { //依頼者
      uid: requestdata.fromUid,
      displayName: requestdata.fromName,
      icon: requestdata.fromIcon,
    }
    const toRequestUser = { //クリエイター
      uid: requestdata.toUid,
      displayName: requestdata.toName,
      icon: requestdata.toIcon,
    }
    switch (toStatus) {
      // これからなるステータスなので、受諾かリクエストキャンセル以降
      case REQUESTSTATUS.ENTRUSTED:
        //entrusted 受諾する
        await ifProxy.entrusted(id)
        //作家から　リクエストした人に通知する
        notify({ toUid: fromRequestUser.uid, fromUid: toRequestUser.uid, FromDisplayName: toRequestUser.displayName, fromPhotoURL: toRequestUser.toIcon, infomation: "リクエストが受諾されました" })
        window.location.reload()
        break;

      case REQUESTSTATUS.UNSETTLEMENT:
        // 納品する。未入金ステータスに遷移
        // ステータス更新はcreate側でやる
        notify({ toUid: fromRequestUser.uid, fromUid: toRequestUser.uid, FromDisplayName: toRequestUser.displayName, fromPhotoURL: toRequestUser.toIcon, infomation: "リクエストが納品されました" })
        navigate("/Create/" + params.id)


        break;
      case REQUESTSTATUS.COMPLETE:
        // 入金が完了
        // await ifProxy.completed(id) ステータスの実際の更新はwebhookで実施
        goStripeSettlement();
        notify({ toUid: toRequestUser.uid, fromUid: fromRequestUser.uid, FromDisplayName: fromRequestUser.displayName, fromPhotoURL: fromRequestUser.toIcon, infomation: "まもなく支払いが行われます。口座確認から入金をご確認ください" })
        //window.location.reload()


        break;
      case REQUESTSTATUS.CLOSE:
        // キャンセル
        await ifProxy.close(id)
        window.location.reload()

        break;



      default:
        break;
    }
  }

  const notify = ({ toUid, fromUid, FromDisplayName, fromPhotoURL, infomation }) => {
    // 通知
    const noti = {
      uid: toUid, //通知の宛先
      fromUid: fromUid, // 自分
      fromDisplayName: FromDisplayName,
      fromPhotoURL: fromPhotoURL,
      requestId: params.id,
      infomation: infomation,
      type: "parcialRequest",
    }
    ifProxy.createNotification(noti);
  }


  // アクセス制御ありコンポーネント
  // 依頼者だけが見れるコンポーネント
  const requestCancelView = () => {
    if (user.uid === requestdata.fromUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => statusUpdateAction(REQUESTSTATUS.CLOSE)}
            variant="contained"
            color="secondary">
            リクエストをキャンセルする
          </Button>
        </>
      )
    }

  }

  // 受諾者だけが見れるコンポーネント
  //entrusted 受諾に遷移
  const entrusting = () => {
    if (user.uid === requestdata.toUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => {
              statusUpdateAction(REQUESTSTATUS.ENTRUSTED)
            }}
            variant="contained"
            color="secondary">
            承認する
          </Button>
        </>
      )
    }
  }

  // 納品済み支払い待ちにステータス変更。納品する
  const unsettlement = () => {
    if (user.uid === requestdata.toUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => statusUpdateAction(REQUESTSTATUS.UNSETTLEMENT)}
            variant="contained"
            color="secondary">
            納品する
          </Button>
        </>
      )
    }
  }

  // 納品済み支払い待ちにステータス変更。納品する
  const completed = () => {
    if (user.uid === requestdata.fromUid) {
      return (
        <>
          <Button
            sx={{
              margin: 1
            }}
            fullWidth
            onClick={() => statusUpdateAction(REQUESTSTATUS.COMPLETE)}
            variant="contained"
            color="secondary">
            決済する
          </Button>
        </>
      )
    }
  }

  // 依頼者と受諾者だけが見れるコンポーネント
  const offerView = () => {
    if (user.uid === requestdata.toUid || user.uid === requestdata.fromUid) {
      return (<>
        <h2>オファー金額</h2>
        <Typography variant="body2">
          {requestdata.amount}
        </Typography>
      </>

      )
    }

  }
  // 誰でも見れるコンポーネント
  const statusView = () => {
    /*
    unentrusted 未受諾
    entrusted 受諾
    completed 完了
    requestCanceled キャンセル
    creatorCanceled 作家判断キャセル
    */

    return (
      <>
        <h2>ステータス</h2>
        <h1>
          {status === REQUESTSTATUS.UNENTRUSTED && "リクエスト中"}
          {status === REQUESTSTATUS.ENTRUSTED && "承認済み。製作中"}
          {status === REQUESTSTATUS.UNSETTLEMENT && "納品済み。リクエストユーザー支払い待ち"}
          {status === REQUESTSTATUS.COMPLETE && "完了"}
        </h1>
      </>
    )
  }


  // ステータスごとの表示制御
  const actionByStatus = () => {

    let conponentView = "";

    // ステータスごとに出すボタンアクションを入れる
    switch (status) {
      //unentrusted リクエスト中
      case REQUESTSTATUS.UNENTRUSTED:
        conponentView = (<>
          {entrusting() /* 受諾できる人だけが見える受諾者ボタン */}
          {requestCancelView() /* リクエストした人が受諾前までだけできるキャンセルボタン */}
        </>)
        break;

      case REQUESTSTATUS.ENTRUSTED:
        //entrusted 受諾
        conponentView = (
          <>
            {unsettlement() /* 受諾できる人だけが見える受諾者ボタン */}
          </>
        )
        break;

      case REQUESTSTATUS.UNSETTLEMENT:
        //completed 完了
        conponentView = (
          <>
            <h2>完了：納品済み 決済待ち</h2>
            {completed() /* 依頼者だけが見える支払いボタン */}


          </>
        )
        break;
      case REQUESTSTATUS.COMPLETE:
        //completed 完了
        conponentView = (
          <>
            <h2>完了：支払い完了</h2>
          </>
        )
        break;
      default:
        break;

    }
    return conponentView;
  }




  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else if (status === REQUESTSTATUS.CLOSE) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>リクエストはキャンセルされました</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>

          <Box className={classes.mainBox} >
            <Box className={classes.centerBox} >
              <Avatar sx={{ width: 100, height: 100 }} src={requestdata.fromIcon} />

            </Box>

            <h1>{requestdata.fromName} からの部分変更リクエスト</h1>
            <h2>対象の作品</h2>
            <Box className={classes.centerBox} >
              <CardMedia
                component="img"
                sx={{ width: "200px" }}
                image={targetIllust.thumbnail}
                alt="product"
              />
            </Box>


            <h2>変更差分の要望内容</h2>
            <Typography variant="body2">
              {requestdata.thema}
            </Typography>
            {offerView()}
            <h2>支払い条件</h2>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {requestdata.tochu ? "【イメージに合えば報酬を支払う】" : "　【必ず報酬を支払う】"}
            </Typography>
            <Box className={classes.centerBox} >
              {statusView() /* 誰でも見れる。ステータスの表示 */}
              {actionByStatus()}

            </Box>

            {suggestedIllust && <Box className={classes.centerBox} >
              <h2>投稿された作品</h2>
              <CardMedia
                component="img"
                sx={{ width: "200px" }}
                image={suggestedIllust.thumbnail}
                alt="product"
                onClick={() => navigate('/Detail/' + suggestedIllust.id)}
              />
            </Box>}

            {/** フォローボタン 依頼者をフォローする*/}
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.leftBox}>

                  <Avatar sx={{ width: 100, height: 100 }} src={requestdata.fromIcon} onClick={() => navigate('/User/' + requestdata.fromUid)} />
                  <Box >
                    依頼者：{requestdata.fromName}
                  </Box>
                </Box>


              </CardContent>
            </Card>


          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default Request;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
