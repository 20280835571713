import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';


// Material UI
import { makeStyles } from '@material-ui/core/styles';


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

/** 用途：共通のプロフィール表示サイドバー
使用方法
  ページからのインポート　
  import ProfileComp from '../comp/ProfileComp';

  設置
  <ProfileComp uid={表示する対象のuid}/>
prop: uid 表示するユーザのuidを受け取る
*/
const ProfileComp = (props) => {

  const [listItems, setListItems] = useState(0);

  const [followed, setFollowed] = useState(false);
  const [following, setFollowing] = useState(false);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();


  useEffect(() => {
    loadData(props);
  }, []);


  // paramで受け取ったuidのユーザ情報を取得し表示する
  const loadData = () => {

    // ログインしていなければフォローは問わない
    if (user) {
      // フォローされているか
      ifProxy.followedCheck(user.uid, props.uid).then((result) => {
        setFollowed(result);
      })
      // フォローしているか
      ifProxy.followCheck(user.uid, props.uid).then((result) => {
        setFollowing(result);
      });
    } else {
      setFollowed(false);
      setFollowing(false);
    }
    // ユーザデータ取得
    ifProxy.getUserData(props.uid).then((result) => {
      console.log("プロフィールコンポーネントの引数")
      console.log(props.uid)
      console.log("データ取得結果")
      console.log(result)
      const user = {
        id: result.id,
        uid: result.uid,
        photoURL: result.photoURL,
        displayName: result.displayName,
        discription: result.discription,
      }
      setListItems(user);
    }).catch((e) => {
      console.log("プロフィールコンポーネント用ユーザ取得 失敗");
      console.log(e);
    })
  }

  const followAction = () => {
    console.log("UID")
    console.log(user.uid)
    console.log(props.uid)
    if (following) {
      ifProxy.deleteFollow(user.uid, props.uid);
      setFollowing(false);
    } else {
      ifProxy.addFollow(user, listItems);
      setFollowing(true);
    }

  }

  const userProfile = () => {
    // TODO: ユーザ詳細画面に遷移する
    navigate(`/User/${listItems.uid}`);
  };

  const followBtn = () => {
    // フォローチェック
    if (following) {
      // フォローしている
      return (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ margin: 2 }}
          className={classes.followButton}
          onClick={followAction}
        >
          フォローしています(解除)
        </Button>
      )

    } else if (followed) {
      // フォローしていないがフォローされている
      return (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ margin: 2 }}
          className={classes.followButton}
          onClick={followAction}
        >
          フォローバックする
        </Button>

      )

    } else {
      // フォローしていない
      return (
        <Button
          variant="contained"
          color="secondary"
          sx={{ margin: 2 }}
          className={classes.followButton}
          onClick={followAction}
        >
          フォローする
        </Button>
      )

    }
  }

  return (
    <Box sx={{
      padding: 2,
      boxShadow: 1,
      alignItems: 'center'

    }}>
      <Avatar
        src={listItems.photoURL}
        onClick={userProfile}
        sx={{ width: 100, height: 100 }}
      />
      <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
        {listItems.displayName}
      </Typography>
      {user ? followBtn() : null}
      <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
        {listItems.discription}
      </Typography>


    </Box>
  );
}

export default ProfileComp;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
