import logo from './logo.svg';
import './App.css';
import ifProxy from './IfProxy.js';
import React from "react";
import { useEffect } from "react";

import { AuthProvider } from './AuthContext.js';
import { Routes, Route, Link, BrowserRouter, useLocation, useParams } from "react-router-dom";

// GA用のライブラリ
import ReactGA from "react-ga4";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import { green, purple } from '@mui/material/colors';

// 各ページ
import Login from './pages/A/Login.js';
import LpCreator from './pages/A/LpCreator.js';
import Kiyaku from './pages/A/Kiyaku.js';
import Tokushoho from './pages/A/tokushoho.js';
import Privacypolicy from './pages/A/Privacypolicy.js';
import ManualStripe from './pages/A/ManualStripe.js';
import Controll from './pages/A/Controll.js';

import TmpTwitterToEmailLogin from './pages/A/TmpTwitterToEmailLogin.js'; //認証プロバイダ移行用
import TmpTwitterToEmailLogin2 from './pages/A/TmpTwitterToEmailLogin2.js'; //認証プロバイダ移行用

// 部分変更リクエスト
import CreateProduct from './pages/Business/CreateProduct.js';
import CreateRequest from './pages/Business/CreateRequest.js';
import Product from './pages/Business/Product.js';
import Request from './pages/Business/Request.js';


import MainList from './pages/B/MainList.js';
import CardList from './pages/B/CardList.js';
import Detail from './pages/B/Detail.js';
import HoldDetail from './pages/B/HoldDetail.js';
import Create from './pages/B/Create.js';
import SearchedListView from './pages/B/SearchedListView.js';
import Registration from './pages/B/Registration.js';
import User from './pages/D/User.js';
import UserEdit from './pages/D/UserEdit.js';
import UserBlock from './pages/D/UserBlock.js';
import UserBlockList from './pages/D/UserBlockList.js';
import NotificationList from './pages/D/NotificationList.js';
import Management from './pages/D/Management.js';
import ApprovalList from './pages/E/ApprovalList.js';
import ApprovalCommentList from './pages/E/ApprovalCommentList.js';

import StandardPage from './pages/StandardPage.js';

const mainTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: "#333c93" },
    secondary: { main: "#008ab0" },
  },
});

function App() {
  const location = useLocation();
  let locationPathName = location.pathname;
  // パスパラメータをうまく取り除くことができなかったため、パスパラメータをもつエンドポイントは置き換える
  //const params = useParams();
  //console.log(params)
  //const locationEjectPathParam = locationPathName.replace(params, "")
  if (locationPathName.match(/\/User\//)) {
    //console.log("userのパス")
    // パス名をidを含まないものに上書きする
    locationPathName = "/User"
  }
  if (locationPathName.match(/\/Detail\//)) {
    //console.log("Detailのパス")
    locationPathName = "/Detail"
  }
  if (locationPathName.match(/\/UserEdit\//)) {
    //console.log("UserEditのパス")
    locationPathName = "/UserEdit"
  }
  if (locationPathName.match(/\/UserBlock\//)) {
    //console.log("UserBlockのパス")
    locationPathName = "/UserBlock"
  }
  if (locationPathName.match(/\/HoldDetail\//)) {
    //console.log("HoldDetailのパス")
    locationPathName = "/HoldDetail"
  }
  if (locationPathName.match(/\/ApprovalCommentList\//)) {
    //console.log("ApprovalCommentListのパス")
    locationPathName = "/ApprovalCommentList"
  }


  useEffect(() => {
    // Google Analytics 測定 ID を入力して設定
    ReactGA.initialize("G-KVV380F57V");
    ReactGA.send({
      hitType: "pageview",
      // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
      page: locationPathName + location.search,
    });
  }, [location]);

  return (

    <AuthProvider>
      <ThemeProvider theme={mainTheme}>
        <Routes>


          <Route path="/login" element={<Login />} />

          <Route path="/LpCreator" element={<LpCreator />} />
          <Route path="/Kiyaku" element={<Kiyaku />} />
          <Route path="/ManualStripe" element={<ManualStripe />} />
          <Route path="/Tokushoho" element={<Tokushoho />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/" element={<MainList />} />
          <Route path="/MainList" element={<MainList />} />
          <Route path="/CardList" element={<CardList />} />
          <Route path="/Create" element={<Create />} />{/* 通常の作品投稿 */}
          <Route path="/Create/:suggestedId" element={<Create />} /> {/* リクエストへの提案のためsuggestedIdを持たせる */}
          <Route path="/SearchedListView" element={<SearchedListView />} />
          <Route path="/Detail/:id" element={<Detail />} />
          <Route path="/HoldDetail/:id" element={<HoldDetail />} />
          <Route path="/Registration/:id" element={<Registration />} />
          <Route path="/User/:id" element={<User />} />
          <Route path="/UserEdit/:id" element={<UserEdit />} />
          <Route path="/UserBlock/:id" element={<UserBlock />} />
          <Route path="/UserBlockList" element={<UserBlockList />} />
          <Route path="/NotificationList" element={<NotificationList />} />
          <Route path="/Management" element={<Management />} />
          <Route path="/ApprovalList" element={<ApprovalList />} />
          <Route path="/ApprovalCommentList/:id" element={<ApprovalCommentList />} />
          <Route path="/StandardPage" element={<StandardPage />} />
          <Route path="/Controll" element={<Controll />} />

          <Route path="/TmpTwitterToEmailLogin" element={<TmpTwitterToEmailLogin />} /> {/* 認証プロバイダ移行用 */}
          <Route path="/TmpTwitterToEmailLogin2" element={<TmpTwitterToEmailLogin2 />} /> {/* 認証プロバイダ移行用 */}

          {/**部分変更リクエスト */}
          <Route path="/CreateRequest/:uid/:id" element={<CreateRequest />} />
          <Route path="/Request/:id" element={<Request />} />
          <Route path="/CreateProduct/:id" element={<CreateProduct />} />
          <Route path="/Product/:id" element={<Product />} />

        </Routes>
      </ThemeProvider>

    </AuthProvider>

  );
}


export default App;
