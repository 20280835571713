import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate } from 'react-router-dom';

import IfProxy from './../../IfProxy';

import { ThemeProvider, createTheme } from '@mui/material/styles';


// Material UI
import { makeStyles } from '@material-ui/core/styles';


import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// TODO 公開前の事前アンケートLPを表示するためだけのバージョン
const DEBUG_VERSION = true;
const imageHeight = 250;


const LpCreator = (props) => {
  const { user } = useAuthContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const [lpCreatorFlg, setLpCreatorFlg] = useState(true); //クリエイター向け:True 利用者向け:False
  const [lpVtuberFlg, setLpVtuberFlg] = useState(false); //クリエイター向け:True 利用者向け:False

  const [listItems, setListItems] = useState(0);
  const [trendListItems, setTrendListItems] = useState(0);
  const [tagDataList, setTagDataList] = useState(0);
  const [nsfwFlg, setNsfwFlg] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenText, setIsOpenText] = useState(false);

  const ifProxy = new IfProxy();


  useEffect((props) => {
    //ifProxy.pvView(); Firebaseセキュリティルールをうまく通せず、一旦停止
    loadData();
    loadTrendData();
    return () => {
      setTrendListItems({});
    }

  }, []);

  const clickSignup = () => {
    navigate('/login');
    //pv直帰しなかった数
    //ifProxy.addKpi("addNotBounce", "");
  }

  /**
   * アンケートへの遷移
   */
  const goAnkateForCreator = () => {
    //ifProxy.ancateClickCreator();Firebaseセキュリティルールをうまく通せず、一旦停止
    window.open('https://forms.gle/4mJBC9ed4rcQtctp8');
  }
  const goAnkateForUser = () => {
    //ifProxy.ancateClickUser();Firebaseセキュリティルールをうまく通せず、一旦停止
    window.open('https://forms.gle/k9CyUatKESK6iAyE9');
  }

  // ============　サンプルとして表示するMainのリスト用　============

  const loadData = () => {
    //pvカウンター
    ifProxy.addKpi("addLpPv", "");

    ifProxy.getIllustlationList().then(async (result) => {
      console.log(result);


      let localstorageFlgNsfw = null;
      // まずローカルストレージからNSFWフラグを取得しフィルタをセット
      await ifProxy.checkNsfwFlg().then((result) => {
        // 更新が非同期となり安定しないため、直接変数にフラグを持つ
        localstorageFlgNsfw = result;
        setNsfwFlg(result);
      })
      let reportList = result;

      // nsfwFlgがFalseのときは nsfw=trueのデータをフィルタする
      let nsfwFilterd = [];

      if (!localstorageFlgNsfw) {
        nsfwFilterd = reportList.filter((output, index) => {
          return !output.nsfw; // falseのものは除外される
        })
      } else {
        nsfwFilterd = reportList
      }

      // カードを作成
      let listItems = nsfwFilterd.map((item) =>
        createCard(item)
      );
      console.log(listItems);
      setListItems(listItems);
    })
  }

  const loadTrendData = () => {

    ifProxy.getIllustrationByTrendShort().then(async (result) => {
      console.log(result);

      let localstorageFlgNsfw = null;
      // まずローカルストレージからNSFWフラグを取得しフィルタをセット
      await ifProxy.checkNsfwFlg().then((result) => {
        // 更新が非同期となり安定しないため、直接変数にフラグを持つ
        localstorageFlgNsfw = result;
        setNsfwFlg(result);
      })

      let reportList = result;
      // nsfwFlgがFalseのときは nsfw=trueのデータをフィルタする
      let nsfwFilterd = [];
      if (!localstorageFlgNsfw) {
        nsfwFilterd = reportList.filter((output, index) => {
          return !output.nsfw; // falseのものは除外される
        })
      } else {
        nsfwFilterd = reportList
      }

      // カードを作成
      let listItems = nsfwFilterd.map((item) =>
        createCard(item)
      );
      console.log(listItems);
      setTrendListItems(listItems);
    })
  }

  const createCard = (item) => {
    return (

      <Card className={classes.imageCard} >
        <CardContent>
          <CardMedia
            component="img"
            height={imageHeight}
            image={item.thumbnail}
            alt="イラスト"
          />
          {item.sales &&
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              販売中
            </Typography>
          }
          {!item.sales &&
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              受付停止中
            </Typography>
          }
          {item.freeFlg &&
            <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
              無料
            </Typography>
          }
          {item.mediaEnableFlg &&
            <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
              媒体単位利用
            </Typography>
          }
          {item.monthlyFlg &&
            <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
              レンタル利用
            </Typography>
          }
        </CardContent>
      </Card>
    )
  }

  const mainListForLp = () => {

    return (
      <Grid item xs={12} sm={12} md={12} >
        <Button variant="contained" fullWidth onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
        {viewSummery()}
        <Box className={classes.contentWrapper}>
          <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>

            {trendListItems}


          </Grid>
        </Box>
      </Grid>
    )

  }

  /** イラストレンタルってこんなサービス コンポーネント */
  const viewSummery = () => {
    return (<>
      <Typography variant="h3" component="div" sx={style.title}>
        <p><b>イラストレンタルってこんなサービス</b></p>
      </Typography>

      <Typography variant="h4" component="div" sx={style.shokaiText}>
        過去に描いた絵を収益化<br></br>
        好きな作家さんの絵をアイコンや2次創作に利用可能<br></br><br></br>
      </Typography>
      <Typography variant="h4" component="div" sx={style.shokaiText}>
        バズりそうな配信シーンをファンに提供<br></br>
        新規ファンの獲得へ<br></br><br></br>
      </Typography>
      <img
        src={`${process.env.PUBLIC_URL}/lpfig_3.png`}
        style={{
          width: '100%',
          maxWidth: "600px",
          margin: "0 auto",
          opacity: 1,
          display: "block",
          background: '#fff',
        }} />
      <Typography variant="h4" component="div" sx={style.shokaiText}>
        利用したい人は、使いたい絵を通販のように購入<br></br>
        売上は利用料を差し引き作家様に振込まれます<br></br><br></br>
        また、無料での許可も可能（サイト利用料も無料）<br></br>
        二次利用を促し新規ファン獲得につなげられます<br></br><br></br>
      </Typography>
    </>
    )
  }


  const viewLpCreator = () => {
    return (
      <div>
        {/*クリエイター向けのご案内*/}
        <Box style={{
          opacity: isOpenText ? 1 : 0,
          transition: '1s',
        }}>


          <Typography variant="h3" component="div" sx={style.title}>
            <p><b>こんな方におすすめ</b></p>
          </Typography>
          <Card className={classes.card} style={style.cardProblem} >
            <CardContent >
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>忙しくてこれ以上描けない</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                イラストを描いて生活できるぐらいの報酬を得られるようになりたい<br></br>
                コミッションサイトや、ファンコミュニティへの作品投稿をしているが<br></br>
                受注量を増やそうにも、<b>これ以上仕事を増やせる余力がない</b>
              </Typography>
            </CardContent>

          </Card>
          <Card className={classes.card} style={style.cardProblem}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>描いたイラストを見てくれる人を増やしたい</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                イラストサイトに投稿するも、自分のタイムラインでは<b>なかなか閲覧数が増えない</b><br></br>
                もっと効率良くファンを増やしたい<br></br>
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card} style={style.cardProblem} >
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>無断利用ではなく、許可したい</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                本当は、許可を与えて使ってもらい、ファンになってほしいが、<br></br>
                誰に何を許可したのかが管理しきれない<br></br>
                有料で許可をするとしても、利用料回収の仕組みを自分で管理するのは手間<br></br>
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card} style={style.cardProblem}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>オリキャラを二次創作、したい、されたい</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                どういう使い方はOK、どういう使い方はNGというルールを表明する場所が欲しい<br></br>
                他の人のキャラクターをお借りしあって創作者同士の交流を活発化したい<br></br>
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="h3" component="div" sx={style.title}>

            <p><b>イラストレンタルでできること</b></p>
          </Typography>
          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>仕事量を増やさずにイラストの売り上げを増やせる</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                眠っているイラストで収益化することができます。これまでに書き溜めたイラストの利用許可を販売し。<br></br>
                SNSアイコン利用のみか、動画配信時の素材に利用するか、作品の改変を許可するか、などに応じて料金設定をすることができます<br></br>
                著作権の譲渡もありません<br></br>

                <br></br><br></br>

                誰にいつまでどのような利用権を貸与しているかは、利用者台帳上で一覧して管理することができます<br></br>
                もちろん、無料での許可についても管理することができます。許可をしている相手。<br></br>
                何に使うことを許可しているのかを簡単に登録しいつでもひとめでわかるようにします。<br></br>
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>ファンが増やせる仕組みがある</b>
              </Typography>
            </CardContent>
            <CardContent>
              <img
                src={`${process.env.PUBLIC_URL}/lpfig_1.png`}
                style={{
                  width: '100%',
                  maxWidth: "600px",
                  margin: "0 auto",
                  opacity: 1,
                  display: "block",
                  background: '#fff',
                }}
              />
              <Typography variant="h5" component="div" sx={style.bodyText}>
                イラストレンタルの特徴的な点は、「使われる」前提で販売している点です。<br></br><br></br>

                利用登録した人がSNSアイコンなどに利用します<br></br>
                利用者が利用することで、さらに多くの人の目に触れます。<br></br><br></br>

                こうして利用者の数だけ倍々でイラストの認知を獲得することができます<br></br><br></br>

                もしそこで「このアイコン、いいな」と思ったら、<br></br>
                イラストレンタルのあなたのページにそのアイコンを買いにきます<br></br><br></br>

                こうして、ファンが増加する仕組みがあります<br></br>
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="h3" component="div" sx={style.title}>
            <p><b>料金</b></p>
          </Typography>
          <img
            src={`${process.env.PUBLIC_URL}/lpfig_2.png`}
            style={{
              width: '100%',
              maxWidth: "600px",
              margin: "0 auto",
              opacity: 1,
              display: "block",
              background: '#fff',
            }} />
          <Typography variant="h5" component="div" sx={style.bodyText}>

            <p>
              サイト登録や利用に料金はかかりません<br></br>
              <br></br><br></br>
              アイコンとして利用して良い期間を１ヶ月や１年単位で販売できます<br></br>
              動画や同人誌など時間で取り下げられないものは、媒体（チャンネル・アカウント・作品）単位で許可を販売できます<br></br>
              <br></br><br></br>
              料金は、販売したときのみ売り上げから決済手数料 23.6% が徴収されます<br></br>
              （もちろん、無料で許可をすることができます。その場合は全て無料となります）<br></br>
            </p>
          </Typography>
        </Box>
      </div >
    )
  }
  const viewLpNotCreator = () => {
    return (
      <>
        {/*ユーザ向けのご案内*/}
        <Box variant="h5" component="div" style={{
          opacity: isOpenText ? 1 : 0,
          transition: '1s',
        }}>

          <Typography variant="h3" component="div" sx={style.title}>
            <p><b>こんな方におすすめ</b></p>
          </Typography>
          <Card className={classes.card} style={style.cardProblem} >
            <CardContent >
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>SNSで使えるアイコンが欲しい</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                気に入ったイラストをSNSのアイコンや、自身の作品に利用したいけれど<br></br>
                イラストが描けない。<br></br>
              </Typography>
            </CardContent>

          </Card>
          <Card className={classes.card} style={style.cardProblem} >
            <CardContent >
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>無断使用はしたくないが、どうやって交渉したらいいかわからない</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                好きな絵があっても<br></br>
                作家さんにどうやって連絡を取っていいのかわからない<br></br>
                交渉の仕方もわからないし大変<br></br>
                高額だったり、または断られるかもしれないのが不安<br></br>
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="h3" component="div" sx={style.title}>

            <p><b>イラストレンタルでできること</b></p>
          </Typography>
          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>好きなクリエイターのイラストを、<br></br>プロフィールのアイコンなどに利用する許可を購入できます</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                <br></br>アイコンに使うことができるイラストを見つけることができます
                <br></br>または、あなたの創作活動で作っている小説の表紙や、
                <br></br>音楽・映像コンテンツのパッケージ等に利用できます
                <br></br>
                <br></br>そして、作者に許可されていることを第三者に証明することができます
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>ネットの通販のように、許可を取ることができます</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                <b>個別にクリエイターさんに個別に交渉をしなくてもワンクリックで利用許可を取ることができます</b><br></br>
                制作を発注するのではなく、利用権をレンタルするだけのため比較的安価に利用できます<br></br><br></br>

              </Typography>
            </CardContent>
          </Card>


          <Typography variant="h3" component="div" sx={style.title}>
            <p><b>料金</b></p>
          </Typography>
          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                サイト登録自体に料金はかかりません<br></br><br></br>

                １ヶ月や１年単位で気に入ったイラストの利用権を購入することができます<br></br>
                定期的な課金を避けたい場合は、媒体単位で購入できます。例えば配信であれば、<br></br>
                １チャンネルごと。SNSなら１アカウントごとに利用の許諾を獲得できます<br></br><br></br>

                制作を発注するのと異なり、既にある絵をすぐに利用許可を得ることができます<br></br>
                無料のイラストも簡単に見つけることができます<br></br>
              </Typography>
            </CardContent>
          </Card>

        </Box>
      </>
    )

  }
  const viewLpVTuber = () => {
    return (
      <>
        {/*VTuber向けのご案内*/}
        <Box variant="h5" component="div" style={{
          opacity: isOpenText ? 1 : 0,
          transition: '1s',
        }}>
          <Typography variant="h3" component="div" sx={style.title}>
            <p><b>こんな方におすすめ</b></p>
          </Typography>
          <Card className={classes.card} style={style.cardProblem} >
            <CardContent >
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>リスナーの獲得ができるか不安が大きい</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                多数の配信者がいる状況に埋もれてしまいファンが獲得できるか不安がある<br></br>
                アピールはするものの自分のタイムラインだけではなかなか閲覧数が増えない<br></br>
                もっと効率良くファンを増やしたい<br></br><br></br>
                <b>例えば、ファンが自ら新規リスナーを増やしてくれるような</b>
              </Typography>
            </CardContent>

          </Card>
          <Card className={classes.card} style={style.cardProblem} >
            <CardContent >
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>チャンネル開始直後は収益が少なく不安がある</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                再生数がまだ少ない立ち上げ当初の時期は、収益性が安定せず<br></br>
                経済的な不安を感じており、早期に収益化できる手段が欲しい<br></br><br></br>
                <b>収益源を一つでも増やしたい</b>
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="h3" component="div" sx={style.title}>

            <p><b>イラストレンタルでできること</b></p>
          </Typography>

          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>多くの人の目に触れる機会を増やし、ファン獲得に繋げられる</b>
              </Typography>
            </CardContent>
            <CardContent>
              <img
                src={`${process.env.PUBLIC_URL}/lpfig_1.png`}
                style={{
                  width: '100%',
                  maxWidth: "600px",
                  margin: "0 auto",
                  opacity: 1,
                  display: "block",
                  background: '#fff',
                }}
              />
              <Typography variant="h5" component="div" sx={style.bodyText}>
                イラストレンタルの特徴的な点は、「使われる」前提で販売している点です。<br></br><br></br>

                利用登録した人がSNSアイコンなどに利用します<br></br>
                利用者が利用することで、さらに多くの人の目に触れます。<br></br><br></br>

                こうして利用者の数だけ倍々でイラストの認知を獲得することができます<br></br><br></br>

                もしそこで「このアイコン、いいな」と思ったら、<br></br>
                イラストレンタルのあなたのページにそのアイコンを買いにきます<br></br><br></br>

                こうして、ファンが増加する仕組みがあります
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>開始直後のチャンネル収益化ができていな時期に、収益をえる仕組みを持てる</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                もちろん無料でのアイコン利用を公開することもできますが<br></br>
                有料アイコンの販売等で、収益を得る手段を増やすことができます
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>利用してくれているファン把握し企画などに利用した</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                例えば、あなたのアイコンを利用してくれているファンを一覧で把握することができます<br></br>
                アイコンを利用してくれているファンに対して特典を付与する。という企画を行いたいときに<br></br>
                これらの情報から利用しているファンのアカウントを把握しサービスを行うことが可能です<br></br>
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card} style={style.card}>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.h3Text}>
                <b>二次創作されたい</b>
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5" component="div" sx={style.bodyText}>
                どういう使い方はOK、どういう使い方はNGというルールを表明する場所を確保できます<br></br>
                ルールをファンに対して明確にすることにより、権利関係の不安を取り除くことでファンアート活動を活性化できます。<br></br>
                また、二次創作の方向づけをあなたがコントロールすることができます<br></br>
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="h3" component="div" sx={style.title}>
            <p><b>料金</b></p>
          </Typography>
          <img
            src={`${process.env.PUBLIC_URL}/lpfig_2.png`}
            style={{
              width: '100%',
              maxWidth: "600px",
              margin: "0 auto",
              opacity: 1,
              display: "block",
              background: '#fff',
            }} />
          <Typography variant="h5" component="div" sx={style.bodyText}>

            <p>
              サイト登録や利用に料金はかかりません<br></br>
              <br></br><br></br>
              アイコンとして利用して良い期間を１ヶ月や１年単位で販売できます<br></br>
              動画や同人誌など時間で取り下げられないものは、媒体（チャンネル・アカウント・作品）単位で許可を販売できます<br></br>
              <br></br><br></br>
              料金は、販売したときのみ売り上げから決済手数料 23.6% が徴収されます<br></br>
              （もちろん、無料で許可をすることができます。その場合は全て無料となります）<br></br>
            </p>
          </Typography>

        </Box>
      </>
    )

  }


  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values

  setTimeout(() => setIsOpen(true), 800);
  setTimeout(() => setIsOpenText(true), 500);

  return (
    <Box>

      <div style={{
        position: "relative",
        maxWidth: "1000px",
        margin: "0 auto",
      }}>

        <img src={`${process.env.PUBLIC_URL}/logo_h.png`}
          style={{
            width: '100%',
            maxWidth: "1000px",
            margin: "0 auto",
            opacity: 0.8,
            display: "block",
            background: 'linear-gradient(to bottom right,#9575cd,#f27573)',
          }} />
        <Typography
          variant="h4"
          style={{
            transition: '1s',
            opacity: isOpen ? 1 : 0,
            width: isOpen ? '30vw' : 0,
            fontFamily: "Helpetica",
            position: "absolute",
            top: "30%",
            left: "80%",
            transform: "translate(-50%,-50%)",
            color: "#fff",
            padding: 0,
            margin: 0
          }}
        >イラストレンタル
        </Typography>
        <Typography
          style={{
            transition: '1s',
            opacity: isOpen ? 1 : 0,
            width: isOpen ? '30vw' : 0,
            fontFamily: "Helpetica",
            position: "absolute",
            top: "80%",
            left: "85%",
            transform: "translate(-50%,-50%)",
            color: "#0000ff",
            fontWeight: 'bold',
            fontSize: 14,
            padding: 2,
            margin: 2,
            backgroundColor: 'rgba(255,255,255,0.5)',
            overflowWrap: 'break-word'
          }}
        >
          <p>
            あなたのイラストを使いたい人に<br></br>
            作品の利用許可を販売できる<br></br><br></br>
            アイコンに使いたいイラストを<br></br>
            ワンクリックで利用許可を購入できる<br></br>
          </p>
        </Typography>

      </div>


      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>

          <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            {/* 登録前に見れるMainList */}
            {mainListForLp()}


            {/* LPの表示 */}
            <Grid item xs={12} sm={4} md={4} >

              <Button fullWidth sx={{ height: 80, borderRadius: 100 }} variant={lpCreatorFlg ? "contained" : "outlined"} size="large"
                onClick={() => { setLpCreatorFlg(true); setIsOpenText(false); setLpVtuberFlg(false) }}
              >
                <Typography variant="h4" component="div">
                  イラストを描く人
                </Typography>

              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} >
              <Button fullWidth sx={{ height: 80, borderRadius: 100 }} variant={(!lpVtuberFlg && !lpCreatorFlg) ? "contained" : "outlined"} size="large"
                onClick={() => { setLpCreatorFlg(false); setIsOpenText(false); setLpVtuberFlg(false) }}
              >
                <Typography variant="h4" component="div">
                  イラストを利用する人
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={12} sm={4} md={4} >
              <Button fullWidth sx={{ height: 80, borderRadius: 100 }} variant={lpVtuberFlg ? "contained" : "outlined"} size="large"
                onClick={() => { setLpCreatorFlg(false); setIsOpenText(false); setLpVtuberFlg(true) }}
              >
                <Typography variant="h4" component="div">
                  配信者(VTuber)
                </Typography>
              </Button>
            </Grid>


            <Grid item xs={12} sm={12} md={12} >
              <Button variant="contained" fullWidth onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
            </Grid>

            <Grid item xs={12} sm={12} md={12} >
              {lpCreatorFlg && viewLpCreator()}

              {(!lpVtuberFlg && !lpCreatorFlg) && viewLpNotCreator()}

              {lpVtuberFlg && viewLpVTuber()}


              {DEBUG_VERSION && <Button fullWidth variant="contained" onClick={clickSignup} sx={style.conversionBtn}> はじめる</Button>
              }
              {DEBUG_VERSION && <Button fullWidth variant="contained" color="primary" onClick={goAnkateForCreator} style={{ marginTop: 10 }}> アンケートを回答する（クリエイター向け）</Button>}
              {DEBUG_VERSION && <Button fullWidth variant="contained" color="secondary" onClick={goAnkateForUser} style={{ marginTop: 10 }}> アンケートを回答する（利用者向け）</Button>}


            </Grid>



          </Grid>
        </Box>
      </Box >
    </Box >


  );
}
export default LpCreator;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    padding: 30,
    maxWidth: "1000px",
    margin: "0 auto",
  },
  mainBox: {

  },
  cardList: {
    margin: 10, //'0 auto'
    display: 'flex',
    alignItems: 'center'
  },
  imageCard: { // トップサンプルのカード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,
    width: 300,
  },
  card: { // LP説明用カード
    marginTop: 10,
    marginBottom: 20,
    padding: 0,

  },
  imageContainer: {
    padding: 0,
  },

  //ヘッダ用
  // 全体レイアウト
  root: {
    flexGrow: 1,
  },
  // 各部分とその中身
  header: {
    background: 'linear-gradient(to bottom right,#9575cd,#f27573)',
    color: "#fff"
  },
  menuButton: {
    marginRight: 10,
  },
  loginBtn: {
    color: "#00bcd4"
  },
  lpBtn: {
    padding: 10,
    margin: 10,
    color: "#000000"

  },
}));

const conversionColor = "#c71585";

// 色とカードの形状はここで制御　
const style = {
  conversionBtn: {
    backgroundColor: conversionColor,
    color: '#fff',
    borderRadius: 10
  },
  title: {
    fontSize: 22,
    color: "#696969"
  },
  h3Text: {
    fontSize: 17,
    color: "#696969"
  },
  shokaiText: {
    margin: 2,
    fontSize: 20,
    color: "#696969"
  },
  bodyText: {
    fontSize: 12,
    color: "#000065"
  },
  card: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    background: 'linear-gradient(to bottom right,#CCD8FF,#CCFFF2)',
  },
  cardProblem: {
    //boxShadow: '10px 2px 20px #CCD8FF',
    background: 'linear-gradient(to bottom right,#CCD8FF,#ffc0cb)',
  },
}

