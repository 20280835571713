import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, Link, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import RequestComp from '../comp/RequestComp';



import { LinkPreview } from '@dhaiwat10/react-link-preview';

// スピナーアニメーション
import { Puff } from 'react-loader-spinner'



// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

// param ユーザuid
const User = (props) => {

  const [listItems, setListItems] = useState(0); //ユーザが入っている
  const [cardList, setCardList] = useState(0);
  const [holdList, setHoldList] = useState(0);
  const [bookmarkList, setBookmarkList] = useState(0);
  const [followed, setFollowed] = useState(false);
  const [following, setFollowing] = useState(false);

  const [followListData, setFollowListData] = useState(0);
  const [followerListData, setFollowerListData] = useState(0);


  // フォームインプット内容
  const [uid, setUid] = useState();
  const [displayName, setDisplayName] = useState();
  const [photoURL, setPhotoURL] = useState();

  const [discription, setDiscription] = useState();
  const [docId, setDocId] = useState(0);
  const [pixiv, setPixiv] = useState();
  const [twitter, setTwitter] = useState();
  const [ticktok, setTicktok] = useState();
  const [site, setSite] = useState();
  const [penaltyConfig, setPenaltyConfig] = useState();

  const [loading, setLoading] = useState(true);


  //ジャンル
  const [genre, setGenre] = useState();
  //タグ
  const [tags, setTags] = useState();
  // 保有レンタル数
  const [rentalCount, setRentalCount] = useState();
  // 獲得ブックマーク数
  const [bookMarkedCount, setBookMarkedCount] = useState();
  // 獲得レンタル数
  const [rentaledCount, setRentaledCount] = useState();

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();
  const ifProxy = new IfProxy();

  useEffect((props) => {
    setLoading(true)

    console.log("USE PARAMS======-")
    console.log(params)
    // このクラスでは、閲覧uidはパスパラメータに入っている
    loadData(params.id);
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (uid) => {
    // ポートフォリオリストの取得
    getCardList(uid);
    // Hold作品リスト
    getHoldList(uid);

    // Bookmark作品リスト
    getBookmarkList(uid)

    // ログインしていないユーザはfalse
    if (user) {
      // フォローされているか
      ifProxy.followedCheck(user.uid, params.id).then((result) => {
        setFollowed(result);
      })
      // フォローしているか
      ifProxy.followCheck(user.uid, params.id).then((result) => {
        setFollowing(result);
      });

    } else {
      setFollowed(false);
      setFollowing(false);
    }

    // 獲得ブックマーク数を設定
    ifProxy.acquwiredBookmark(uid).then((result) => {
      setBookMarkedCount(result);
    });

    // フォロワー、フォローリストデータを取得
    getFollowerList(params.id);
    console.log("loadData : " + uid);

    ifProxy.getUserData(uid).then((result) => {
      console.log("getUserData");
      console.log(result);
      setUid(result.uid);
      setDisplayName(result.displayName);
      setPhotoURL(result.photoURL);


      setDocId(result.id || "");
      setDiscription(result.discription || "");
      setPixiv(result.pixiv || "");
      setTwitter(result.twitter || "");
      setTicktok(result.ticktok || "");
      setSite(result.site || "");
      setPenaltyConfig(result.penaltyConfig || "");

      // ジャンル等右ペインの情報
      setGenre(result.genre || "");
      setTags(result.tags || "");
      setListItems(result);


      setLoading(false)

    })

  }

  // ポートフォリオリスト
  const getCardList = (uid) => {
    const ifProxy = new IfProxy();
    ifProxy.getCreatedIllustlation(uid).then((result) => {
      console.log("ポートフォリオデータ")
      console.log(result)
      let listItems = result.map((item) =>
        createCard(item)
      );
      console.log("========ITEM LIST =======-")
      console.log(listItems)
      setCardList(listItems);
    })
  }

  const createCard = (item) => {
    return (
      <Grid item key={item.id}>
        <Card className={classes.bottomCard} onClick={() => onClickCard(item.id)}>
          <CardContent>
            <CardMedia
              component="img"
              height="100"
              image={item.thumbnail}
              alt="イラスト"
            />
            {item.sales &&
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                販売中
              </Typography>
            }
            {!item.sales &&
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                受付停止中
              </Typography>
            }
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              pv数 : {item.pv}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              お気に入り数: {item.bookmark}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small"
              to={`/Detail/${item.id}`}
            >詳細
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }
  const onClickCard = (id) => {
    console.log("カードがクリックされた")
    navigate('/Detail/' + id);
  }


  /**
   * BookMarkした作品の一覧を取得する
   * @param {*} uid 
   */
  const getBookmarkList = (uid) => {

    const ifProxy = new IfProxy();
    ifProxy.getBookmarkList(uid).then((result) => {
      console.log("ブックマークしているリスト")
      console.log(result)
      let listItems = result.map((item) => {
        return createBookmarkCard(item)
      });
      console.log("========ITEM LIST =======-")
      console.log(listItems)
      setBookmarkList(listItems)
    })
  }
  const createBookmarkCard = (item) => {
    return (
      <Grid item key={item.id}>
        <Card className={classes.bottomCard} onClick={() => onClickBookmarkCard(item.illustDocid)}>
          <CardContent>
            <CardMedia
              component="img"
              height="100"
              image={item.thumbnail}
              alt="イラスト"
            />
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              タグ : {item.tagStrings}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small"
              to={`/Detail/${item.illustDocid}`}
            >詳細
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  const onClickBookmarkCard = (id) => {
    console.log("カードがクリックされた")
    navigate('/Detail/' + id);
  }


  // 所有作品リスト
  const getHoldList = (uid) => {

    const ifProxy = new IfProxy();
    ifProxy.getHoldingIllustlation(uid).then((result) => {
      console.log("所有しているリスト")
      console.log(result)
      let listItems = result.map((item) => {
        if (item.active) {
          return createHoldCard(item)
        } else {
          return
        }
      });
      // 保有レンタル数を設定
      setRentalCount(result.length || "");
      setHoldList(listItems);
    })

    // 所有されている量（Holder数をuidで取得)を返す
    ifProxy.getRendingUser(uid).then((rendingCount) => {
      setRentaledCount(rendingCount.length || "");
    })
  }

  const createHoldCard = (item) => {
    return (
      <Grid item key={item.id}>
        <Card className={classes.bottomCard} onClick={() => onClickHold(item.id)}>
          <CardContent>
            {expireLabel(item)}
            <CardMedia
              component="img"
              height="100"
              image={item.thumbnail}
              alt="イラスト"
            />

          </CardContent>
          <CardActions>
            {(!item.freeFlg && !item.valid) ?
              <Typography variant="h5" component="div" color="red">
                (決済承認待ち)
              </Typography>
              :
              <Button size="small"
                to={`/HoldDetail/${item.id}`}
              >詳細 {item.valid}
              </Button>
            }

          </CardActions>
        </Card>
      </Grid>
    )
  }
  const onClickHold = (id) => {
    console.log("カードがクリックされた")
    navigate('/HoldDetail/' + id);
  }
  const expireLabel = (item) => {
    if (item.deadline.toDate() < new Date())
      return (
        <Typography variant="h5" component="div" color="red">
          利用期間終了
        </Typography>
      )
  }

  const followAction = () => {
    console.log("UID")
    console.log(user.uid)
    // このクラスでは、閲覧uidはパスパラメータに入っている
    console.log(params.id)
    if (following) {
      ifProxy.deleteFollow(user.uid, params.id);
      setFollowing(false);
    } else {
      ifProxy.addFollow(user, listItems);
      setFollowing(true);
    }

  }

  const followBtn = () => {
    // フォローチェック
    if (following) {
      // フォローしている
      return (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ margin: 2 }}
          className={classes.followButton}
          onClick={followAction}
        >
          フォローしています(解除)
        </Button>
      )

    } else if (followed) {
      // フォローしていないがフォローされている
      return (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ margin: 2 }}
          className={classes.followButton}
          onClick={followAction}
        >
          フォローバックする
        </Button>

      )

    } else {
      // フォローしていない
      return (
        <Button
          variant="contained"
          color="secondary"
          sx={{ margin: 2 }}
          className={classes.followButton}
          onClick={followAction}
        >
          フォローする
        </Button>
      )

    }
  }


  // uidをフォローしている人のリスト
  const getFollowerList = (uid) => {
    ifProxy.getFollowerList(params.id).then((result) => {
      console.log("getFollowerList");
      console.log(result);
      //setFollowerListData(result);
      let listItems = result.map((item) => {
        console.log(item)
        let data = {
          photoURL: item.fromPhotoURL,
          displayName: item.fromDisplayName
        }
        return followCard(data)
      });
      console.log("======getFollowerList_listItems");
      console.log(listItems);
      setFollowerListData(listItems);
    });

    // uidがフォローしている人のリスト
    ifProxy.getFollowList(params.id).then((result) => {
      //setFollowListData(result);
      let listItems = result.map((item) => {
        let data = {
          photoURL: item.toPhotoURL,
          displayName: item.toDisplayName
        }
        return followCard(data);
      });
      setFollowListData(listItems);
    });
  }

  const followCard = (data) => {
    return (
      <div>
        <Avatar
          src={data.photoURL}
          sx={{
            width: 50, height: 50
          }} />
        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          {data.displayName}
        </Typography>
      </div>

    )

  }

  const twitterPostTest = () => {
    ifProxy.postTweet();
  }


  /**
   * 自分自身のプロフィール画面のみ編集が可能
   * @returns ユーザ編集画面
   */
  const editButton = () => {
    if (user && user.uid == uid) {
      return (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`/UserEdit/${uid}`}
          sx={{ margin: 2 }}>
          編集
        </Button>
      )
    }
  }

  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values
  if (loading) {
    return (<>
      ...読込中
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#4fa94d"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />


    </>)
  } else {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={9} md={9} >
                <Card className={classes.card}>
                  <CardContent>

                    <Avatar
                      src={photoURL}
                      sx={{
                        width: 100, height: 100
                      }} />
                    <h1>{displayName}</h1>
                    <Typography variant="body2" sx={{
                      fontSize: 14,
                      marginTop: 5
                    }} >
                      {discription}
                    </Typography>
                    <Typography variant="body2"
                      sx={{
                        fontSize: 14,
                        marginTop: 5
                      }}>
                      登録画像の不同意利用時の請求内容について(無断使用ペナルティ)

                    </Typography>
                    <Typography variant="body2">
                      {penaltyConfig}
                    </Typography>
                  </CardContent>
                  <CardActions>


                    <a href={`${twitter}`}>Twitter</a>
                    <a href={`${pixiv}`}>Pixiv</a>
                    <a href={`${ticktok}`}>TikTok</a>
                    <a href={`${site}`}>その他サイト</a>

                    {editButton()}

                    <Button
                      variant="outlined"
                      color="primary"
                      component={Link}
                      to={`/UserBlock/${params.id}`}
                      sx={{ margin: 2 }}>
                      通報/ブロック
                    </Button>
                  </CardActions>
                </Card>

                <h1>作品一覧</h1>
                <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                  {cardList}
                </Grid>
                <h1>受け取ったリクエスト</h1>
                <RequestComp uid={params.id} />
                {(user && user.uid == uid) && <h1>あなたが行ったリクエスト</h1>}
                {(user && user.uid == uid) && < RequestComp uid="my" />}

                <h1>保有する作品一覧</h1>
                <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                  {holdList}
                </Grid>
                <h1>ブックマーク一覧</h1>
                <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
                  {bookmarkList}
                </Grid>

              </Grid>
              <Grid item xs={12} sm={3} md={3} >
                <Box sx={{
                  padding: 2,
                  boxShadow: 1,
                  alignItems: 'center'

                }}>

                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    ジャンル： {genre}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    タグ：
                  </Typography>
                  <Typography variant="h5" sx={{ color: 'blue', wordWrap: 'break-word' }}>
                    {tags}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    保有レンタル数： {rentalCount}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    獲得ブックマーク数： {bookMarkedCount}
                  </Typography>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    獲得レンタル数： {rentaledCount}
                  </Typography>
                  {user ? followBtn() : null}
                </Box>
                <Box sx={{
                  padding: 2,
                  boxShadow: 1,
                  alignItems: 'center'

                }}>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    フォロワー
                  </Typography>
                  {followerListData}

                </Box>
                <Box sx={{
                  padding: 2,
                  boxShadow: 1,
                  alignItems: 'center'
                }}>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                    フォロー中
                  </Typography>
                  {followListData}

                </Box>
              </Grid>
            </Grid>



          </Box>


        </Box>


      </HeadFooter>

    );




  }
}
export default User;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
