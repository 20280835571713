import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';


// Material UI
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

/** 用途：リクエストコンポーネントの内部で利用する。リクエストに関係するイラストのリスト
使用方法
  ページからのインポート　
  import suggestionListComponent from './suggestionListComponent';

  設置
  <suggestionListComponent sugestionId={イラストを取得するリクエストのid}/>
prop: sugestionId
*/

/**
 * 
 * requestのidを属性パラメータに持つ
 * @param {*} props.sugestionId
 * @returns 
 */
const SuggestionListComponent = (props) => {
  const ifProxy = new IfProxy();

  const [activeFlg, setActiveFlg] = useState(false);// 表示フラグ
  const [illustCardList, setIllustCardList] = useState([]);//作られたコンポーネントのリスト


  const { user } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    // 有効化したら読み込みを開始する
    if (activeFlg) {
      loadData(props);
    }
    return () => {
      setActiveFlg(false);
      setIllustCardList([]);
    }

  }, []);
  // データを取得
  // リクエストのidは、sugestionIdでillustをとれる
  const loadData = async () => {
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log(props.sugestionId)
    console.log("===========")

    const items = await ifProxy.getIllustrationByRequestId(props.sugestionId);

    // カード作成
    const createdCardList = items.map((item) =>
      createCard(item)
    )
    // 最後に生成したデータをstateに保管　引数はCardの配列
    setIllustCardList(createdCardList);
  }

  // createCardのアクション
  const onClickCard = (id) => {
    // illustLationに遷移
    navigate(`/Detail/${id}`);
  }
  // リクエストリストの中のイラストリストのカード
  const createCard = (item) => {
    return (

      <Grid item key={item.id}>
        <Card onClick={() => onClickCard(item.id)}>
          <CardContent>
            <CardMedia
              component="img"
              height={100}
              image={item.thumbnail}
              alt="イラスト"
            />
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const activeCheck = () => {
    return (
      <>
        提案された作品
        < Box
          sx={{
            marginLeft: 5,
            padding: 2,
            boxShadow: 2,
            alignItems: 'center',
            overflowWrap: 'break-word'
          }
          }>
          {illustCardList}
        </Box >
      </>
    )
  }

  return (
    <>
      {/**　有効の時だけ表示する */}
      {!activeFlg && <Button variant="contained" onClick={() => { setActiveFlg(true); loadData() }}>提案された作品を見る</Button>}
      {activeFlg && activeCheck()}
    </>

  )
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));

export default SuggestionListComponent;