import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import Typography from '@mui/material/Typography';


/**
 * 
 * @param {*} props uidを入れているが、実はcontextから取っているのでいらない
 * @returns 
 */
const UserBlock = (props) => {

  const [listItems, setListItems] = useState(0);
  // フォームインプット内容
  const [discription, setDiscription] = useState();
  const [reason, setReason] = useState(0);
  const [targetUid, setTargetUid] = useState(0);
  const [targetPhotoUrl, setTargetPhotoURL] = useState(0);
  const [targetDisplayName, setTargetDisplayName] = useState(0);

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (props) => {
    console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝");
    console.log(params);
    //console.log(location);
    const ifProxy = new IfProxy();
    // 対象ユーザ情報をstateに保持
    ifProxy.getUserData(params.id).then((result) => {
      console.log("getUserData");
      console.log(result);
      setTargetUid(result.uid || "");
      setTargetPhotoURL(result.photoURL || "");
      setTargetDisplayName(result.displayName || "");
    })
  }

  /**
   * ブロックを登録
   */
  const registerBlock = () => {
    // {
    // BlockUid
    // Blockid
    // BlockDisplayName
    // reason 通報理由
    // }
    const data = {
      blockUid: targetUid || "",
      blockDisplayName: targetDisplayName || "",
      reason: reason || ""
    }
    console.log("insertBlockUserRelation");
    console.log(data);
    const ifProxy = new IfProxy();
    ifProxy.insertBlock(user, data).then((result) => {
      navigate(`/`);
    }).catch((e) => {
      console.log("error updateData")
      console.log(e);
    })
  }

  const handleChangeReason = (e) => {
    //console.log("handleChangeDiscription : " + e.target.value);
    setReason(e.target.value);
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={12} >
                <Card className={classes.card}>
                  <CardContent>

                    <Avatar
                      src={targetPhotoUrl}
                      sx={{ width: 100, height: 100 }}
                    />
                    <h2>対象ユーザ情報: {targetDisplayName}</h2>

                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      <p>上記のユーザをブロックします</p>
                      <p>ブロックを行うと、当該ユーザ及び投稿した内容を非表示にします。当該ユーザからあなたの投稿を閲覧できないようにします</p>
                      <p>ブロック理由、当該ユーザの不正行為について以下より報告を行うことができます</p>
                      <p>注意: ブロックを行っても、レンタル中のイラストは、閲覧可能状態が継続されます</p>
                    </Typography>
                    <div>

                      <TextField
                        id="filled-multiline-flexible"
                        fullWidth
                        label="登録理由"
                        multiline
                        maxRows={4}
                        onChange={handleChangeReason}
                        variant="filled"
                        sx={{ marginTop: 2 }}
                      />
                    </div>

                  </CardContent>

                  <Button variant="contained"
                    color="secondary"
                    sx={{ margin: 2 }}
                    onClick={registerBlock}>
                    登録する
                  </Button>
                </Card>
              </Grid>

            </Grid>
          </Box>


        </Box>


      </HeadFooter>

    );
  }




}
export default UserBlock;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
