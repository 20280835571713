import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getFirestore, collection, getDocs, query, where, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, EmailAuthProvider, linkWithCredential, updateProfile } from "firebase/auth";
import { doc, setDoc, getDoc, updateDoc, deleteDoc, startAfter } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import Resizer from "react-image-file-resizer";


// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
// Twitter側設定
// https://developer.twitter.com/en/portal/apps/22484142/settings

const getPaymentIntent = "https://us-central1-app3-819e4.cloudfunctions.net/getPaymentIntent";


/**
  * @enum { (
* UNENTRUSTED: String, 
* ENTRUSTED: String, 
* UNSETTLEMENT: String, 
* COMPLETE: String, ) }
*/
const REQUESTSTATUS = Object.freeze({
  UNENTRUSTED: "UNENTRUSTED",
  ENTRUSTED: "ENTRUSTED",
  UNSETTLEMENT: "UNSETTLEMENT",
  COMPLETE: "COMPLETE",
  CLOSE: "CLOSE"
})


// ユーザ状態の遷移を読み取るオブザーバ
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log("ログイン中")
  } else {
    // User is signed out
    console.log("ログアウト")
  }
});


getRedirectResult(auth)
  .then((result) => {
    console.log("==========-TWITTER Redirected=============")
    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
    // You can use these server side with your app's credentials to access the Twitter API.
    const credential = TwitterAuthProvider.credentialFromResult(result);

    console.log(result)
    const token = credential.accessToken;
    const secret = credential.secret;
    // ...

    // The signed-in user info.
    const user = result.user;
    console.log("==========-getRedirectResult=============")
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = TwitterAuthProvider.credentialFromError(error);
    console.log("TWITTER 認証エラー")
    console.log(error)
    // ...
  });


class IfProxy {

  // 認証系
  /**
   * メールでの新規ユーザ登録
   * @param {*} email 
   * @param {*} password 
   * @returns 
   */
  createUser = (email, password) => {
    return new Promise(async (resolve, reject) => {
      createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        resolve(user);
        // ...
      })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(error)
          reject(error);
        });
    });
  }

  /**
   * メールログイン
   * @param {*} email 
   * @param {*} password 
   * @returns 
   */
  login = (email, password) => {
    return new Promise(async (resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          let result = {
            code: "OK",
            user: user
          }
          resolve(result);
        })
        .catch((error) => {
          console.log("ログインエラー")
          const errorCode = error.code;
          const errorMessage = error.message;

          console.log(errorCode)
          console.log(errorMessage)
          let result = {
            code: "NG",
            error: error
          }
          reject(result);
        });

    });
  }

  /**
   * プロバイダー切り替え。twitter認証からメール認証への切り替え
   * @param {*} email 
   * @param {*} password 
   * @returns 
   */
  linkTwitterAuthProviderToEmailProvider = (email, password) => {
    return new Promise(async (resolve) => {
      const credential = EmailAuthProvider.credential(email, password);
      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          const user = usercred.user;
          console.log("Account linking success", user);
          resolve();
        }).catch((error) => {
          console.log("Account linking error", error);
          resolve()
        });
    });
  }

  twitterSignup = () => {
    return new Promise(async (resolve) => {

      // OAuth認証のためのポップアップウィンドウ
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          const credential = TwitterAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const secret = credential.secret;

          // The signed-in user info.
          const user = result.user;
          resolve(user);
          // ...
        }).catch((error) => {
          console.log("Twitter signInWithPopup ERROR")
          console.log(error)
          console.log(error.code)
          console.log(error.message)
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = TwitterAuthProvider.credentialFromError(error);
          // ...
          resolve(error)
        });

    })
  }

  // API操作
  /**
   * twitter投稿
   */
  postTweet = () => {
    console.log("======postTweet=======")
    var Twitter = require('twitter');
    const functions = getFunctions();
    const callTwitterAPI = "https://us-central1-app3-819e4.cloudfunctions.net/callTwitterAPI";

    const addMessage = httpsCallable(functions, 'callTwitterAPI');
    addMessage({ userid: "@yukihigasi", message: "テストメッセージ３" })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        console.log("---callTwitterAPI TEST ========")
        console.log(result)

      })
      .catch((error) => {
        console.log("callTwitterAPI コール失敗");
        console.log(error);
      });
  }



  // データ操作 ================-
  getData = () => {
    return new Promise(async (resolve) => {
      const querySnapshot = await getDocs(collection(db, "content"));
      let result = []

      try {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    })
  }

  getDetailData = (id) => {
    return new Promise(async (resolve) => {
      resolve();
    });
  }

  insertData = (data) => {
    return new Promise(async (resolve) => {
      console.log("データのインサート")
      console.log(data)

      try {
        const newData = await addDoc(collection(db, "content"), {
          no: data.no,
          text: data.text
        })
      } catch (e) {
        console.log("データ登録に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
   * FirebaseAuthのユーザをUPDATEする
   * @param {displayName,blob} data 
   * @returns 
   */
  updateUser = (data) => {
    return new Promise(async (resolve) => {
      // blobで受け取ったアイコン画像をfirestoreに上げる
      // profileIconフォルダ以下　ファイル名はランダム値
      // urlを取得する

      // プロフィール変更がない場合

      if (data.blob == 0) {
        try {
          // userを更新する
          updateProfile(auth.currentUser, {
            displayName: data.displayName,
          }).then(() => {
            console.log("FirebaseAuthのユーザをUPDATEする 成功 ")

            const resultUser = {
              displayName: data.displayName,
              photoURL: null
            }
            resolve(resultUser);
            // Profile updated!
            // ...
          }).catch((error) => {
            // An error occurred
            // ...
            console.log("FirebaseAuthのユーザをUPDATEする 失敗 ")
            resolve()
          });
        } catch (e) {
          console.log("画像更新がない場合のプロフィール更新")
          console.log(e)
          resolve(e);
        }
      } else {

        // サムネイル化しblobを取得
        const thumbnail = await this.resizeFile(data.blob);

        // Storageへ画像データを登録
        var storage = getStorage();
        const fileName = this.randomstr();
        var storageRef = ref(storage, `/profileIcon/${data.uid}/${fileName}`);
        uploadBytesResumable(storageRef, data.blob).then((uploadTask) => {
          getDownloadURL(ref(storage, `/profileIcon/${data.uid}/${fileName}`))
            .then((url) => {
              console.log("ストレージ画像アップロード成功")
              try {
                // userを更新する
                updateProfile(auth.currentUser, {
                  displayName: data.displayName,
                  photoURL: url
                }).then(() => {
                  console.log("FirebaseAuthのユーザをUPDATEする 成功 ")
                  const resultUser = {
                    displayName: data.displayName,
                    photoURL: url
                  }
                  resolve(resultUser);
                  // Profile updated!
                  // ...
                }).catch((error) => {
                  // An error occurred
                  // ...
                  console.log("FirebaseAuthのユーザをUPDATEする 失敗 ")
                  resolve()
                });

              } catch (e) {
                console.log("データ登録に失敗")
                console.log(e)
                resolve(e);
              }
            })
            .catch((error) => {
              // Handle any errors
              console.log(error)
            });
        })
      }
    })

  }

  // CLUD
  /**  /
  IllustLation テーブル =======================================================
  画像本体はStorageに格納する
  しかし、リストびゅーで3.5MBを超える画像をリスト表示するとダウンロードコストがかかるため、
  200　200 の100kb以下のサムネイルをリストビューでは表示する
  そのため、保存時にリサイズコピーした画像をbase64データで用意し、
  それは、firestoreの項目の一つに保持する。 firestoreは1ドキュメント1MB以下のため注意
  
  ＜画像情報＞
  url: ファイルのURL
  uid: 作成者のuid
  thumbnail: 画像をサムネイルしたBase64 blobデータ
  ＜権利メニュー＞
  freeFlg: freeFlg, //商品種別: 無料公開
  monthlyFlg: monthlyFlg,//商品種別: 通常レンタル
  mediaEnableFlg: mediaEnableFlg,//商品種別: 媒体単位許諾  
  
  
  forIcon: 使用権：アイコンT/f
  forIconAmount: 料金 ¥
  forMaterial: 使用権：画像素材としての利用(icon以外のSNS・ブログ・動画での利用) T/f
  forMaterialAmount: 料金 ¥
  forProcessing: 使用権：加工(SNS投稿時に発生するトリミング以外の加工)T/f
  forProcessingAmount: 料金 ¥
  forCommarce: 使用権：商用利用 T/f
  forCommarceAmount: 料金 ¥
  else: その他の使用制限　String
  ＜フラグ＞
  sales: 出品中かどうか T/f
  nsfw: ゾーニング要否 t/F
  dedicated: 独占使用かどうか f/F
  ＜検索よう情報＞
  tags: [{key:value}] タグ名のオブジェクト配列で持つ
  tagStrings: [String]　タグtextの文字列の配列　検索用にtagsのvalueだけを持つ
  ＜モチベーション喚起＞
  pv: ページの閲覧数（モチベーション強化）
  bookmark: ファボの代わり。ユーザが気になっている候補をメモする
  ＜システム＞
  createtAt: 作成日
  deletedAt: 公開終了日。公開終了設定がされたときに、日付を登録する
  <リクエスト機能>
  suggestedId: 関係する提案のid　
  
  storage
  パス： IllustLation/登録ユーザのuid/UUID.jpg
  */
  // コンテンツを投稿
  /* param
  uid : 登録ユーザのuid
  blob: アップロードファイルのblobデータ
  */
  postContent = (data) => {

    return new Promise(async (resolve) => {
      console.log("データのインサート")
      console.log(data)
      // サムネイル化しblobを取得
      const thumbnail = await this.resizeFile(data.blob);

      // Storageへ画像データを登録
      var storage = getStorage();
      const fileName = this.randomstr();
      var storageRef = ref(storage, `/IllustLation/${data.uid}/${fileName}`);
      uploadBytesResumable(storageRef, data.blob).then((uploadTask) => {
        getDownloadURL(ref(storage, `/IllustLation/${data.uid}/${fileName}`))
          .then((url) => {
            console.log("ストレージ画像アップロード成功")
            try {
              addDoc(collection(db, "IllustLation"), {
                url: url,
                thumbnail: thumbnail,

                // 引数から設定
                uid: data.uid,
                forIcon: data.forIcon,

                freeFlg: data.freeFlg, //商品種別: 無料公開
                monthlyFlg: data.monthlyFlg,//商品種別: 通常レンタル
                mediaEnableFlg: data.mediaEnableFlg,//商品種別: 媒体単位許諾            

                forIconAmount: data.forIconAmount,
                forMaterial: data.forMaterial,
                forMaterialAmount: data.forMaterialAmount,
                forProcessing: data.forProcessing,
                forProcessingAmount: data.forProcessingAmount,
                forCommarce: data.forCommarce,
                forCommarceAmount: data.forCommarceAmount,
                forElse: data.forElse,

                sales: data.sales,
                nsfw: data.nsfw,
                dedicated: data.dedicated,

                suggestedId: data.suggestedId || "",

                tags: data.tags || [{}],
                tagStrings: data.tagStrings || [{}],

                janru: data.janru || [{}],
                zokusei: data.zokusei || [{}],
                situation: data.situation || [{}],
                janruStrings: data.janruStrings || [{}],
                zokuseiStrings: data.zokuseiStrings || [{}],
                situationStrings: data.situationStrings || [{}],

                pv: data.pv,
                bookmark: data.bookmark,

                createtAt: serverTimestamp()

              }).then((result) => {
                console.log("投稿成功");
                console.log("画像パス");
                console.log(url);

                resolve(result);
              }).catch((e) => {
                console.log(e);
              })
            } catch (e) {
              console.log("データ登録に失敗")
              console.log(e)
              resolve(e);
            }
          })
          .catch((error) => {
            // Handle any errors
            console.log(error)
          });
      })
    });
  }

  // UUIDを生成。ユーザ単位のファイル名に使用する
  randomstr = () => {
    let s = "";
    let length = 32;
    for (let i = 0; i < length; i++) {
      let random = Math.random() * 16 | 0;
      s += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
    }

    return s;
  }

  // 投稿されたイラストをリストで取得 Main画面用6レコード絞り
  getIllustlationList = () => {
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      const q = await query(ref, orderBy("createtAt", "desc"), limit(6));
      const querySnapshot = await getDocs(q);
      let result = []
      console.log("getIllustlationList");

      try {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    })
  }
  // 投稿されたイラストをリストで取得 ３０レコード取得
  getIllustlationFullList = () => {
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      const q = await query(ref, orderBy("createtAt", "desc"), limit(30));
      const querySnapshot = await getDocs(q);
      let result = []
      console.log("getIllustlationList");

      try {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    })
  }
  // 投稿されたイラストをdocidで取得
  getIllustlation = (param) => {
    return new Promise(async (resolve) => {
      console.log("getIllustlation（引数） : " + param);
      const docRef = doc(db, "IllustLation", param);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        console.log("getIllustlation data:", snapshot.data());
        let res = snapshot.data();
        res.id = snapshot.id;
        resolve(res);

        //PVを加算
        let prePv = res.pv - 0; //integer型に変換
        let postPv = prePv + 1;
        console.log("POSTPV================")
        console.log(postPv)

        await updateDoc(docRef, {
          pv: postPv
        }).then(() => {

        }).catch((e) => {
          console.log("PV加算に失敗");
          console.log(e);

        })
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch((e) => {
      console.log("エラー:" + e);
      console.log(e);
    })
  }

  /**
   *  フォロー中のユーザが投稿した新着を取得
   * ログインユーザのuidがフォローしているユーザが製作したイラストを取得
   * @param {*} uid ログインユーザのuid
   * @returns  "false" の場合はデータなし
   */
  getFollowIllustrationList = (uid) => {
    console.log("START getFollowIllustrationList")
    return new Promise(async (resolve) => {
      // フォローしているユーザのuidの配列を取得
      let userList = await this.getFollowList(uid);
      const uidList = userList.map((output, index) => {
        return output.toUid;
      });

      if (uidList.length === 0) {
        resolve();
      } else {
        // illustを取得
        const ref = collection(db, "IllustLation");
        const q = await query(ref, where("uid", "in", uidList), orderBy("createtAt", "desc"), limit(10));
        const querySnapshot = await getDocs(q);
        let result = []
        console.log("getIllustlationList");

        try {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        } catch (e) {
          console.log("データ取得に失敗")
          console.log(e)
          resolve(e);
        }
      }
    })
  }
  // タグを更新する　投稿されたイラストをdocidで取得
  updateTags = (param, tags) => {
    return new Promise(async (resolve) => {
      console.log("タグを取得し設定する 対象id " + param);
      console.log("タグを取得し設定する 対象id " + tags);

      const tagStrings = tags.map((data, index) => {
        return data.text
      })

      const docRef = doc(db, "IllustLation", param);
      await updateDoc(docRef, {
        tags: tags, // データとして利用するためのマップ配列
        tagStrings: tagStrings //検索値として利用するための文字列配列
      }).then(() => {

      }).catch((e) => {
        console.log("PV加算に失敗");
        console.log(e);
      })
    }).catch((e) => {
      console.log("エラー:" + e);
      console.log(e);
    })
  }

  // イラストの公開を終了する uid
  closeIllustlation = (param) => {
    return new Promise(async (resolve) => {
      console.log("公開を終了する 対象id " + param);

      const docRef = doc(db, "IllustLation", param);
      await updateDoc(docRef, {
        sales: false,
        deletedAt: serverTimestamp()
      }).then(async () => {
        // 使用者の翌月の決済購読更新を行わない様にする
        // Twitterメンションとうで通知する →週次バッチで通知

        // 購読者に今月で終了する通知を行う
        // 保有者リストを取得
        await this.getHolderList(param).then(async (result) => {
          console.log("HOLDER LIST ")
          console.log(result)

          for (let data of result) {
            // Holderの使用権を無効に更新する Holderを無効にする必要がない。なぜなら契約分は利用可能なため。
            //期限切れの更新時に初めて影響する。つまり継続しようとしたら公開が終了しているため更新できない
            // this.offHolder(data.id);

            // 通知
            const noti = {
              uid: data.holderUid, //通知の宛先
              fromIllustrationId: data.illustId,
              type: "close",
            }
            await this.createNotification(noti);
          }

        }).catch((e) => {
          console.log("getHolderList");
          console.log(e);
        })
      }).catch((e) => {
        console.log("イラスト salesフラグを非公開に設定変更失敗");
        console.log(e);
      })
    });
  }

  /**
   * 投稿したイラストをuidで取得
   * @param {uid} param 作成者のuid
   * @returns IllustLation[]
   */
  getCreatedIllustlation = (uid) => {
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      const q = await query(ref, where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 投稿作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }
  /**
   * 投稿したイラストをタグで取得
   * @param {tag} param 検索するタグを持つIllustlationを取得する
   * 検索は　tagStrings　項目で行う
   * @returns IllustLation[]
   */
  getIllustrationByTag = (tag) => {
    console.log("getIllustrationByTag=======search")
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      const q = await query(ref, where("tagStrings", "array-contains", tag), orderBy("createtAt", "desc"), limit(10));

      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 投稿作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
   * 
   * @param {{janru,zokusei,situation}} param0 
   * @returns 
   */
  getIllustrationByAndTag = ({ janru, zokusei, situation }) => {
    console.log("==========GET TAG")
    console.log("==========GET TAG")
    console.log("==========GET TAG")
    console.log("==========GET TAG")

    console.log(janru + " : " + zokusei + " : " + situation)
    let result = []
    const getjanru = new Promise(async (resolve, reject) => {
      const ref = collection(db, "IllustLation");

      if (janru != "") {
        const q = query(
          ref,
          where("janruStrings", "array-contains", janru),
          orderBy("createtAt", "desc"), limit(20)
        );
        const querySnapshot = await getDocs(q);
        let result = []
        try {
          if (querySnapshot.size == 0) {
            console.log("getjanru not exist 投稿作品なし")
            resolve(null);
          } else {
            console.log("janru　＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝でーたあり")
            querySnapshot.forEach((doc) => {
              console.log(doc.id, " => ", doc.data());
              let res = doc.data();
              res.id = doc.id;
              result.push(res);
            })
            console.log("ループ終了")
            resolve(result)
          }
        } catch (e) {
          console.log("データ取得に失敗")
          console.log(e)
          reject(e);
        }
      } else {
        resolve(null);
      }
    })
    const getZokusei = new Promise(async (resolve, reject) => {
      const ref = collection(db, "IllustLation");

      if (zokusei != "") {
        const q = await query(
          ref,
          where("zokuseiStrings", "array-contains", zokusei),
          orderBy("createtAt", "desc"), limit(20)
        );
        const querySnapshot = await getDocs(q);
        let result = []
        try {
          if (querySnapshot.size == 0) {
            console.log("getZokusei not exist 投稿作品なし")
            resolve(null);
          } else {
            await querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              let res = doc.data();
              res.id = doc.id;
              result.push(res);
            })
            resolve(result)
          }
        } catch (e) {
          console.log("データ取得に失敗")
          console.log(e)
          reject(e);
        }
      } else {
        resolve(null);

      }
    })
    const getSituation = new Promise(async (resolve, reject) => {
      const ref = collection(db, "IllustLation");

      if (situation != "") {
        const q = await query(
          ref,
          where("situationStrings", "array-contains", situation),
          orderBy("createtAt", "desc"), limit(20)
        );
        const querySnapshot = await getDocs(q);
        let result = [];
        try {
          if (querySnapshot.size == 0) {
            console.log("getSituation not exist 投稿作品なし")
            resolve(null);
          } else {
            await querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              let res = doc.data();
              res.id = doc.id;
              result.push(res);
            })
            resolve(result)
          }
        } catch (e) {
          console.log("データ取得に失敗")
          console.log(e)
          reject(e);
        }
      } else {
        resolve(null)
      }
    })

    return Promise.all([getjanru, getZokusei, getSituation]).then(([janruAry, zokuseiAry, situationAry]) => {

      let intersection = []
      if (janruAry && zokuseiAry && situationAry) {
        //全部入っている
        intersection = janruAry.filter(x => zokuseiAry.some(y => y.id === x.id))
          .filter(x => situationAry.some(y => y.id === x.id));

      } else if (!janruAry && zokuseiAry && situationAry) {
        // ２項目に入力があるケース
        intersection = zokuseiAry.filter(x => situationAry.some(y => y.id === x.id));

      } else if (janruAry && !zokuseiAry && situationAry) {
        intersection = janruAry.filter(x => situationAry.some(y => y.id === x.id));

      } else if (janruAry && zokuseiAry && !situationAry) {
        intersection = janruAry.filter(x => zokuseiAry.some(y => y.id === x.id));

      } else if (janruAry && !zokuseiAry && !situationAry) {
        // 1項目だけに値がある場合
        intersection = janruAry
      } else if (!janruAry && zokuseiAry && !situationAry) {

        intersection = zokuseiAry
      } else if (!janruAry && !zokuseiAry && situationAry) {
        intersection = situationAry
      }


      //console.log("論理和")
      //console.log(intersection);
      return intersection

    }).catch((e) => {
      console.log(e)

    })


  }

  /**
   * フリーワード検索（実態は、ワードに相当するタグがあるかどうかを検索）
   * @param {tag} param 検索するタグを持つIllustlationを取得する
   * @returns IllustLation[]
   */
  getIllustrationByWord = (tag) => {
    console.log("getIllustrationByWord=======search")
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      const q = await query(ref, where("tagStrings", "array-contains", tag), orderBy("createtAt", "desc"), limit(10));
      let result = []
      await getDocs(q).then(async (querySnapshot) => {
        try {
          if (querySnapshot.size == 0) {
            console.log("not exist 投稿作品なし")
            resolve(result);
          } else {
            await querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, " => ", doc.data());
              let res = doc.data();
              res.id = doc.id;
              result.push(res);
            })
            resolve(result);
          }
        } catch (e) {
          console.log("データ取得に失敗")
          console.log(e)
          resolve(e);
        }
      })

    });
  }

  /**
   * 注目投稿を取得 MainList用に６件だけ取得
   * @returns Illustlation[]
   * @description 条件 投稿から60日以内（0件を防ぐため長めにとり、徐々に減らす） の新着50件を、PV降順ソート。ページングは原理上できない
   */
  getIllustrationByTrendShort = () => {
    console.log("getIllustrationByWord=======search")
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      // 投稿から3日時間以内
      const date = new Date();
      date.setDate(date.getDate() - 180);
      console.log("TIMESTAMP 現在時刻から上を-した日付 ===========================")
      console.log(date);
      console.log(Timestamp.fromDate(date));

      console.log("TIMESTAMP ===========================")

      let timestampNDateAgo = Timestamp.fromDate(date);
      console.log(timestampNDateAgo)
      // 日付の範囲で絞りこみ
      const q = await query(ref, where("createtAt", ">=", timestampNDateAgo), orderBy("createtAt", "desc"), limit(12));
      // >=比較とorder byが併用できないため、範囲で絞り込んだ上で、mapでソートする

      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 投稿作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          // 取得したリストをpv順ソート
          const sortKey = "pv"
          const sortedResult = result.sort((pre, post) => {
            if (pre[sortKey] < post[sortKey]) return 1;
            if (pre[sortKey] > post[sortKey]) return -1;
            return 0;
          })
          resolve(sortedResult);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
   * 注目投稿を取得
   * @returns Illustlation[]
   * @description 条件 投稿から3日以内（0件を防ぐため長めにとり、徐々に減らす） の新着50件を、PV降順ソート。ページングは原理上できない
   */
  getIllustrationByTrend = () => {
    console.log("getIllustrationByWord=======search")
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");
      // 投稿から3日時間以内
      const date = new Date();
      date.setDate(date.getDate() - 180);
      console.log("TIMESTAMP 現在時刻から上を-した日付 ===========================")
      console.log(date);
      console.log(Timestamp.fromDate(date));

      console.log("TIMESTAMP ===========================")

      let timestampNDateAgo = Timestamp.fromDate(date);
      console.log(timestampNDateAgo)
      // 日付の範囲で絞りこみ
      const q = await query(ref, where("createtAt", ">=", timestampNDateAgo), orderBy("createtAt", "desc"), limit(50));
      // >=比較とorder byが併用できないため、範囲で絞り込んだ上で、mapでソートする

      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 投稿作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          // 取得したリストをpv順ソート
          const sortKey = "pv"
          const sortedResult = result.sort((pre, post) => {
            if (pre[sortKey] < post[sortKey]) return 1;
            if (pre[sortKey] > post[sortKey]) return -1;
            return 0;
          })
          resolve(sortedResult);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
   * 検索画面ページングでの追加データ取得
   * @param {*} type 　検索するものは何か
   * @param {*} lastVisible 現在表示されている最後のデータ
   * @param {*} param 検索条件
   * @returns IllustLation[]
   */
  getPagingIllustrationList = (type, lastVisible, param) => {
    if (lastVisible == undefined) {
      console.log("最後のデータのため検索を終了");
      return false;
    }
    console.log("getIllustrationByTag=======search")
    return new Promise(async (resolve) => {
      const ref = collection(db, "IllustLation");

      // 取得種別でクエリを切り替え
      let q = null;

      if (type === "tag") {
        //タグ検索
        // paramはタグ
        const docSnap = await getDoc(doc(ref, lastVisible.id));

        q = await query(ref, where("tagStrings", "array-contains", param), orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));
      } else if (type === "janru") {
        const docSnap = await getDoc(doc(ref, lastVisible.id));

        q = await query(ref, where("janruStrings", "array-contains", param), orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));

      } else if (type === "zokusei") {
        const docSnap = await getDoc(doc(ref, lastVisible.id));

        q = await query(ref, where("zokuseiStrings", "array-contains", param), orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));

      } else if (type === "situation") {
        const docSnap = await getDoc(doc(ref, lastVisible.id));

        q = await query(ref, where("situationStrings", "array-contains", param), orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));

      } else if (type === "word") {
        // paramは検索文字列
        // フリーワード検索
        const docSnap = await getDoc(doc(ref, lastVisible.id));

        q = await query(ref, where("tagStrings", "array-contains", param), orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));

      } else if (type === "follow") {
        // paramはログインユーザのuid
        // フォローしているユーザのuidの配列を取得
        let userList = await this.getFollowList(param);
        const uidList = userList.map((output, index) => {
          return output.toUid;
        });
        if (uidList.length === 0) {
          resolve();
        } else {
          // illustを取得
          const docSnap = await getDoc(doc(ref, lastVisible.id));
          q = await query(ref, where("uid", "in", uidList), orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));
        }

      } else if (type === "full") {
        // 新着取得
        const docSnap = await getDoc(doc(ref, lastVisible.id));

        // paramはなし
        q = await query(ref, orderBy("createtAt", "desc"), startAfter(docSnap), limit(10));
      }

      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 投稿作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }


  // CLUD
  /**  Holder/
  使用者台帳 テーブル =======================================================
  id :docId
  illustId :クリエイターのuid
  illustUid
  illustUrl
  holderUid :購入者のuid
  holderUser
  holderUserUrl :購入者のアイコンurl
  twitterid: 期限ぎれ通知をするときにtwitterメンションで使う
  permited: 投稿者が登録したユーザ。課金されない
  active :所有フラグ　有効化しているとtrue、未払いで月を跨ぐとfalse隣無効化、台帳から非表示
  valid : 支払いが済んでいるとtrue　済んでいないとfalseで仮登録状態
  deadline: 失効日
  settlement : Stripe決済id (毎月支払い毎に更新)
  createtAt: serverTimestamp()
  paymentIntentId: WebHocで、決済イベント処理完了の時に、対象のHolderを探すためのキー
  paymentStatus: false: まだ true:決済済み
   
  freeFlg: false, //商品種別: 無料公開
  monthlyFlg: false,//商品種別: 通常レンタル
  mediaEnableFlg: false,//商品種別: 媒体単位許諾    
   
   
  MediaUseuse: data.MediaUseuse, // 媒体利用　利用媒体名
  MediaUsemediaUrl: data.MediaUsemediaUrl, // 媒体利用　利用媒体 url
   
  */
  addHolder = (data) => {
    return new Promise(async (resolve) => {
      // 終了日を作成する
      // 登録日である本日をnew Date()から作成
      const today = new Date();
      // serverTimeStampからperiodの数（１or12）分だけ先の日付を取得

      console.log("data.period")
      console.log(data.period)
      if (data.period === 1) {
        console.log("一ヶ月を通過")
        today.setMonth(today.getMonth() + 1)
      } else if (data.period === 12) {
        console.log("12ヶ月を通過")
        today.setMonth(today.getMonth() + 12)
      } else if (data.period === 1000) {
        console.log("期限なしのため、今日から1000ヶ月")
        today.setMonth(today.getMonth() + 1000)
      }
      // それを期限にするためタイムスタンプ型にする
      const deadlineTimestamp = Timestamp.fromDate(today);

      let valid = false;
      // 無料利用の場合はwebhoc無視して有効化する
      if (data.freeFlg) {
        valid = true;
      }

      addDoc(collection(db, "Holder"), {
        illustId: data.illustId,
        illustUid: data.illustUid,
        illustUrl: data.illustUrl,
        thumbnail: data.thumbnail,
        holderUid: data.holderUid,
        holderUser: data.holderUser,
        holderUserUrl: data.holderUserUrl,

        // holderユーザのtwitter id名　通知時のメンション用
        twitterid: data.twitterid,

        // クリエイター指定ユーザ
        permited: data.permited || "",

        // 登録権利
        icon: data.icon,
        material: data.material,
        processing: data.processing,
        commers: data.commers,
        amount: data.amount,
        active: true,

        freeFlg: data.freeFlg, //商品種別: 無料公開
        monthlyFlg: data.monthlyFlg,//商品種別: 通常レンタル
        mediaEnableFlg: data.mediaEnableFlg,//商品種別: 媒体単位許諾    

        MediaUseuse: data.MediaUseuse, // 媒体利用　利用媒体名
        MediaUsemediaUrl: data.MediaUsemediaUrl, // 媒体利用　利用媒体 url

        valid: valid, // falseは決済が完了してwebhocが帰ってくるのを待っている状態 webhocのcs_でholderを特定し更新
        period: data.period,
        deadline: deadlineTimestamp, //失効日
        settlement: data.settlement, //Stripe決済id
        createtAt: serverTimestamp(),

        stripeAccount: data.stripeAccount, // 決済情報を取得するために、決裁を持つアカウントのidを保持
        paymentIntentId: data.paymentIntentId, // 決済情報のid 取れない
        cs_: data.cs_,// 対応するStripe上の決済はcs_ で識別
        paymentStatus: data.paymentStatus,

      }).then((docRef) => {
        console.log("投稿成功 : " + docRef.id);
        resolve(docRef.id);

        // 通知
        const noti = {
          uid: data.illustUid, //通知の宛先
          fromUid: data.holderUid, // 自分
          fromDisplayName: data.holderUser,
          fromPhotoURL: data.holderUserUrl,
          fromIllustrationId: data.illustId,
          infomation: "",
          type: "newUser",
        }
        this.createNotification(noti);
      }).catch((e) => {
        console.log(e);
        resolve("addHolder error");
      })
    });
  }

  /**
   * 保有を解除する
   * @param {*} id 
   * @returns 
   */
  offHolder = (id) => {
    return new Promise(async (resolve) => {

      console.log("ホルダーレコードを論理削除===========--");
      try {
        const document = await doc(db, "Holder", id);
        await updateDoc(document, {
          active: false
        }).then(() => {
          resolve();
        })

      } catch (e) {
        console.log("論理削除に失敗");
        console.log(e)
        resolve(e);
      }
    })

  }
  /**
  * 決済状態のステータスを更新する
  * @param {*} item
  * @returns 
  */
  updatePaymentStatus = (item) => {
    return new Promise(async (resolve) => {

      console.log("ホルダーレコードを論理削除===========--");
      try {
        const document = await doc(db, "Holder", item.id);
        await updateDoc(document, {
          paymentStatus: item.paymentStatus
        }).then(() => {
          resolve();
        })

      } catch (e) {
        console.log("論理削除に失敗");
        console.log(e)
        resolve(e);
      }
    })

  }

  // イラストの使用者をリストで取得　イラストのidで取得
  getHolderList = (illustId) => {
    return new Promise(async (resolve) => {
      console.log("HOLDERリストの取得")
      const ref = collection(db, "Holder");
      const q = await query(ref, where("illustId", "==", illustId), where("active", "==", true), orderBy("createtAt", "desc"), limit(10));
      const querySnapshot = await getDocs(q);
      let result = []


      try {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "HOLDER LIST => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    })
  }


  // 作品レンタルレコードをdocidで取得
  getHold = (param) => {
    return new Promise(async (resolve) => {
      console.log("getHolder（引数） : " + param);
      const docRef = doc(db, "Holder", param);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        console.log("Document data:", snapshot.data());
        let res = snapshot.data();
        res.id = snapshot.id;
        resolve(res);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch((e) => {
      console.log("エラー:" + e);
      console.log(e);
    })
  }

  /**
   * 所有しているイラストを取得
   * @param {uid} param  所有者のuid
   * @returns Holder[]
   */
  getHoldingIllustlation = (uid) => {
    return new Promise(async (resolve) => {

      const ref = collection(db, "Holder");
      const q = await query(ref, where("holderUid", "==", uid), where("active", "==", true));
      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 所有作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }
  /**
   * 所有されているイラストを取得
   * @param {uid} param  投稿者のuid
   * @returns Holder[]
   */
  getRendingUser = (uid) => {
    return new Promise(async (resolve) => {

      const ref = collection(db, "Holder");
      const q = await query(ref, where("illustUid", "==", uid), where("active", "==", true));
      const querySnapshot = await getDocs(q);
      let result = []

      try {
        if (querySnapshot.size == 0) {
          console.log("not exist 所有作品なし")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  /**
   * すでに保有しているかどうか取得
   * @param {id,uid} param  作品のdocIdとユーザのuid
   * @returns Boolean
   */
  chkHold = (id, uid) => {
    return new Promise(async (resolve) => {

      const ref = collection(db, "Holder");
      const q = await query(ref, where("illustId", "==", id), where("holderUid", "==", uid), where("active", "==", true));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }


  // CLUD
  /**  UserData/
  UserData、Userはコンテクスト上に保持する
  const { user, userData } = useAuthContext(); でstateを取得
  stateである以下を取得可能
  user : Authのuser
  userData :  実際に使用するユーザデータ
   
   
  ユーザ参照情報 テーブル =======================================================
  // 自分以外のユーザがAuthのユーザをおそらく取れないため、storage側にもアカウント周りの情報を持ち、そっちを使う
  id :docId
  uid : Authのuidを持つ
  name: twitterIDを固定
  photoURL: AuthのphotoURL
  displayName: AuthのDisplayNameを固定
  discription: 自己紹介（twitterプロフを固定）
  pixiv: pixivユーザページのurl
  twitter: twitterのユーザページのurl
  ticktok: ユーザページurl
  site: その他のアカウントurl
   
  //penaltyConfig: 無断使用が行われたときに、請求を行う金額等を明示する -> これは、こちらで有識者と相談して決めるべき
  少なくとも、推奨を定義しておく
   
  集計用
  salesAmount: 当月販売額
  holdAmount: 当月所有額
  kingFlg : 表彰水準に達しているかどうか(上位5%を月次で更新)
   
   
  createtAt: serverTimestamp()
  updatedAt: 更新日
   
  twitterサインアップ時に初期情報を自動設定
  */
  // ユーザデータを取得し、
  // Authコンテクストへ設定
  // 個別のユーザ詳細、ユーザプロフィールコンポーネントで利用

  // param uid (docidではない)
  getUserData(uid) {
    return new Promise(async (resolve) => {
      let userData = {};
      const ref = collection(db, "UserData");
      const q = await query(ref, where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let result = []


      try {
        if (querySnapshot.size == 0) {
          console.log("user data not exist 新規ユーザ")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result[0]);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }
  /**
   * 名前に関係するユーザを取得する
   * @param {*} displayName 
   * @returns UserData[]
   */
  getUserDataByDisplayName(displayName) {
    console.log("getUserDataByDisplayName ユーザ該当なし")
    return new Promise(async (resolve) => {
      const ref = collection(db, "UserData");
      const q = await query(ref, orderBy("displayName"), startAt(displayName), endAt(displayName + '\uf8ff')); // Like検索は公式サポートなし。前方一致と後ろなんでもで対応
      const querySnapshot = await getDocs(q);
      let result = []
      try {
        if (querySnapshot.size === 0) {
          console.log("user data not exist")
          resolve(result);
        } else {
          await querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
            let res = doc.data();
            res.id = doc.id;
            result.push(res);
          })
          resolve(result);
        }
      } catch (e) {
        console.log("データ取得に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }

  // data　のid項目で更新対象を特定して更新 idが空なら新規と判定
  updateUserData(data) {
    return new Promise(async (resolve) => {
      let userData = {};

      // 新規作成かどうか判定
      if (!data.id) {
        console.log("新規ユーザ==========-");
        try {
          await addDoc(collection(db, "UserData"), {
            uid: data.uid, // Auth値固定（Auth)
            name: data.name, // Auth値固定（twitterID固定)
            photoURL: data.photoURL,
            displayName: data.displayName, // Auth値固定（twitter) 

            discription: data.discription, // Auth値固定（twitter) -> cors対応のため、別サーバ（funciton)が必要。一旦後回しでフリーテキストへ

            genre: data.genre,
            tags: data.tags,

            pixiv: data.pixiv,
            twitter: data.twitter,
            ticktok: data.ticktok,
            site: data.site,
            penaltyConfig: data.penaltyConfig,

            stripeId: "", // Stripeアカウント登録時は登録

            salesAmount: "", //システム側の更新
            holdAmount: "", //システム側の更新
            kingFlg: false, //システム側の更新

            createtAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          })
          resolve();
        } catch (e) {
          console.log("データ登録に失敗");
          console.log(e)
          resolve(e);
        }


      } else {
        const document = doc(db, "UserData", data.id);
        console.log("更新ユーザ===========--");
        try {
          // Stripeの口座登録であればStripeIdを設定。nullを避けるため他は空もじ
          const stripeId = data.stripeId || "";
          await updateDoc(document, {

            uid: data.uid, // 初回登録固定（Auth)
            name: data.name, // 初回登録固定(twitterID固定)
            photoURL: data.photoURL,
            displayName: data.displayName, // 初回登録固定（twitter)
            discription: data.discription, // 初回登録固定（twitter)→一旦自由入力へ

            genre: data.genre,
            tags: data.tags,

            // ユーザ制御が可能な項目
            pixiv: data.pixiv,
            twitter: data.twitter,
            ticktok: data.ticktok,
            site: data.site,
            penaltyConfig: data.penaltyConfig,

            // Stripe口座登録でのStripeId払い出し
            stripeId: stripeId, // Stripeアカウント登録時は登録

            //salesAmount: data.salesAmount, //システム側の更新
            //holdAmount: data.holdAmount, //システム側の更新
            //kingFlg : data.kingFlg, //システム側の更新

            //createtAt: data.createtAt,
            updatedAt: serverTimestamp()

          });
          resolve();

        } catch (e) {
          console.log("データ登録に失敗");
          console.log(e)
          resolve(e);
        }


      }


      resolve(userData);
    });
  }



  // CLUD
  /**  follow/
  フォロー テーブル =======================================================
  // フォロワー関係を作る。ユーザ名、アイコンも正規化せず持たせる
  id :docId
   
  // フォローしている人
  fromUid : Authのuidを持つ
  fromPhotoURL: AuthのphotoURL
  fromDisplayName: AuthのDisplayNameを固定
  // フォローされている人
  toUid : Authのuidを持つ
  toPhotoURL: AuthのphotoURL
  toDisplayName: AuthのDisplayNameを固定
   
  red: 既読フラグ
  createtAt:タイムスタンプ
  */

  /**
   * フォロー関係を追加
   * @param {*} fromUser, toUser userData型
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  addFollow(fromUser, toUser) {
    return new Promise(async (resolve) => {
      let reration = {
        toUid: toUser.uid,
        toDisplayName: toUser.displayName,
        toPhotoURL: toUser.photoURL,
        fromUid: fromUser.uid,
        fromDisplayName: fromUser.displayName,
        fromPhotoURL: fromUser.photoURL,
        read: false,
        createtAt: serverTimestamp()
      };
      try {
        await addDoc(collection(db, "follow"), reration)
        resolve();

        // 通知
        const noti = {
          uid: toUser.uid, //通知の宛先
          fromUid: fromUser.uid, // 自分
          fromDisplayName: fromUser.displayName,
          fromPhotoURL: fromUser.photoURL,
          fromIllustrationId: "", // fromは、紐づく作品はないため不要
          infomation: "",
          type: "follow",
        }
        this.createNotification(noti);
      } catch (e) {
        console.log("フォローデータ登録に失敗");
        console.log(e)
        resolve(e);
      }

      resolve();
    })
  }
  /**
   * フォロー関係を削除
   * @param {uid, toUid} 
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  deleteFollow(uid, toUid) {
    return new Promise(async (resolve) => {

      try {
        const ref = collection(db, "follow");
        const q = await query(ref, where("fromUid", "==", uid), where("toUid", "==", toUid));
        const querySnapshot = await getDocs(q);
        await querySnapshot.forEach(async (document) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(document.id, " このデータを削除=> ", document.data());
          await deleteDoc(doc(db, "follow", document.id));
        })
        resolve();
      } catch (e) {
        console.log("フォローデータ削除に失敗");
        console.log(e)
        resolve(e);
      }

      resolve();
    })
  }
  /**
   * uidをフォローしている人を取得
   * @params uid 
   * @returns follow[]
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  getFollowerList(uid) {
    return new Promise(async (resolve) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "follow");
      const q = await query(ref, where("toUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "getFollowerList => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      }
    })
  }
  /**
   * uidにフォローされている人
   * @param {*} uid 
   * @returns follower[]
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  getFollowList(uid) {
    return new Promise(async (resolve) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "follow");
      const q = await query(ref, where("fromUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      }
    })
  }
  /**
   * フォローされてるか確認
   * @param {uid, fromUid} uid そのUIDにフォローされているか fromUid
   * @returns T/F
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  followedCheck(uid, fromUid) {
    return new Promise(async (resolve) => {
      const ref = collection(db, "follow");
      const q = await query(ref, where("toUid", "==", uid), where("fromUid", "==", fromUid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    })
  }/**
   * 既にフォローしているか確認
   * @param {uid,toUid} uid そのUIDをフォローしているか toUid
   * @returns T/F
   * 宛先(to)に自分が入っている＝フォローされている
   * 送り元（from）に自分が入っている＝自分がフォローしている
   */
  followCheck(uid, toUid) {
    return new Promise(async (resolve) => {
      const ref = collection(db, "follow");
      const q = await query(ref, where("fromUid", "==", uid), where("toUid", "==", toUid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    })
  }

  // CLUD
  /**  block/
   
  ユーザ参照情報 テーブル =======================================================
  // 自分以外のユーザがAuthのユーザをおそらく取れないため、storage側にもアカウント周りの情報を持ち、そっちを使う
  tuhoUid: user.uid,
  tuhoDisplayName: user.displayName,
  blockUid: item.blockUid,
  blockId: item.blockId,
  blockDisplayName: item.blockDisplayName,
  reason: item.reason,
  osusume: item.osusume,
  createdAt: firebase.firestore.FieldValue.serverTimestamp() //レコード作成時刻
  */
  // ブロックユーザお呼びコンテンツ
  //引数itemの値
  // {
  // BlockUid
  // Blockid
  // BlockDisplayName
  // reason 通報理由
  // }
  /**
   * 
   * @param {*} loginUser 通報する人
   * @param {*} blockUser 通報される人
   * @returns 
   */
  insertBlock = (loginUser, blockUser) => {
    console.log("insertBlock===")
    console.log(blockUser.blockUid)
    return new Promise(async (resolve) => {
      let blockRelation = {
        tuhoUid: loginUser.uid,
        tuhoDisplayName: loginUser.displayName,
        blockUid: blockUser.blockUid,
        blockId: blockUser.blockId || "",
        blockDisplayName: blockUser.blockDisplayName,
        reason: blockUser.reason,
        createdAt: serverTimestamp() //レコード作成時刻
      };
      try {
        await addDoc(collection(db, "block"), blockRelation)
        console.log("ブロックリレーションに登録");
        console.log(blockRelation);
        resolve();
      } catch (e) {
        console.log("ブロックリレーション登録に失敗");
        console.log(e)
        resolve(e);
      }

    })
  }

  /**
   * uidがブロックしているまたはされているリストを取得
   * @param {*} uid ログインユーザのuid
   * @returns ブロックリレーション[]
   */
  checkBlock = (uid) => {
    console.log("====ckeckBlock=======")
    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");

      // ログインユーザがブロックしているリスト
      const tuhoIng = await this.tuhoIngList(uid);
      const tuhoed = await this.tuhoEdList(uid);
      result = tuhoIng.concat(tuhoed);
      resolve(result);
    })
  };


  /**
  * 通報している、ブロックレコード
  * @param {*} uid ログインユーザ
  */
  tuhoIngList = (uid) => {
    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");
      const q = await query(ref, where("tuhoUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(result);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "checkBlock => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        console.log(result)
        resolve(result);
      }
    });
  }

  /**
   * 通報されている、ブロックレコード
   * @param {*} uid ログインユーザ
   */
  tuhoEdList = (uid) => {
    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");
      // ログインユーザがブロックされているリストを取得し統合
      const qed = await query(ref, where("blockUid", "==", uid));
      const querySnapshoted = await getDocs(qed);
      if (querySnapshoted.size === 0) {
        //終わり
        resolve(result)
      } else {
        await querySnapshoted.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "checkBlockEd => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        console.log(result)
        resolve(result)
      }
    });
  }



  /**
   * ログインユーザがブロックしているユーザリストを取得する
   * @param {*} uid 
   * @returns 
   */
  checkBlockingList = (uid) => {
    console.log("====checkBlockingList=======")

    return new Promise(async (resolve, error) => {
      let result = [];
      const ref = collection(db, "block");

      // ログインユーザがブロックしているリスト
      const q = await query(ref, where("tuhoUid", "==", uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        resolve(result);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "checkBlock => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          result.push(res);
        })
        resolve(result);
      }
    });
  }


  /**
   * ブロックを解除する
   * @param {id} BlockRelationのdocid 
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  deleteBlock(id) {
    return new Promise(async (resolve) => {
      await deleteDoc(doc(db, "block", id)).then(() => {
        console.log("ブロック解除")
        resolve();
      }).catch((e) => {
        console.log("ブロック解除失敗")
        console.log(e);
      })

    })
  }
  // CLUD
  /**  通知　Notification/
   
  通知　Notification テーブル =======================================================
  uid: 宛先uid
  read: 既読
  fromUid: 通知を送ったuid
  fromDisplayName: 通知を送ったユーザ名
  fromPhotoURL: 通知を送ったユーザアイコン画像
  fromIllustrationId: 通知があった投稿した作品docid
  infomation: 公式からのお知らせ
  type: 投稿の種別 [info(公式通知), follow(フォローされた), bookmark(ブックマークされた), fav(いいねされた), newUser(新たにレンタルが開始される), close(公開を終了。来月からは利用権と支払い義務は除去), request(リクエストに新着あり), parcialRequest（部分変更リクエスト） ]
  createdAt: serverTimestamp() //レコード作成時刻
  */

  /**
   * 通知レコードを作成する
   * @param {*} data 
   * @returns 
   */
  createNotification = (data) => {
    return new Promise(async (resolve) => {
      let noti = {
        uid: data.uid || "",
        read: false,
        fromUid: data.fromUid || "",
        fromDisplayName: data.fromDisplayName || "",
        fromPhotoURL: data.fromPhotoURL || "",
        fromIllustrationId: data.fromIllustrationId || "",
        requestId: data.requestId || "",
        infomation: data.infomation || "",
        type: data.type || "",
        createdAt: serverTimestamp() //レコード作成時刻
      };
      try {
        await addDoc(collection(db, "Notification"), noti)
        resolve();
      } catch (e) {
        console.log("通知データ登録に失敗");
        console.log(e)
        resolve(e);
      }
    })
  }



  /** 
   * uidのユーザに来ている通知情報を全て取得する
   * @param {*} uid 
   * @returns 
   */
  getNotification = (uid) => {
    console.log("getNotification======")
    console.log(uid)
    return new Promise(async (resolve) => {
      let notificationList = []
      const ref = collection(db, "Notification");
      const q = await query(ref, where("uid", "==", uid), orderBy("createdAt", "desc"), limit(50));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(notificationList);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "getNotification => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          notificationList.push(res);
        })
        resolve(notificationList);
      }
    });
  }
  /** 
  * 公式の全体向け通知
  * @param {*} uid 
  * @returns 
  * 
  * テーブル OfficialInfo
  * title
  * discription
  * createdAt
  * 内容の登録は、Firebaseコンソール上から行う
  */
  getOfficialNotification = () => {
    console.log("getOfficialNotification======")
    return new Promise(async (resolve) => {
      let notificationList = []
      const ref = collection(db, "OfficialInfo");
      const q = await query(ref, orderBy("createdAt", "desc"), limit(50));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(notificationList);
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "getOfficialNotification => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          notificationList.push(res);
        })
        console.log("resolve")
        console.log(notificationList)
        resolve(notificationList);
      }
    });
  }

  /**
   * uidのユーザがの通知を全て既読にする
   * @param {*} uid 
   * 
   * uidのユーザの既読フラグを全部falseにする
   * getNotificationのユーザが画面を閉じるときに、これも非同期で実行する
   * 一旦ボタンで既読にする
   */
  readNotification = (uid) => {
    return new Promise(async (resolve) => {
      let targetDoc = []
      const ref = collection(db, "Notification");
      const q = await query(ref, where("uid", "==", uid), where("read", "==", false));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve();
      } else {
        await querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, "readNotification => ", doc.data());
          targetDoc.push(doc.id);
        })
        for (let doclist of targetDoc) {
          const document = doc(db, "Notification", doclist);
          await updateDoc(document, {
            read: true
          });
        }
        resolve();
      }
    });
  }
  /**
   * uidのユーザに未読の通知が存在するか確認
   * @param {*} uid 
   * @returns boolean 未読がある T
   */
  checkRead = (uid) => {
    return new Promise(async (resolve) => {
      const ref = collection(db, "Notification");
      const q = await query(ref, where("uid", "==", uid), where("read", "==", false), limit(50));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(false);
      } else {
        resolve(true);
      }
    })
  }


  // CLUD
  /** Bookmark/
  ブックマーク テーブル =======================================================
  // id
  id :docId 関係レコードのdocid
  createtAt:タイムスタンプ
   
  // ブックマークしている人
  fromUid : した人のuid
  fromPhotoURL: した人のアイコンphotoURL
  fromDisplayName: した人の名前　DisplayName
   
  // ブックマークされた作品
  toUid : ブックマークされた作品の作者
   
  // 作品データ
  url: 画像のurl
  illustDocid: illustの詳細画面へのdocid
  tags: illustのタグ
  tagStrings : 検索用の値
   
   
   
  /**
   * ブックマーク関係を追加
   * @param {*} fromUserUid ユーザのuid
   * @param {*} illustId イラストのId
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  addBookmark(fromUserUid, illustId) {
    return new Promise(async (resolve) => {
      // FromUserをuidから取得
      const user = await this.getUserData(fromUserUid);

      // イラストをIDから取得
      const illust = await this.getIllustlation(illustId);

      let reration = {
        toUid: illust.uid,
        fromUid: user.uid,
        fromDisplayName: user.displayName,
        fromPhotoURL: user.photoURL,
        thumbnail: illust.thumbnail,

        url: illust.url,
        illustDocid: illust.id,
        tags: illust.tags || [],
        tagStrings: illust.tagStrings || [],

        createtAt: serverTimestamp()
      };
      try {
        console.log("ブックマークする")
        console.log("fromUserUid : " + fromUserUid);
        console.log("illustId : " + illustId);
        console.log("reration : " + reration);
        await addDoc(collection(db, "Bookmark"), reration);

        // ブックマーク数をIllustlationに更新する
        let bookmarkCount = illust.bookmark - 0;
        let postBookmarkCount = bookmarkCount + 1;
        const docRef = doc(db, "IllustLation", illust.id);
        await updateDoc(docRef, {
          bookmark: postBookmarkCount
        }).then(() => {

        }).catch((e) => {
          console.log("PV加算に失敗");
          console.log(e);

        })

        // 通知
        const noti = {
          uid: illust.uid, //通知の宛先
          fromUid: user.uid, // 自分
          fromDisplayName: user.displayName,
          fromPhotoURL: user.photoURL,
          fromIllustrationId: illust.id,
          infomation: "",
          type: "bookmark",
        }
        this.createNotification(noti);
        resolve();
      } catch (e) {
        console.log("ブックマーク登録に失敗");
        console.log(e)
        resolve(e);
      }

      resolve();
    })
  }

  /**
   * 自分がブックマークしているリストを取得する
   * @param {*} uid ブックマークしているユーザのuid
   * @returns Bookmark[]
   */
  getBookmarkList = (uid) => {
    return new Promise(async (resolve) => {
      let bookmarkList = []
      const ref = collection(db, "Bookmark");
      const q = await query(ref, where("fromUid", "==", uid), orderBy("createtAt", "desc"));
      console.log(uid)
      const querySnapshot = await getDocs(q);
      console.log("UID=======")
      console.log(uid)
      console.log("取得したブックマーク数")
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(bookmarkList);
      } else {
        await querySnapshot.forEach((doc) => {
          console.log(doc.id, "getBookmarkList => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          bookmarkList.push(res);
        })
        resolve(bookmarkList);
      }
    });
  }
  /**
   * 獲得したブックマークされている数を返す
   * @param {*} uid ブックマーク数を数えるユーザのuid
   * @returns int 
   */
  acquwiredBookmark = (uid) => {
    return new Promise(async (resolve) => {
      let bookmarkList = []
      const ref = collection(db, "Bookmark");
      const q = await query(ref, where("toUid", "==", uid));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      resolve(querySnapshot.size);
    });
  }
  /**
   * そのイラストをブックマークしているBookmarkリレーションを全て取得する
   * uidがそのイラストをbookmarkしているかは、このリストのfromUid　に uidが含まれているかどうかで判別
   * @param {*} id イラストのdocid
   * @returns Bookmark[]
   */
  getBookmarkUserList = (id) => {
    return new Promise(async (resolve) => {
      let bookmarkList = []
      const ref = collection(db, "Bookmark");
      const q = await query(ref, where("illustDocid", "==", id));
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      if (querySnapshot.size === 0) {
        console.log("通知データなし")
        resolve(bookmarkList);
      } else {
        await querySnapshot.forEach((doc) => {
          console.log(doc.id, "getBookmarkUserList => ", doc.data());
          let res = doc.data();
          res.id = doc.id;
          bookmarkList.push(res);
        })
        resolve(bookmarkList);
      }
    });
  }
  /**
   * ブックマークを削除
   * @param {id} bookmarkのdocid
   * @returns 
   * 宛先に自分が入っている＝フォローされている
   * 送り元に自分が入っている＝自分がフォローしている
   */
  deleteBookmark(id) {
    return new Promise(async (resolve) => {
      await deleteDoc(doc(db, "Bookmark", id)).then(() => {
        console.log("ブックマークを解除")
        resolve();
      }).catch((e) => {
        console.log("ブックマーク解除解除失敗")
        console.log(e);
        resolve();
      })
    })
  }



  // リクエストコンポーネント用 データ操作 ===========================================
  /*
  * requestContent リクエスト本文
  * requestOffer オファー金額
  * toUid　相手
  * FromUid　リクエストした人
  * clearFlg　F:まだ提案数ぜろ,T:提案あり
  * openFlg T:全体へのリクエスト F: 特定ユーザへのリクエスト
  * suggestionCount
  *  ランキングどうするか
  * 　　１　新規作成順
  * 　　２　投稿があったじゅん　　→ リクエスト側は足りていても、投稿されたらトップに来る
  * 　　３　リクエストの人気順　　→ 欲しい順に上にくる。が、過去の人気、が残る
  * 　　４　リクエスト人気　降順 AND まだ投稿がない 昇順　欲しいけどまだないが上に来る
  * createdAt
  * updatedAt
  */

  insertRequest(data) {
    return new Promise(async (resolve) => {
      console.log("リクエストデータのインサート")
      console.log(data)
      // システム情報を加える
      data.clearFlg = false;
      data.suggestionCount = 0; //提案数
      data.createdAt = serverTimestamp(); //レコード作成時刻
      data.updatedAt = serverTimestamp(); //レコード作成時刻


      try {
        const newData = await addDoc(collection(db, "requests"), data)

        if (!data.openFlg) {
          // 通知
          const noti = {
            uid: data.toUid, //通知の宛先
            infomation: `リクエストを受け取りました。プロフィール画面から確認してください。 リクエスト内容 : ${data.requestContent}`,
            type: "request",
          }
          this.createNotification(noti);

        }


        resolve(data);


      } catch (e) {
        console.log("データ登録に失敗")
        console.log(e)
        resolve(e);
      }

    })
  }

  /**
   * リクエストをもらったユーザに絞って取得する
   * @param {*} uid 
   * @returns 
   */
  getRequestByToUid(uid) {
    return new Promise(async (resolve) => {

      console.log("getRequestByToUid====")
      console.log(uid)
      const ref = collection(db, "requests");
      const q = await query(ref, where("toUid", "==", uid), orderBy("createdAt", "desc"));// ソートじゅん修正
      const querySnapshot = await getDocs(q);

      console.log("リクエストの取得結果============")
      console.log(querySnapshot.size)

      this.getResultQuery(querySnapshot, resolve);

    })
  }
  /**
  * リクエストを、自分が行ったものに絞って取得する
  * @param {*} uid 
  * @returns 
  */
  getRequestByFromUid(uid) {
    return new Promise(async (resolve) => {

      console.log("getRequestByFromUid====")
      console.log(uid)
      const ref = collection(db, "requests");
      const q = await query(ref, where("FromUid", "==", uid), orderBy("createdAt", "desc"));// ソートじゅん修正
      const querySnapshot = await getDocs(q);

      console.log("リクエストの取得結果============")
      console.log(querySnapshot.size)

      this.getResultQuery(querySnapshot, resolve);

    })
  }
  /**
   * リクエストを全て取得する
   * @returns 
   */
  getRequest() {
    return new Promise(async (resolve) => {

      console.log("getRequest====")
      const ref = collection(db, "requests");
      const q = await query(ref, orderBy("createdAt", "desc")); // ソートじゅん修正
      const querySnapshot = await getDocs(q);

      console.log("リクエストの取得結果============")
      console.log(querySnapshot.size)

      this.getResultQuery(querySnapshot, resolve);

    })
  }


  /**
   * 既存の作品でリクエストに回答する (今のところ実装予定なし)
   * @param {*} id  requestのid
   * @param {*} illustId リクエストに提案する既存作品のid
   * @returns 
   */
  updateIllustrationByRequest(id, illustId) {
    return new Promise(async (resolve) => {

      // 既存の作品でリクエストに回答する
      // illustIdでイラストを取得
      // イラストをCreate

      // requestの suggestionCount++ clearrFlg=true に更新する

    })
  }

  /**
   * リクエストに対して提案された作品を取得する
   * @param {*} id 　suggestedId　を持った作品を取得する
   * @returns 
   */
  getIllustrationByRequestId(id) {
    return new Promise(async (resolve) => {

      console.log("suggestedId====")
      console.log(id)
      const ref = collection(db, "IllustLation");
      const q = await query(ref, where("suggestedId", "==", id));
      const querySnapshot = await getDocs(q);

      console.log("リクエストに紐づく作品の取得結果============")
      console.log(querySnapshot.size)

      this.getResultQuery(querySnapshot, resolve);

    })
  }

  // リクエストコンポーネント用共通処理
  /**
   * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
   * @param {*} querySnapshot 
   * @param {*} resolve 
   */
  getResultQuery = (querySnapshot, resolve) => {
    let result = []
    try {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        let res = doc.data();
        res.id = doc.id;
        result.push(res);
      })
      resolve(result);
    } catch (e) {
      console.log("データ取得に失敗")
      console.log(e)
      resolve(e);
    }
  }

  // ================================================
  // 部分変更リクエスト ================================
  // ================================================

  // Busyness リクエストコントロール =======================================================
  createRequest({ targetId, thema, tochu, amount, fromUid, fromName, fromIcon, toUid, toName, toIcon, toStripeId }) {
    return new Promise(async (resolve, reject) => {
      /*
      console.log("CHECK====")
      console.log("thema" + thema)
      console.log("tochu" + tochu)
      console.log("amount" + amount)
      console.log("fromUid" + fromUid)
      console.log("fromName" + fromName)
      console.log("fromIcon" + fromIcon)
      console.log("toUid" + toUid)
      console.log("toName" + toName)
      console.log("toIcon" + toIcon)
  */
      await addDoc(collection(db, "Request"), {
        targetId,
        thema: thema,
        tochu: tochu,
        amount: amount,
        suggestedId: "",
        fromUid: fromUid,
        fromName: fromName,
        fromIcon: fromIcon,
        toUid: toUid,
        toName: toName,
        toIcon: toIcon,
        stripeId: toStripeId,
        status: REQUESTSTATUS.UNENTRUSTED,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }).then((result) => {
        resolve(result);

      }).catch((e) => {
        console.log("createRequest に失敗 IF Proxy")
        console.log(e);
        reject(e);
      })

    });
  }


  /**
   * status
   * unentrusted 未受諾/
   * entrusted 受諾/
   *  completed 完了/
   *  requestCanceled キャンセル/
   *  creatorCanceled 作家判断キャセル
   */

  /**
   * リクエスト中に更新　TODO これ存在しない、作成と同時にリクエスト中になるため
   * @param {*} id 
   * @returns 
   */
  unentrusted(id) {
    return new Promise(async (resolve, reject) => {
    });
  }

  /**
  * 受諾済みに更新 
  * @param {*} id 
  * @returns 
  */
  entrusted(id) {
    return new Promise(async (resolve, reject) => {
      //this.sign(id)
      await this.updateRequestStatus(id, REQUESTSTATUS.ENTRUSTED)
      resolve(true)
    });
  }

  /**
  * 納品済み 支払い待ち
  * @param {*} id  Requestのid
  * @param {id} illustId Requestに応じて投稿されたIllsutのいd
  * @returns 
  */
  settlement(id, illustId) {
    return new Promise(async (resolve, reject) => {
      await this.updateRequestStatus(id, REQUESTSTATUS.UNSETTLEMENT, illustId)
      resolve(true)
    });
  }

  /**
   * 完了 支払い済み済みに更新
   * @param {*} id 
   * @returns 
   */
  completed(id) {
    return new Promise(async (resolve, reject) => {
      await this.updateRequestStatus(id, REQUESTSTATUS.COMPLETE)
      resolve(true)
    });
  }

  /**
   * 完了 支払い済み済みに更新
   * @param {*} id 
   * @returns 
   */
  close(id) {
    return new Promise(async (resolve, reject) => {
      await this.updateRequestStatus(id, REQUESTSTATUS.CLOSE)
      resolve(true)
    });
  }

  /**
   * リクエストステータスを更新する共通メソッド
   * @param {*} id 
   * @param {*} nextStatus 
   * @returns 
   */
  updateRequestStatus(id, nextStatus, suggestedId = "") {
    return new Promise(async (resolve, reject) => {
      console.log("updateRequestStatus")
      console.log("NEXT STSTUS")
      console.log(nextStatus + " : " + id)
      this.getRequestByid(id).then((result) => {
        const docRef = doc(db, "Request", result.id);

        updateDoc(docRef, {
          status: nextStatus,
          suggestedId: suggestedId,
          updatedAt: serverTimestamp()
        }).then(() => resolve()).catch((e) => {
          console.log(e)
        })
      })
    });
  }

  /**
   * リクエストをidで取得する
   * @param {id} docid
   * @return {request}
   */
  getRequestByid(id) {
    return new Promise(async (resolve, reject) => {
      console.log(id)
      const docRef = doc(db, "Request", id);
      const docSnap = await getDoc(docRef);

      try {
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          let res = docSnap.data()
          res.id = docSnap.id;
          resolve(res);

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          resolve({ status: "データなし" })
        }
      } catch (e) {
        console.log("getRequestByid");
        console.log(e);
        reject(e)
      }


    });
  }

  /**
   * リクエストを受けたユーザのuidで取得
   * @param {uid}
   * @return {[request]}
   */
  getRequestByToUid(toUid) {
    return new Promise(async (resolve, reject) => {
      try {


        let result = []; //取得結果のリスト
        const ref = collection(db, "Request");
        const q = await query(ref, where("toUid", "==", toUid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size == 0) {
          resolve(result);
        } else {
          this.getRequestQuery(querySnapshot, resolve);

        }
      } catch (e) {
        console.log("getRequestByToUid")
        console.log(e);
      }
    });
  }
  /**
   * リクエストをしたユーザのuidで取得
   * @param {uid}
   * @return {[request]}
   */
  getRequestByFromUid(fromUid) {
    return new Promise(async (resolve, reject) => {
      let result = []; //取得結果のリスト
      const ref = collection(db, "Request");
      const q = await query(ref, where("toUid", "==", fromUid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        resolve(result);
      } else {
        this.getRequestQuery(querySnapshot, resolve);
      }
    });
  }

  /**
   * リクエスト表示用のリスト取得クエリ
   * querySnapshotを実行した結果をリスト化して返すRefactoringメソッド
   * @param {querySnapshot, resolve} querySnapshot 
   * @param {[]} レコードの配列 
   */
  getRequestQuery = async (querySnapshot, resolve) => {
    let result = []; //取得結果のリスト
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      let res = doc.data();
      res.id = doc.id;
      result.push(res);

    })
    resolve(result);

  }


  //ローカルストレージの操作 =======================================================
  /*
  * nsfw: t/F nsfwを表示するかどうか
  * Tの場合は、データを読み出すときにフィルタで除去する
  */
  setNsfwFlg(flg) {
    return new Promise(async (resolve) => {
      let flgVal = "";
      if (flg) {
        flgVal = "true"
      } else {
        flgVal = "false"
      }
      localStorage.setItem('nsfw', flgVal);
      resolve();
    }
    )
  }

  checkNsfwFlg() {
    return new Promise(async (resolve) => {
      var flgVal = localStorage.getItem('nsfw');
      if (flgVal === "true") {
        resolve(true)
      } else {
        resolve(false)
      }

    }
    )

  }

  // Util系のメソッド
  /**
   * param timestamp(FirebaseのTimestamp)
   * return String (YYYY年MM月DD日)
   */
  formatFirebaseTimestampToString = (timestamp) => {
    const date = timestamp.toDate();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    const formatedDate = year + '年' + month + '月' + day + '日';

    console.log("フォーマッた動作確認" + year + '年' + month + '月' + day + '日');

    return formatedDate
  }

  /**
   * firebaseTimestampが、本日より過去であればT
   * @param {*} timestamp 
   * @returns bool 
   */
  overFirebaseTimestampDate = (timestamp) => {
    const date = timestamp.toDate();
    return date < new Date();
  }

  /**
   * 表示前の投稿イラストリストから、ブロック関係にあるユーザの投稿を除去する
   * @param {*} targetList データ型:投稿（Illustlation)の配列
   * @param {*} loginUid 
   * return フィルタ済みの 投稿リスト
   */
  filterBlockData = (targetList, loginUid) => {
    return new Promise(async (resolve, error) => {
      let filterdIllustlationList = [];
      let blockList = await this.checkBlock(loginUid)
      console.log("======ブロックりすと=========")
      console.log(blockList)

      // ブロックリストをフィルタする
      if (blockList != null) {
        filterdIllustlationList = targetList.filter((output, index) => {
          let result = true;

          for (let blackListData of blockList) {
            // ブラックリストに含まれている場合falseにする
            if (output.uid.includes(blackListData.blockUid)) {
              // 自分がブロックしたユーザのuidが作成者の場合false
              // ※ 他人が自分をブロックし、自分の絵が含まれるため除外(from->to両方をorで合わせてリストを持ってきているため)
              if (output.uid === loginUid) {
                result = true;
              } else {
                result = false
              }

            } else if (output.uid.includes(blackListData.tuhoUid)) {
              // 自分をブロックしているユーザのuidが作成者の場合はfalse
              // ※ 他人をブロックした自分のuidがtohoUidに含まれているためそれは除外
              if (output.uid === loginUid) {
                result = true;
              } else {
                result = false
              }
            }
          }
          return result;
        })
      } else {
        // ブラックリストを登録していない場合
        filterdIllustlationList = targetList;
      }

      resolve(filterdIllustlationList);
    });
  }

  /**
   * ファイルサイズをサムネイル用に縮小する。
   * fireを投入し blobを返す
   * @param {*} file 
   * @returns blob uri base64フォーマットのイメージ
   */
  resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });


  // アクセス分析用

  /**
   * 
   * @param {string} type
   * @param {string} comment
   
   * @returns 
   * 
   * テーブル:kpiCounter
   * 
   * type:
   * LP表示数:addLpPv
   * 始めるボタン押下数
   * 直帰しなかった数:addNotBounce
   * ログインボタン押下数
   * （サインアップ）:addSignup
   * クリエイター登録:addCreatorRegist
   * 作品登録: addSelling
   * アクセスごとに１レコード。それが何を操作したものなのかを記録
   */
  addKpi = (type, comment) => {
    return new Promise(async (resolve) => {
      console.log("アクセスログの記録")
      try {
        const newData = await addDoc(collection(db, "kpiCounter"), {
          type: type,
          date: serverTimestamp(),
          comment: comment
        })
      } catch (e) {
        console.log("ログ記録に失敗")
        console.log(e)
        resolve(e);
      }
    });
  }


  /**
   * 決済が完了し、フルフィルメントしていいか確認する
   * @param {pi:text, stripeAccount:text } pi_, stripeAccount
   * @returns 
   */
  getPaymentIntent = (pi_, stripeAccount) => {
    return new Promise(async (resolve) => {
      const addMessage = httpsCallable(functions, 'getPaymentIntent');

      console.log("proxy引数確認======================")
      console.log(pi_)
      addMessage({ id: pi_, stripeAccount: stripeAccount })
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          console.log("======= PAYMENT INTENT 本体=======")
          console.log("======= PAYMENT INTENT 本体=======")
          console.log(result)
          console.log("======= PAYMENT INTENT 本体=======")
          console.log("======= PAYMENT INTENT 本体=======")
          const status = result.data.result.status;
          console.log("有効化状態")
          console.log(status)
          console.log("決済が正常に完了しているか")
          console.log(status == "succeeded")

          if (status == "succeeded") {
            resolve(true)
          } else {
            resolve(false)
          }

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
        });

    })

  }

  // =============================================
  // 部分変更リクエストのStripe処理
  pacialRequestSettlement = ({ requestId, toStripeId, amount }) => {
    return new Promise(async (resolve, reject) => {
      const addMessage = httpsCallable(functions, 'cleateCheckoutForParcialRequest');
      await addMessage({ amount: amount, stripeId: toStripeId })
        .then(async ({ data }) => {
          console.log(data);
          const accountData = data.result.url;
          // Stripe_eventを特定するためのpaymentIntentを取得
          const cs_ = data.result.id;
          console.log("cs_")
          console.log(cs_);
          console.log("ACCOUNT DATA URL")
          console.log(accountData);
          window.open(accountData, '_blank');
          // 購入の完了(Holder)を仮作成。
          // WebHocで決済処理完了で有効化する
          // request にcs_を持たせる
          this.getRequestByid(requestId).then((oldRequestData) => {
            const docRef = doc(db, "Request", oldRequestData.id);

            updateDoc(docRef, {
              cs_: cs_,
              updatedAt: serverTimestamp()
            }).then(() => resolve()).catch((e) => {
              console.llog(" pacialRequestSettlement　決済URLの取得とcs更新に失敗")
              console.log(e)
            })
          }).catch((e) => {
            console.llog("  pacialRequestSettlement　にてgetRequestByid　でrequestの取得に失敗")
            console.log(e)
          })
        })
        .catch((error) => {
          console.log("createCheckoutLink コール失敗");
          console.log(error);
        });
    })
  }





}
export default IfProxy;
