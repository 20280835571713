import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, getDoc, query, deleteDoc, updateDoc, where, addDoc, serverTimestamp, limit, orderBy, startAt, endAt, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, updateProfile, EmailAuthProvider, linkWithCredential, } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import Resizer from "react-image-file-resizer";

import { getFunctions, httpsCallable } from "firebase/functions";


import IfProxy from './IfProxy';

// アプリ全体のFirebase設定 起動直後最初に一回読み込まれる
// APIキーを git管理から外したファイルに設置。これはpushしない
import apiKey from './apikey.json';

// APIキー等の設定情報を取得 起動直後最初に一回読み込まれる
const firebaseConfig = apiKey.firebase;

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const provider = new TwitterAuthProvider();
const functions = getFunctions(app);
const ifProxy = new IfProxy();


class IfProxyStripe {

  getTestCall = () => {
    return new Promise(async (resolve) => {

      const addMessage = httpsCallable(functions, 'addMessage');

      console.log("proxy引数確認======================")
      addMessage({ test: "test" })
        .then((result) => {
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log("FUNCTION========")
          console.log(result)
          console.log(result.test)

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
        });

    })

  }


  /**
   * クリエイター登録をする：Stripe連結アカウントを作成する
   */
  registerCreator = (uid) => {
    return new Promise(async (resolve) => {
      try {
        const createStripeAccount = await httpsCallable(functions, 'createStripeAccount');

        createStripeAccount()
          .then(({ data }) => {
            //console.log("data=============")
            //console.log(data)
            const result = data.result

            // StripeIdを userDataに保持

            ifProxy.getUserData(uid).then((userData) => {
              //console.log("Stripe ID " + result.id);
              userData.stripeId = result.id
              //console.log(" ===userData ===")
              //console.log(userData)
              ifProxy.updateUserData(userData);
              resolve({ status: true, userData: userData })
            })

            window.open(result.url, '_blank');
            // クリエイター初期登録処理を実施
            return null;

          })
          .catch((error) => {
            console.log("registerCreator コール失敗");
            console.log(error);
          });
      } catch (e) {
        console.log(e)
      }


    })

  }

  // クリエイター登録が未完了かどうかを確認
  getStripeAccount = (iputId) => {
    return new Promise(async (resolve) => {
      const addMessage = await httpsCallable(functions, 'checkStripeUserById');
      addMessage({ stripeId: iputId })
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          const accountData = result.data.result.result;
          //console.log("有効化状態")
          //console.log(accountData.charges_enabled)
          if (accountData.charges_enabled) {
            resolve(true)
          } else {
            resolve(false)
          }

        })
        .catch((error) => {
          console.log("checkStripeUserById コール失敗");
          console.log(error);
        });

    })

  }


  /**
   * 決済を行う
   * @param {{toUid,requestId,productId,estimatedAmount}} item 
   */
  settlementRequest = async ({ toUid, requestId, productId, estimatedAmount }) => {
    return new Promise(async (resolve) => {
      // お題を確認
      const request = await ifProxy.getRequestByid(requestId);
      const createrUser = await ifProxy.getUserData(toUid);
      const stripeId = createrUser.stripeId
      // 決済リンクを作成
      const addMessage = httpsCallable(functions, 'createCheckoutLink');
      await addMessage({ stripeId, requestId, estimatedAmount })
        .then(async (result) => {
          console.log("===========createCheckoutLink END===========")
          console.log(result);
          console.log("===========stripe.checkout.sessions.create===========")
          console.log("=========== id cs_ ===========")
          console.log(result.data.result.result.id)

          console.log("result.data" + result.data);
          console.log("result.data.result.url" + result.data.result.result.url);

          const paymentUrl = result.data.result.result.url;


          // Stripe_eventを特定するためのidを取得

          console.log("ACCOUNT DATA URL")
          console.log(paymentUrl);
          window.open(paymentUrl, '_blank');

          // 購入の完了(Holder)を仮作成。
          // WebHocで決済処理完了で有効化する

          const document = await doc(db, "Request", request.id);
          console.log("更新ユーザ===========--");
          try {
            // Stripeの口座登録であればStripeIdを設定。nullを避けるため他は空もじ
            await updateDoc(document, {
              // Stripe決済情報
              cs_: result.data.result.result.id
            }).then(() => {
              resolve()
            }).catch((e) => {
              console.log(e)
            })

          } catch (e) {
            console.log(e)
          }


          /*
          await ifProxy.addHolder(data).then((responce) => {
            console.log("addHolder =======");
            console.log(responce);
            navigate('/HoldDetail/' + responce);
            if (responce == "error") {
   
            }
          });
          */
        })
        .catch((error) => {
          console.log("createCheckoutLink コール失敗");
          console.log(error);
        });
    });

  }




}





export default IfProxyStripe;
