import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


const cardHeight = 200;
const imageHeight = 150;
const cardMargin = 5;

/**
 * 作品一覧で表示する作品の情報をまとめたカード
 * @property { id,displayName, uid, thumbnail }
 * @returns 
 * 
 * 
 * 
 * <要件>
 * アバターを出す
 * ヒーローイメージを背景に出す
 * クリックしたらプロフィールに遷移する
 * 環境に応じて幅を調整する
 * propで受け取ったユーザデータで構成する
 */
const ProductCard = ({ id, displayName, uid, thumbnail }) => {

    const navigate = useNavigate();
    const classes = useStyles();


    /**
     * 作品詳細画面(Product)に遷移
     * @param {*} id 
     */
    const onClickCard = (id) => {
        console.log("カードがクリックされた")
        console.log(id);
        navigate('/Product/' + id);
    }

    const createCard = () => {
        return (

            <Grid item key={id}
            >
                <Card className={classes.card} onClick={() => onClickCard(id)}>
                    <CardContent>
                        <CardMedia
                            component="img"
                            height={imageHeight}
                            image={thumbnail}
                            alt="イラスト"
                        />
                        <Typography color="text.primary" gutterBottom>
                            {displayName}
                        </Typography>


                    </CardContent>
                </Card>
            </Grid>
        )
    }
    return (
        <>
            {createCard()}
        </>
    )
}

export default ProductCard;


const useStyles = makeStyles((theme) => ({

    card: {

        maxWidth: "700px",
        height: cardHeight,
        margin: cardMargin,
    },



}));
