import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import ProfileComp from '../comp/ProfileComp';
import TagInput from '../comp/TagInput'

// スピナーアニメーション
import { Puff } from 'react-loader-spinner'


import {
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { WithContext as ReactTags } from 'react-tag-input';


// タグインプットに関する設定
const KeyCodes = {
  comma: 188,
  enter: [10, 13],
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const Detail = (props) => {

  const [listItems, setListItems] = useState(0);
  const [parchacedFlg, setParchacedFlg] = useState(0);
  const [holderListState, setHolderListState] = useState();
  const [chkBookmark, setChkBookmark] = useState(false);
  const [myBookmarkId, setMyBookmarkId] = useState();
  const [bookmarkUserList, setBookmarkUserList] = useState();
  const [tags, setTags] = useState([{}]);
  const [suggestions, setSuggestions] = useState([
    { id: 'オリジナル', text: 'オリジナル' },
  ]);

  const [janru, setJanru] = useState(false);
  const [zokusei, setZokusei] = useState(false);
  const [situation, setSituation] = useState(false);

  const [loading, setLoading] = useState(false);

  //test
  const [checkBox, setCheckBox] = useState({
    icon: true,
    material: false,
    processing: false,
    commers: false,
  })
  const [permitedUserName, setPermitedUserName] = useState("");


  //test

  // 利用許可ユーザの登録フォームを表示
  const [showPermitedUserView, setShowPermitedUserView] = useState(false);

  // プロフィールコンポーネントサイレダリング
  const [profileId, setProfileId] = useState(0);

  // 初期表示時、stateの項目がまだDBから帰ってこない状態でアクセスしundefinedになるのを防ぐため、
  // stateの初期化時に、項目をからで初期化。async読み込み後にstate更新で表示する
  const [illustData, setIllustData] = useState({
    url: null,

    // 引数から設定
    id: "",
    uid: "",
    forIcon: "",

    freeFlg: false, //商品種別: 無料公開
    monthlyFlg: false,//商品種別: 通常レンタル
    mediaEnableFlg: false,//商品種別: 媒体単位許諾     

    forIconAmount: "",
    forMaterial: "",
    forMaterialAmount: "",
    forProcessing: "",
    forProcessingAmount: "",
    forCommarce: "",
    forCommarceAmount: "",
    forElse: "",

    sales: "",
    nsfw: "",
    dedicated: "",

    tags: [{}],
    janru: [{}],
    zokusei: [{}],
    situation: [{}],
    janruStrings: [{}],
    zokuseiStrings: [{}],
    situationStrings: [{}],
    pv: "",
    bookmark: ""
  });

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();
  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    loadData(params);
    return () => {
      // リクエスト中にコンポーネントがアンマウントしてしまった場合、
      // 戻す先(setState)がなく、リクエスト状態が続いてしまう。
      // アンマウントしたらStateをからにする
      // https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
      setTags([]);
      setSuggestions([]);
    }

  }, []);


  const loadData = async (props) => {

    console.log("画面遷移後＝ パスパラメータ：params ＝＝＝＝＝＝＝＝＝＝");
    console.log(params);
    console.log(location);

    // ログイン前はスキップ
    let holdCheck = false
    if (user) {
      // 所有チェック
      holdCheck = await ifProxy.chkHold(params.id, user.uid);
      // ブックマークチェック
      let bookMarkedCheck = await ifProxy.getBookmarkUserList(params.id);
      for (let bookMark of bookMarkedCheck) {
        // このイラストをbookmariした人のリレーションリストに、user.uidが存在するかチェック
        if (bookMark.fromUid === user.uid) {
          setChkBookmark(true);
          setMyBookmarkId(bookMark.id);
        }
      }
      // ブックマークしたユーザリストを作成
      bookmarkList(bookMarkedCheck);
    }


    await ifProxy.getIllustlation(params.id).then((data) => {
      // 権利者一覧情報を、イラストidから取得
      holderList(data.id);
      // sampleData
      let item = {
        url: data.url,

        hold: holdCheck,

        // 引数から設定
        id: data.id,
        uid: data.uid,

        freeFlg: data.freeFlg, //商品種別: 無料公開
        monthlyFlg: data.monthlyFlg,//商品種別: 通常レンタル
        mediaEnableFlg: data.mediaEnableFlg,//商品種別: 媒体単位許諾     

        forIcon: data.forIcon,
        forIconAmount: data.forIconAmount,
        forMaterial: data.forMaterial,
        forMaterialAmount: data.forMaterialAmount,
        forProcessing: data.forProcessing,
        forProcessingAmount: data.forProcessingAmount,
        forCommarce: data.forCommarce,
        forCommarceAmount: data.forCommarceAmount,
        forElse: data.forElse,

        sales: data.sales,
        nsfw: data.nsfw,
        dedicated: data.dedicated,


        tags: data.tags,

        janru: data.janru || [{}],
        zokusei: data.zokusei || [{}],
        situation: data.situation || [{}],
        janruStrings: data.janruStrings || [{}],
        zokuseiStrings: data.zokuseiStrings || [{}],
        situationStrings: data.situationStrings || [{}],


        pv: data.pv,
        bookmark: data.bookmark,

        deletedAt: data.deletedAt
      }


      setIllustData(item);
      setProfileId(data.uid);
      // タグの初期値を設定
      setTagsByIllustLation(data.tags);


      console.log("LOAD")
      console.log(data)
      console.log(data.janruStrings)


      //  [{ id: "id", tagsString: ["タグ1", "タグ2"] }]
      setJanru((pre) => {
        return { id: data.id, tagsString: data.janruStrings };
      })
      setZokusei((pre) => {
        return { id: data.id, tagsString: data.zokuseiStrings };
      })
      setSituation((pre) => {
        return { id: data.id, tagsString: data.zokuseiStrings };
      })

      setLoading(false)

    })
  }

  // tagsをillustlationデータがら設定する
  const setTagsByIllustLation = (illustTags) => {
    setTags(illustTags);
  }

  // 申し込み画面へ遷移
  const registration = () => {
    navigate('/Registration/' + illustData.id);
  }

  // レンタル販売中のメニューと金額。イラストに設定されているメニューだけ表示する
  /**
   * 
   * @returns メニューの表示
   */
  const menuLabel = () => {

    // 無料版のメニュー
    if (illustData.freeFlg) {
      return (
        <Box>
          <ul>
            <Typography variant="h2" color="text.secondary" gutterBottom>
              無料
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
              登録後は「利用可能な範囲」で無料でイラストの利用が可能
            </Typography>
            {illustData.forElse !== "" &&
              <li>
                利用可能な範囲<br></br>
                {illustData.forElse}

              </li>
            }
          </ul>
        </Box>
      )

    }

    // レンタルのメニュー
    if (illustData.monthlyFlg) {
      return (
        <Box>
          <ul>
            <Typography variant="h2" color="text.secondary" gutterBottom>
              レンタル
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
              一定の期間の利用許諾を購入することができます
            </Typography>

            {illustData.forIcon &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  SNSアイコンとしての使用料/月額 ¥{illustData.forIconAmount}
                </Typography>
              </li>
            }
            {illustData.forMaterial &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  素材使用料/月額 ¥{illustData.forMaterialAmount} <br></br>
                  SNS投稿、ブログ、webサイトでの掲載
                </Typography>
              </li>
            }
            {illustData.forProcessing &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  加工許可オプション/月額 ¥{illustData.forProcessingAmount} <br></br>
                  上記使用に加え、画像加工の許諾あり (著作者クレジット 要)
                </Typography>
              </li>
            }
            {illustData.forCommarce &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  商用利用/月額 ¥{illustData.forCommarceAmount} <br></br>
                  イラストを利用した制作物の販売 (著作者クレジット 要)
                </Typography>
              </li>
            }
            {illustData.forElse !== "" &&
              <li>
                その他使用制限<br></br>
                {illustData.forElse}

              </li>
            }
          </ul>
        </Box>
      )
    }

    // 媒体利用のメニュー
    if (illustData.mediaEnableFlg) {
      return (
        <Box>
          <ul>
            <Typography variant="h2" color="text.secondary" gutterBottom>
              媒体利用
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
              以下いずれかの範囲での利用を、無期限(作者が公開を終了するまで）利用できます<br></br>
              動画・配信活動ならば、１チャンネル<br></br>
              SNSアカウントならば、１アカウント<br></br>
              (同人誌・ダウンロード作品での販売の場合は１作品(タイトル))<br></br>
            </Typography>
            {illustData.forIcon &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  SNSアイコンとしての使用料/1チャンネルあたり ¥{illustData.forIconAmount}
                </Typography>
              </li>
            }
            {illustData.forMaterial &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  素材使用料/1チャンネルあたり ¥{illustData.forMaterialAmount} <br></br>
                  SNS投稿、ブログ、webサイトでの掲載
                </Typography>
              </li>
            }
            {illustData.forProcessing &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  加工許可オプション/1チャンネルあたり ¥{illustData.forProcessingAmount} <br></br>
                  上記使用に加え、画像加工の許諾あり (著作者クレジット 要)
                </Typography>
              </li>
            }
            {illustData.forCommarce &&
              <li>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  商用利用/1チャンネルあたり ¥{illustData.forCommarceAmount} <br></br>
                  イラストを利用した制作物の販売 (著作者クレジット 要)
                </Typography>
              </li>
            }
            {illustData.forElse !== "" &&
              <li>
                その他使用制限<br></br>
                {illustData.forElse}

              </li>
            }
          </ul>
        </Box>
      )
    }
  }


  // 権利者リスト系のコントロール==================
  const holderList = (id) => {
    const ifProxy = new IfProxy();
    ifProxy.getHolderList(id).then((data) => {
      console.log("===== getHolderList ======");
      console.log(data);
      let listItems = data.map((item) => {
        if (item.valid) {
          return createCard(item)
        } else {
          console.log("itemInvalid ========-")
          console.log(item.valid)
          return
        }

      }
      );
      console.log(listItems);
      setHolderListState(listItems);
    });
  }
  const createCard = (item) => {
    return (

      <Box key={item.id} sx={{ margin: 2, borderBottom: 1, borderColor: "#CCCCCC" }}>
        <Avatar sx={{ width: 50, height: 50 }}
          src={item.holderUserUrl} />
        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>

          {item.holderUser}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => holderDetail(item.id)}
          sx={{ margin: 2 }}>
          証明書
        </Button>
      </Box>
    )
  };
  // HlderレコードのdocIdで、画面遷移
  const holderDetail = (id) => {
    navigate('/HoldDetail/' + id);
  }

  // 許可済みユーザの追加関係 ===============================
  const addPermitedUser = () => {
    setShowPermitedUserView(true);
  }

  // 許可するユーザ名
  const handleSetPermitedUserName = (event) => {
    console.log("FORM要素:許可するユーザ名 event.target.value =====");
    console.log(event.target.value);
    setPermitedUserName(event.target.value);
  }

  const handleForIcon = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    setCheckBox(prev => {
      let data = {
        icon: event.target.checked,
        material: prev.material,
        processing: prev.processing,
        commers: prev.commers,
      }
      console.log(data);
      return data
    });


  }

  // 素材使用について
  const handleForMaterial = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    setCheckBox(prev => {
      let data = {
        icon: prev.icon,
        material: event.target.checked,
        processing: prev.processing,
        commers: prev.commers,
      }
      console.log(data);
      return data
    });
  }
  // 加工の許諾について

  const handleForProcessing = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    setCheckBox(prev => {
      let data = {
        icon: prev.icon,
        material: prev.material,
        processing: event.target.checked,
        commers: prev.commers,
      }
      console.log(data);
      return data
    });
  }

  // 商用利用の許諾
  const handleForCommarce = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    setCheckBox(prev => {
      let data = {
        icon: prev.icon,
        material: prev.material,
        processing: prev.processing,
        commers: event.target.checked,
      }
      console.log(data);
      return data
    });
  }

  const permitedUserForm = () => {
    if (showPermitedUserView) {

      return (
        <Box>
          <Card className={classes.card}>
            <CardContent>

              <TextField
                id="outlined-required"
                label="許可するユーザ名"
                value={permitedUserName}
                defaultValue=""
                onChange={handleSetPermitedUserName}
              />
              <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                {permitedUserName} さんに次の権限を許諾する
              </Typography>
              <FormControlLabel control={<Checkbox defaultChecked onChange={handleForIcon} />} label="SNSアイコンとしての使用" />
              <FormControlLabel control={<Checkbox onChange={handleForMaterial} />} label="画像素材としての利用(icon以外のSNS・ブログ・動画での利用)" />
              <FormControlLabel control={<Checkbox onChange={handleForProcessing} />} label="加工(SNS投稿時に発生するトリミング以外の加工)" />
              <FormControlLabel control={<Checkbox onChange={handleForCommarce} />} label="商用利用" />

            </CardContent>
            <CardActions>
              {user ? <Button onClick={registerAddPermitedUser}>登録する</Button> : <Button disable >登録する (ログイン後有効化)</Button>}
              <Button onClick={() => setShowPermitedUserView(false)}>閉じる</Button>
            </CardActions>
          </Card>
        </Box>
      )
    } else {
      return null;
    }
  }

  /**
   * 作者が別で既に許可しているユーザ
   */
  const registerAddPermitedUser = () => {
    let timestamp = getStringFromDate(new Date());

    let retustrationData = {
      permited: true, //投稿者許可
      illustId: illustData.id,
      illustUid: illustData.uid,
      illustUrl: illustData.url,
      thumbnail: illustData.thumbnail,
      holderUid: "permitedUser" + user.uid + timestamp, //被らせないためのランダム値
      holderUser: permitedUserName,
      holderUserUrl: "", //空にするとデフォルトになる？

      icon: checkBox.icon,
      material: checkBox.material,
      processing: checkBox.processing,
      commers: checkBox.commers,
      active: true,
      amount: 0,
      settlement: "",

    }
    ifProxy.addHolder(retustrationData).then(() => {
      window.location.reload();
    })
  }
  //日付から文字列に変換する関数 仮holderUserのuidを設定。
  function getStringFromDate(date) {

    var year_str = date.getFullYear();
    //月だけ+1すること
    var month_str = 1 + date.getMonth();
    var day_str = date.getDate();
    var hour_str = date.getHours();
    var minute_str = date.getMinutes();
    var second_str = date.getSeconds();


    let format_str = 'YYYYMMDDhhmmss';
    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);

    return format_str;
  };


  // ブックマークリスト系のコントロール==================
  const bookmarkList = (list) => {

    let listItems = list.map((item) =>
      createBookmarkUser(item)
    );
    console.log(listItems);
    setBookmarkUserList(listItems);
  }
  const createBookmarkUser = (item) => {
    return (

      <Box key={item.id} sx={{ margin: 2, borderBottom: 1, borderColor: "#CCCCCC" }}>
        <Avatar sx={{ width: 50, height: 50 }}
          src={item.fromPhotoURL} />
        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>

          {item.fromDisplayName}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => bookmarkUser(item.fromUid)}
          sx={{ margin: 2 }}>
          詳細
        </Button>
      </Box>
    )
  };
  // HlderレコードのdocIdで、画面遷移
  const bookmarkUser = (id) => {
    navigate('/User/' + id);
  }

  const bookmark = () => {
    console.log("ブックマークするボタン")
    ifProxy.addBookmark(user.uid, illustData.id).then(() => {
      window.location.reload();
    })
  }
  const deleteBookmark = () => {
    ifProxy.deleteBookmark(myBookmarkId);
    setChkBookmark(false);
    window.location.reload();
  }
  /**
   * 部分変更リクエスト
   */
  const request = () => {
    // /CreateRequest/イラストのuid/イラストのid/
    navigate(`/CreateRequest/${illustData.uid}/${illustData.id}`)
  }

  // タグ設定まわり
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  }

  const handleAddition = (tag) => {
    console.log("======TAG=========")
    setTags((pre) => {
      return [...pre, tag]
    })
  }

  // 動いていないかも
  const handleDrag = (tag, currPos, newPos) => {
    const tagsData = tags;
    const newTags = tagsData.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  }

  // タグ更新を反映。illustlationのtagsの配列項目を上書き
  const updateTags = () => {
    console.log("=====updateTags=======")
    ifProxy.updateTags(illustData.id, tags)
    console.log(tags);
  }

  const closeIllustlation = () => {
    ifProxy.closeIllustlation(illustData.id);
    window.location.reload();
  }


  /**
   * 登録ボタン類
   * @returns 
   */
  const controllBtn = () => {
    if (illustData.sales) {
      return (
        <CardActions>
          {!illustData.hold && <Button
            size="large"
            variant="contained"
            color="primary"
            sx={{ margin: 2 }}
            onClick={registration}
          >購入・無料利用</Button>
          }

          {chkBookmark ?
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deleteBookmark()}
              sx={{ margin: 2 }}>
              ブックマークを解除する
            </Button>
            :
            <Button
              variant="contained"
              color="secondary"
              onClick={() => bookmark()}
              sx={{ margin: 2 }}>
              ブックマークする
            </Button>
          }
          {user.uid === illustData.uid &&
            <Button
              variant="contained"
              color="secondary"
              onClick={() => closeIllustlation()}
              sx={{ margin: 2 }}>
              公開を終了する
            </Button>
          }
          {/** 本当はStripeIDが入っていて、有効である場合のみ */}
          {true && <Button
            variant="contained"
            color="primary"
            onClick={() => request()}
            sx={{ margin: 2 }}>
            部分変更リクエスト
          </Button>}

        </CardActions>
      )

    } else {

      // illustDataはデータロード前も呼び出されundefinedエラーとなる
      // そのため、読み込み前の参照時はまだ取り扱わないように回避する
      if (!illustData.deletedAt) {
        return;
      } else {
        let date = illustData.deletedAt.toDate()
        let dateYear = date.getFullYear();
        let dateMonth = date.getMonth() + 1;
        let dateDay = date.getDate();
        let deletetDate = dateYear + "年" + dateMonth + "月" + dateDay + "日";

        //月末
        var lastDate = new Date();
        lastDate.setMonth(date.getMonth() + 1);
        lastDate.setDate(0);
        console.log("末日 : " + lastDate)
        let ldateYear = lastDate.getFullYear();
        let ldateMonth = lastDate.getMonth() + 1;
        let ldateDay = lastDate.getDate();
        let ldeletetDate = ldateYear + "年" + ldateMonth + "月" + ldateDay + "日";


        return (
          <CardActions>
            <Typography variant="h3" component="div">
              公開<br></br>終了
            </Typography>
            <Typography variant="body2" component="div">
              本作品は{deletetDate}に作者によって新規公開終了となりました<br></br>
              本作品の使用許諾は、既に決済ずみの使用期限までで終了となります<br></br>
              申し訳ございませんが、期日までに、掲載等の使用を行っている場合は、差し替え等を完了してください<br></br>
              <br></br>
              レンタル料金のお支払いは前回分が最後となります。<br></br>


            </Typography>
          </CardActions>
        )
      }


    }
  }

  /**　権利者一覧に、任意のユーザを登録するボタンの表示
   * ボタンの表示条件
   * ・ログインしている
   * ・イラストの作成者とログインユーザが同じ
   * @returns 許可済みのユーザを追加するボタンコンポーネント
   */
  const setSelectedHolder = () => {
    if (user && user.uid === illustData.uid) {
      return (
        <Button onClick={addPermitedUser}>無料利用を許可するユーザを追加する</Button>
      )
    }

  }
  /**シェアボタン
   * 
   */
  const shareButton = () => {
    console.log(tags);
    const tag = tags.map((item) => {
      return item.id
    }
    )

    return (
      <TwitterShareButton
        url={"https://illustrental.com/Detail/" + illustData.id}
        title={"イラスト利用レンタル中"}
        via="illustrental"
        related={["illustrental"]}
        hashtags={tag}
      >
        <Button

          variant="contained"
          color="secondary"
          sx={{
            margin: 2,
            borderRadius: 50
          }}
        >
          <TwitterIcon size={50} round /> イラストをシェアする</Button>
      </TwitterShareButton>
    )

  }

  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values
  if (loading) {
    return (<>
      ...読込中
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#4fa94d"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />


    </>)
  } else {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={8} >
                <Card className={classes.card}>
                  <CardContent>

                    <CardMedia
                      component="img"
                      image={illustData.url}
                      alt="画像"
                      sx={{
                        width: 500,
                        padding: 2,
                        boxShadow: 1,
                        alignItems: 'center'
                      }}
                    />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      ブックマーク数:{illustData.bookmark} 閲覧数:{illustData.pv} NSFW:{illustData.nsfw}
                    </Typography>
                    {illustData.hold &&
                      <Typography variant="h5" color="text.secondary" gutterBottom>
                        取得済み
                      </Typography>
                    }
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      データ番号:{illustData.id}
                    </Typography>
                    <Box className={classes.centerBox}>
                      {janru && <TagInput type="view" tagViews={[janru]} target="janru"></TagInput>}
                    </Box>
                    <Box className={classes.centerBox}>
                      {zokusei && <TagInput type="view" tagViews={[zokusei]} target="zokusei"></TagInput>}
                    </Box>
                    <Box className={classes.centerBox}>
                      {situation && <TagInput type="view" tagViews={[situation]} target="situation"></TagInput>}
                    </Box>
                    <Box
                      sx={{
                        fontSize: 20,
                        color: 'gray',
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      <ReactTags tags={tags}
                        suggestions={suggestions}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        placeholder="エンターで確定"
                        delimiters={delimiters}
                      />
                      {user ? <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => updateTags()}
                        sx={{ margin: 2 }}>
                        タグ更新を反映する
                      </Button>
                        : null}

                    </Box>


                    <Typography variant="h5" component="div">
                      使用権レンタルメニュー
                    </Typography>
                    {menuLabel()}
                    <Box sx={{
                      color: 'gray',
                    }}>
                      {shareButton()}
                      <Typography variant="body.secondary" component="div">
                        ※ 画像の添付は手動でお願いいたします。<br></br>シェアして応援！ お願いします。
                      </Typography>
                    </Box>


                  </CardContent>
                  {user ? controllBtn() : null}
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} >
                {profileId && <ProfileComp uid={illustData.uid} />}
                <Box sx={{
                  padding: 2,
                  boxShadow: 1,
                  alignItems: 'center'

                }}>
                  <h2>権利者一覧</h2>
                  {setSelectedHolder()}
                  {permitedUserForm()}

                  {holderListState}
                  <h2>ブックマークした人</h2>
                  {bookmarkUserList}

                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </HeadFooter >

    );

  }

}
export default Detail;


const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
