import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useDropzone } from 'react-dropzone';
import CardMedia from '@mui/material/CardMedia';


import Typography from '@mui/material/Typography';


/**
 * 
 * @param {*} props uidを入れているが、実はcontextから取っているのでいらない
 * @returns 
 */
const UserEdit = (props) => {

  const [listItems, setListItems] = useState(0);
  // フォームインプット内容
  const maxSize = 10 * 1024 * 1024;
  const [imageData, setImageData] = useState(0);
  const [fileUrl, setFileUrl] = useState();

  const [name, setName] = useState();
  const [displayName, setDisplayName] = useState("");

  const [discription, setDiscription] = useState();
  const [docId, setDocId] = useState(0);
  const [pixiv, setPixiv] = useState();
  const [twitter, setTwitter] = useState();
  const [ticktok, setTicktok] = useState();
  const [site, setSite] = useState();
  const [penaltyConfig, setPenaltyConfig] = useState();

  //ジャンル
  const [genre, setGenre] = useState();
  //タグ
  const [tags, setTags] = useState();
  // 保有レンタル数
  const [rentalCount, setRentalCount] = useState();
  // 獲得ブックマーク数
  const [bookMarkedCount, setBookMarkedCount] = useState();
  // 獲得レンタル数
  const [rentaledCount, setRentaledCount] = useState();

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  }, []);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  const loadData = (props) => {
    //console.log("USER=======================");
    //console.log(user);
    //console.log("twitter : reloadUserInfo=======================");
    //let twitterUid = user.reloadUserInfo;
    //console.log(twitterUid);
    //console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝");
    //console.log(params);
    //console.log(location);
    const ifProxy = new IfProxy();
    ifProxy.getUserData(user.uid).then((result) => {
      console.log("getUserData");
      console.log(result);
      setDisplayName(user.displayName || "");
      setDocId(result.id || "");
      setDiscription(result.discription || "");
      setPixiv(result.pixiv || "");
      setTwitter(result.twitter || "");
      setTicktok(result.ticktok || "");
      setSite(result.site || "");
      setPenaltyConfig(result.penaltyConfig || "");

      // ジャンル等右ペインの情報
      setGenre(result.genre || "");
      setTags(result.tags || "");

    })
  }

  /**
   * 画像アップロード周り
   */
  const onDrop = useCallback((acceptedFiles) => {

    if (acceptedFiles.length > 0) {
      setImageData(acceptedFiles[0]);
      const src = URL.createObjectURL(acceptedFiles[0]);
      setFileUrl(src);
    }

  }, []);
  // ドラッグアンドドロップでアップロードファイルパスを登録
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept: 'image/png, image/jpeg, image/gif, image/jpg',
    minSize: 1,
    maxSize: maxSize
  });

  // ファイルアップロードホバー中の表示を切り替え
  const uploadAreaStyle = useMemo(() => (
    { fileUploadStyle, ...(isDragActive ? borderDragStyle : fileUploadStyle) }
  ), [isDragActive]);





  // プロフィール情報の更新

  /**
   * データは三種類
   * ・Authデータをそのまま登録するもの
   * ・ユーザに操作させない情報（スコア情報など）をそのまま戻すもの
   * ・ユーザが更新可能な情報
   */
  const updateData = () => {
    const authUserData = {
      displayName: displayName,
      blob: imageData
    }
    const ifProxy = new IfProxy();
    const updatedUser = ifProxy.updateUser(authUserData).then((result) => {

      const photo = result.url || user.photoURL;
      console.log("設定するurl")
      console.log(photo)
      const data = {
        id: docId,
        uid: user.uid, // Auth値固定（Auth)
        //name: user.reloadUserInfo.screenName || user.displayName, // Auth値固定（twitterID固定) reloadUserInfo:twitterプロバイダからのユーザ情報
        //displayName: user.reloadUserInfo.displayName, // Auth値固定（twitter)
        name: name || "",
        displayName: result.displayName,
        photoURL: photo,

        //フォームから自由に設定
        discription: discription, // twitterから自動取得できるまでは手動

        genre: genre,
        tags: tags,

        pixiv: pixiv,
        twitter: twitter,
        ticktok: ticktok,
        site: site,
        penaltyConfig: penaltyConfig,
      }
      console.log("updateData");
      console.log(data);


      ifProxy.updateUserData(data).then((result) => {
        navigate(`/User/${user.uid}`);
      }).catch((e) => {
        console.log("error updateData")
        console.log(e);
      })
    }).catch((e) => {
      console.log("error updateData")
      console.log(e);
    })

  }
  /*
  // contextのuserから固定値を設定
    uid : data.uid, // Auth値固定（Auth)
    name: data.name, // Auth値固定（twitterID固定)
    displayName: data.displayName, // Auth値固定（twitter)

    //フォームから自由に設定
    discription: data.discription
    pixiv: data.pixiv,
    twitter: data.twitter,
    ticktok: data.ticktok,
    site: data.site,
    penaltyConfig: data.penaltyConfig,
  */

  const handleChangeName = (e) => {
    //console.log("handleChangeDiscription : " + e.target.value);
    setName(e.target.value);
  }
  const handleChangeDisplayName = (e) => {
    //console.log("handleChangeDiscription : " + e.target.value);
    setDisplayName(e.target.value);
  }
  const handleChangeDiscription = (e) => {
    //console.log("handleChangeDiscription : " + e.target.value);
    setDiscription(e.target.value);
  }
  const handleChangePiviv = (e) => {
    //console.log("handleChangePiviv : " + e.target.value);
    setPixiv(e.target.value);
  }
  const handleChangeTwitter = (e) => {
    //console.log("handleChangeTwitter : " + e.target.value);
    setTwitter(e.target.value);

  }
  const handleChangeTicktok = (e) => {
    //console.log("handleChangeTicktok : " + e.target.value);
    setTicktok(e.target.value);
  }
  const handleChangeSite = (e) => {
    //console.log("handleChangeSite : " + e.target.value);
    setSite(e.target.value);
  }
  const handleChangePenaltyConfig = (e) => {
    //console.log("handleChangePenaltyConfig : " + e.target.value);
    setPenaltyConfig(e.target.value);
  }

  // ジャンルとタグ 入力フォーム
  const handleChangeGenre = (e) => {
    setGenre(e.target.value);
  }
  const handleChangeTags = (e) => {
    setTags(e.target.value);
  }

  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>
          <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs={12} sm={12} md={12} >
              <Card className={classes.card}>
                <CardContent>

                  {imageData ? <div><CardMedia
                    component="img"
                    image={fileUrl}
                    alt="投稿画像"
                    sx={{
                      padding: 2,
                      boxShadow: 1,
                      alignItems: 'center'

                    }}
                  /></div> : <div {...getRootProps({
                    className: fileUploadStyle
                  })} style={uploadAreaStyle}>
                    <input {...getInputProps()} />
                    <p>ここへファイルをドラッグしてアップロード (10MBまで)</p>
                  </div>
                  }
                  現在のアイコン
                  <Avatar
                    src={user.photoURL}
                    sx={{ width: 100, height: 100 }}
                  />
                  {/*<h1>{user.reloadUserInfo.displayName}</h1>*/}
                  <TextField
                    id="filled-multiline-flexible"
                    fullWidth
                    label="ユーザ名"
                    value={displayName}
                    onChange={handleChangeDisplayName}
                    variant="filled"
                    placeholder=""
                    sx={{ marginTop: 2 }}
                  />
                  <TextField
                    id="filled-multiline-flexible"
                    fullWidth
                    label="twitterのid"
                    value={name}
                    onChange={handleChangeName}
                    variant="filled"
                    placeholder="@username"
                    sx={{ marginTop: 2 }}
                  />


                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    以下全て任意の項目
                  </Typography>
                  <div>

                    <TextField
                      id="filled-multiline-flexible"
                      fullWidth
                      label="プロフィール"
                      multiline
                      maxRows={4}
                      value={discription}
                      onChange={handleChangeDiscription}
                      variant="filled"
                      placeholder=""
                      sx={{ marginTop: 0 }}
                    />
                  </div>
                  {/*
                    <div>
                      <TextField
                        id="filled-multiline-flexible"
                        fullWidth
                        label="ジャンル"
                        multiline
                        maxRows={4}
                        value={genre}
                        onChange={handleChangeGenre}
                        variant="filled"
                        sx={{ marginTop: 2 }}
                      />
                    </div>
                    */}
                  <div>

                    <TextField
                      id="filled-multiline-flexible"
                      fullWidth
                      label="タグ"
                      multiline
                      maxRows={4}
                      value={tags}
                      onChange={handleChangeTags}
                      placeholder="得意なキャラ名, 得意ジャンル"
                      variant="filled"
                      sx={{ marginTop: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="filled-multiline-flexible"
                      fullWidth
                      label="Pixivアカウントurl"
                      value={pixiv}
                      onChange={handleChangePiviv}
                      variant="filled"
                      placeholder="https://www.pixiv.net/users/xxxxxxxxxx"
                      sx={{ marginTop: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="filled-multiline-flexible"
                      fullWidth
                      label="twitterアカウントurl"
                      value={twitter}
                      onChange={handleChangeTwitter}
                      variant="filled"
                      placeholder="https://twitter.com/xxxxxx"
                      sx={{ marginTop: 2 }}
                    />
                  </div>
                  {/*
                    <div>
                      <TextField
                        id="filled-multiline-flexible"
                        fullWidth
                        label="ticktokアカウントurl"
                        value={ticktok}
                        onChange={handleChangeTicktok}
                        variant="filled"
                        sx={{ marginTop: 2 }}
                      />
                    </div>
                  */}
                  <div>
                    <TextField
                      id="filled-multiline-flexible"
                      fullWidth
                      label="その他サイトurl"
                      value={site}
                      onChange={handleChangeSite}
                      variant="filled"
                      placeholder="https://www.xxxxx"
                      sx={{ marginTop: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="filled-multiline-flexible"
                      fullWidth
                      label="無断使用時のペナルティ要件"
                      value={penaltyConfig}
                      multiline
                      maxRows={4}
                      onChange={handleChangePenaltyConfig}
                      variant="filled"
                      sx={{ marginTop: 2 }}
                    />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" >
                      文例
                      <br></br>
                      私の作品を無断利用した場合は利用期間に応じて利用料を請求させていただきます。利用期間が不明な場合は１作品につき10万円を目安に請求いたします
                      <br></br>
                      私及び第三者の権利侵害は決して行わないでください。私の作品を利用して第三者の権利侵害が行われた場合は、当事者間での解決を行うこととし、一切の補償は致しません。
                      <br></br>
                      上記を行わないことを約束する場合のみ私の作品利用を許諾いたします
                    </Typography>
                  </div>

                </CardContent>

                <Button variant="contained"
                  color="secondary"
                  sx={{ margin: 2 }}
                  onClick={updateData}>
                  更新
                </Button>
              </Card>
            </Grid>

          </Grid>
        </Box>


      </Box>


    );
  }




}
export default UserEdit;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
// アップロードのためのドラッグエリア
const fileUploadStyle = {
  margin: 20,
  height: 150,
  border: "10px dotted #888",
  color: 'blue'
};
// ドラッグエリアにホバーした時のスタイル
const borderDragStyle = {
  margin: 20,
  height: 150,
  backgroundColor: '#E5E5FF',
  border: "1px solid #E5E5FF",
  transition: 'border .5s ease-in-out'
};
