import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';



const LpCreator = (props) => {
  const { user } = useAuthContext();
  const classes = useStyles();

  useEffect((props) => {
    return () => {
    }

  }, []);

  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values
  return (
    <HeadFooter>
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>

          <Grid container justify="center" spacing={3} sx={{ marginTop: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Typography variant="h3" component="div" sx={{ margin: 2 }}>
              利用規約
            </Typography>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >

              <Typography variant="h6" component="div">
                第1条 はじめに
              </Typography>
              <Typography variant="h7" component="div">
                1. イラストレンタル（以下「当社」といいます）が提供するサービス（以下として「本サービス」といいます。）を、ユーザーが利用する場合の一切の行為に適用されます。ユーザーは、本規約に同意の上、本規約に従い本サービスを利用するものとします。<br></br>
                2.	利用規約は、本サービスの利用に係る規約を定めるものです。本サービスにアクセス、又は利用する方すべて（会員登録をした者に限りません。以下「ユーザー」といいます。）に、利用規約は適用されます。<br></br>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第2条 定義
              </Typography>
              <Typography variant="h7" component="div">
                本規約において使用する用語の意義は、次の各号に定めるとおりとします。 <br></br>
                (ア)	「作者」作品を制作し著作権を保持し許諾の提供のためにサイトを閲覧する利用者を指します <br></br>
                (イ)	「利用者」サイトを閲覧し利用許諾を得たユーザまたは利用許諾の調達を目的とした閲覧者を指します <br></br>
                (ウ)	「ユーザ」作者、利用者双方を含む全てのサイト閲覧者を指します <br></br>
                (エ)	「投稿情報」とは、作成者及び利用者が投稿したデータ（画像、テキスト、その他コンテンツ）を指します <br></br>

              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第3条 規約の変更
              </Typography>
              <Typography variant="h7" component="div">
                1.	本規約および個別規約等は、当社の判断により、いつでも変更することができるものとします。変更の際はその旨およびその内容、施行時期を当社の定める方法で適切に周知します。<br></br>
                2.	変更後の本規約および個別規約等は、当社が別途定める場合を除き、本サービス上に表示した時点より効力を生じるものとします。<br></br>
                3.	本規約または個別規約等の変更の効力が生じた後にユーザーが該当する本サービスを利用した場合には、変更後の本規約および個別規約等のすべてにつき、同意したものとみなします。<br></br>


              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第4条  個人情報の取扱い
              </Typography>
              <Typography variant="h7" component="div">
                当社は、個人情報を「プライバシーポリシー」に基づき、適切に取り扱うものとします。
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第5条  利用料金と支払い方法
              </Typography>
              <Typography variant="h7" component="div">
                1.	作品利用権の販売を行うユーザ(著作者)はStripe社の口座を開設を行い料金の受け取りを行います。利用者が作者の投稿情報利用権を購入する場合はStripe社が提供する決済サービスでクレジットカード決済または口座決済等で料金を支払います。<br></br>
                2.	サイト利用料は決済時のみ発生しその他の利用料金は発生しません。決済時は決済金額の23.6%をサイト及び決済手数料として徴収いたします。<br></br>

              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第6条  ユーザーの責任
              </Typography>
              <Typography variant="h7" component="div">
                1.	ユーザーは、自己の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果についての責任を負います。<br></br>
                2.	ユーザーが、本サービスを利用して行う他のユーザーとの取引は、ユーザー同士の直接の取引であり、当社は契約当事者にはなりません。<br></br>
                3.	前項のユーザー間取引に伴う各種作業、連絡、法的義務の履行およびトラブル対処等は、取引当事者たるユーザー同士で行うものとします。<br></br>
                4.	本サービスを利用してユーザーが投稿等した情報に関する責任は、すべてユーザーに帰属します。当社はユーザーが本サービスを利用して投稿等した情報の内容について、責任を負いません。<br></br>
                5.	ユーザーが本規約に違反したことにより、当社または第三者に損害が生じた場合、ユーザーはその損害を賠償する責任を負います。<br></br>
                6.	ユーザーと第三者との間で、本サービスに関連して生じた紛争に関して、当該ユーザーは自身の責任と費用で解決するものとし、当社は、当該紛争に関与しません。また当該ユーザーは、当該紛争に関連して当社に生じた損害（弁護士費用その他の当社が当該紛争に関連する対応のために支払った費用及び賠償金等を含むが、これらに限りません。）を賠償するものとします。<br></br>


              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第7条  禁止行為
              </Typography>
              <Typography variant="h7" component="div">
                1.	当社、又はあらゆる第三者の著作権等知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（本サービス上の投稿情報の無断使用、及び第三者が権利を有する画像・テキスト・情報等の著作物の提供等を含む）<br></br>
                2.	作者が著作権を保有していない作品を公開・利用権販売を行うこと<br></br>
                3.	利用権を購入した利用者は許諾の範囲を超えて利用すること<br></br>
                4.	当社からのコンテンツ利用に関する適正な許諾を得ずに、コンテンツをダウンロード、サーバー等のハードディスクに保存、複製、改変、翻案、頒布、譲渡、貸与、送信、販売する行為<br></br>
                5.	利用権を購入した利用者は、利用期間超過後に作品の利用を続けること<br></br>
                6.	投稿者が購入者に対して、当社の定めた支払方法以外の決済手段の利用を推奨し、又は誘導する行為<br></br>
                7.	本サービスにおいて、虚偽又は過誤の情報を入力すること<br></br>
                8.	コンピューターウィルス、リバースエンジニアリング等インターネットを通じた違法行為又は迷惑行為により本サービスの運営を妨害すること<br></br>
                9.	会員登録時又はログイン時に虚偽の情報を入力し、権限なく、又は他人になりすまして本サービスを利用すること<br></br>
                10.	中傷、嫌がらせ、ハラスメント、脅迫等の迷惑行為を当社及びあらゆる第三者に対して行うこと<br></br>
                11.	本サービスにおいて提供する商品又はサービスの範囲を超えて、他の会員等に対して、宣伝、勧誘、営業、その他連絡を行うこと<br></br>
                12.	公序良俗に反する行為、犯罪行為、不法行為その他、他ユーザが本サイトを利用することを妨げる行為。<br></br>
                13.	反社会的勢力またはこれに準ずるもの（以下「反社会的勢力等」といいます）であること、または違法献金、脅迫的行為、信用毀損行為、業務妨害行為、詐欺行為、組織的犯罪行為、法的責任を超えた不当な要求行為に関与したことが判明した場合。<br></br>
                14.	その他、当社が不適切と判断する行為<br></br>


              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第8条 違反行為等への措置
              </Typography>
              <Typography variant="h7" component="div">
                1.	当社は、ユーザーが不正使用及び購入代金の未払いを含む、利用規約等の何らの条項に違反した場合、当社が別途定める違約金の請求、コンテンツの使用差止、サービスの利用停止、強制退会、損害賠償請求（合理的な弁護士費用も含む）等の措置を取ることができます。<br></br>
                2.	ユーザーによる不正使用を含む利用規約等の違反に関連し、生起する第三者との間の法的請求や責任について、当社は、一切責任を負わず、利用規約等に違反したユーザーは、自己の責任においてこれを処理し、当社に一切の迷惑や損害を与えないことを保証します。<br></br>
                3.	前項の法的請求が当社に対して行われ、当社が何らの損害を被った場合、ユーザーは、合理的な弁護士費用も含め、当社が要した費用一切につき、これを補償します。<br></br>
                4.	ユーザーが利用規約等に違反した場合で当社が必要と判断したとき、当社は、該当するユーザーの連絡先その他当社が当該ユーザーに関して有する情報を、当該違反に関連する第三者に開示できるものとします。<br></br>
                5.	コンテンツ又はその使用用途等について利用規約等違反の報告が当社にあった場合、当社は、当該違反の是正について合理的な範囲での最善の措置を講ずるよう努め、当社の裁量で当社が行う対応を決定することができます<br></br>
                6.	ユーザーは、措置が、当社の裁量により事前の通知なく行われる可能性があることをあらかじめ承諾します。<br></br>
                7.	メンバーまたはメンバー未登録者がコンテンツを当社の事前の許諾なく無断で利用した場合及び第禁止行為を行った場合、当該利用者は当社に対し、料金表又は各規約記載の正規の利用料金に加え、別途当社、撮影者および被写体が蒙った一切の損害（訴訟費用および弁護士費用を含みます）の賠償をなすものとします。<br></br>
                8.	以下に掲げる場合当社は違法性の有無に関わらず投稿情報の削除措置を行います。これらの措置をとる義務を負うものではなく、また、これらの措置を講じた理由を開示する義務を負うものではありません。<br></br>
                (ア)	投稿情報について、第三者から権利主張があった場合<br></br>
                (イ)	投稿情報が第三者の著作権を侵害すると当社が判断した場合<br></br>
                (ウ)	その他当社が法律および社会通念に従って当該情報を削除する必要があると判断した場合非公開または削除措置を<br></br>
                (エ)	公的な機関または専門家（国、地方公共団体、特定電気通信役務提供者の損害賠償責任の制限および発信者情報の開示に関する法律のガイドラインに規定された信頼性確認団体、インターネット・ホットライン、弁護士等をいいます。）から、投稿情報について、違法、公序良俗違反または他人の権利を侵害する等の指摘・意見表明があった場合<br></br>


              </Typography>

            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第9条  アカウント削除
              </Typography>
              <Typography variant="h7" component="div">
                1.	ユーザーは、アカウントの削除を希望する場合には、当社所定の方法により当社にアカウント削除の申出を行い、当社の定める方法によりアカウントを削除するものとします。<br></br>
                2.	ユーザーのアカウント（個別サービスにおいて、ユーザーとは別個の資格での登録がある場合は当該登録も含みます。）が削除された場合、当該ユーザーの投稿情報ならびに本サービスおよび本サービス上のデジタルデータに関するすべての利用権は消滅し、当社は、当該ユーザーの登録情報および投稿情報の一切を削除することができるものとします。<br></br>
                3.	当社は、理由の如何を問わず、当該ユーザーの登録情報、および投稿を復旧する義務を負いません<br></br>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第10条  退会時の措置
              </Typography>
              <Typography variant="h7" component="div">
                1.	ユーザは当社の定める方法によりアカウントを削除し本サービスの利用をいつでも終了することができます。<br></br>
                2.	但し、利用者は、未払いの購入代金がある場合、その支払いが完了するまで退会することができません。なお、本サービスのシステム上、会員による退会申込みが完了してから、その手続きがシステム上に反映されるまで、当社所定の時間が必要な場合があり、会員はこれに同意するものとします。<br></br>
                3.	会員は、退会申込み時点で、アカウントにログインできなくなり、すべての機能が使えなくなります。保有する全ての許諾も無効となります<br></br>
                4.	当社は、ユーザが以下のいずれかの事由に該当する場合、又はそのおそれがあると当社が判断した場合、会員へ事前の通告、催告をすることなく、且つ会員の承諾を得ることなく、当社の裁量により、直ちに、当該会員の退会手続きをとることができます。なお、当社は、会員に対し、退会理由を開示する義務を負いません。<br></br>
                (ア)	当社の合理的な判断において、禁止行為に該当または該当の恐れがある場合<br></br>
                (イ)	会員が死亡した場合<br></br>
                (ウ)	1年間以上、アカウントにログインしていない場合<br></br>
                (エ)	その他、当社が会員として不適切と判断した場合<br></br>

              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第11条  著作権侵害の場合の取扱い
              </Typography>
              <Typography variant="h7" component="div">
                1.	本サービスにおいて、ご自身の著作物の著作権が侵害されている場合には、別途当社が定める連絡先まで所定の事項をお送りください。<br></br>
                2.	申出が、裁判所、検察庁、もしくは行政機関の命令に基づく場合には、命令に応じた対処をするものとします。<br></br>

              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第12条  投稿情報等に関する保証および義務
              </Typography>
              <Typography variant="h7" component="div">
                1.	本サービスを利用して投稿情報を投稿等するユーザーは、当社に対し、当該投稿情報が第三者の権利を侵害するものでないことを保証するものとします。<br></br>
                2.	万一、ユーザーが投稿等した情報が第三者の権利を侵害するなど第三者との間で何らかの紛争が発生した場合には、当該ユーザーの費用と責任において問題を解決するとともに、当社に何らかの損害を与えないものとします。<br></br>
                3.	ユーザーは、自己が作成した画像、テキスト等の一切のデータを自己の責任において管理・保存するものとします。本サービスにアップロードするため編集中の画像またはテキスト等、アップロードされた画像またはテキスト等のデータは、ユーザー自身が適宜バックアップを取るなどの手法で保存するものとし、当社は画像、テキスト等のデータの保存等についてサービス運営上合理的な努力は行いますが、保証をするものではありません。<br></br>
                4.	利用権を購入したユーザは、作品の利用権を販売したユーザの定めたメニューの範囲（利用方法及び期間）において作品の利用を許諾されます。許諾された期間の範囲においては作品公開を著作者が中止した場合でも利用の継続を可能とします<br></br>
                5.	当社又は投稿データの権利者に帰属するものであり、著作権法を含む知的財産権に関する法の下、保護されています。適切な許諾を取得することなく、これらを無断で使用することは、法律の下、禁じられています。<br></br>
                6.	当社利用規約同意の上利用登録を行い。かつ規定の方法で作者との利用許諾を確立しない限り全ての投稿データを利用・複製・加工、その他利用はできません。<br></br>
                7.	本サービスでの投稿情報表示に関するシステム的な制約(作品一覧画面サムネイル作成のためのサイズ変更等)によって発生する加工に対して「同一性保持権」の行使は行わないこととします<br></br>


              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第13条 免責事項
              </Typography>
              <Typography variant="h7" component="div">
                1.	当社は、本サービス内のコンテンツにつき、コンテンツの提供者から、著作権、肖像権その他の権利について他の第三者の権利を侵害するものでない旨の保証を得ていることを保証します。<br></br>
                2.	当社は、タグ情報その他コンテンツに付随する情報の正確性、完全性、最新性等を一切保証いたしません。<br></br>
                3.	当社は、コンピューターシステム障害、それに伴う誤表示、コンピューター等付随する環境に基づく損害について、一切責任を負いません。<br></br>
                4.	当社は、画像・映像・音楽データ、テキスト等を含む当社に提出されたコンテンツ及び付随する情報等のデータ一切に関する損失・損害について、一切責任を負いません。ユーザーは、当該データのバックアップを自身で作成し、維持するものとします。<br></br>
                5.	当社は、本サービス、サーバー、コンテンツ及び本サービスから送信された電子メールが、ウィルス又はその他の有害な要素に感染していないことを保証しません。
                6.	いかなる場合であっても、当社は、利用規約等、本サービス、本サービス上のコンテンツ、利用規約等の下許諾された権利、信頼、アクセス、又は使用、不使用、遅延等に生起して第三者との間に生じた何らの紛争及びこれに関連するいかなる損害についても、一切責任を負いません。上記に関わらず、いずれの場合も、当社の責任の総額は、コンテンツ使用許諾契約において補償額として定める限度額又はコンテンツ提供契約において定める限度額を超えないものとします。但し、ユーザーによる利用規約等違反があった場合、又はユーザーによる加工後のコンテンツに係る場合は、当社は、一切の損害賠償責任を負わないものとします。<br></br>
                7.	当社は、本サービス上のメッセージ機能の使用に起因する他のユーザー又は第三者との紛争及びこれに関連するいかなる損害についても、一切責任を負いません。なお、当社は、問題の発生又はその恐れがあることを察知した場合等、当社が必要と判断した場合に限り、本サービス内のメッセージ機能の内容を閲覧し、当該問題の防止又は是正等の措置を行うことができるものとし、ユーザーは、その旨を予め了承します。<br></br>
                8.	本サービスは、外部サービスと連携してサービスを提供する場合がありますが、ユーザーは、自らの責任において外部サービスを利用するものとし、当社は、外部サービスの利用に関連してユーザーに発生した損害について、一切責任を負いません。ユーザーは、外部サービスの利用にあたっては、当該外部サービスの利用約款等を遵守するものとします。<br></br>
                9.	利用規約等のいずれかの条項、又はその一部が、無効又は執行不能と判断された場合であっても、利用規約等の残りの部分は、継続して効力を有します。<br></br>
                10.	ユーザーは、本サービスまたは本サービスにより知り得た情報を利用する場合、利用される国、地域における法令を遵守しなければならず、当社はユーザーの法令違反につき責任を負いません。<br></br>
                11.	当社は、ユーザーに対し簡便で、品質の高い決済環境等を提供しますが、決済上の無事故等を当社が保証するものではありません。また、当社は、本サービスの恒久的なアップデート、改良、不具合の修正等の義務を負うものではありません。<br></br>
                12.	当社は、ユーザーによって投稿等される投稿情報を管理・保存する義務を負いません。<br></br>
                13.	当社が損害賠償責任を負う場合、契約者が当社に本サービスの対価として支払った総額を限度額として賠償責任を負うものとします。<br></br>


              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第14条 サービスの中断・中止
              </Typography>
              <Typography variant="h7" component="div">
                1.	当社は、以下の事由が生じた場合、会員に事前に告知、通知することなく、本サービスの運営を中断、又は中止することがあります。これらの事由による本サービスの中断又は中止によって、ユーザーが損害を被った場合、当社は一切責任を負いません。<br></br>
                2.	本サービス用設備等の保守、メンテナンスを定期的に、又は緊急に行う場合<br></br>
                3.	本サービス用設備の故障等の場合<br></br>
                4.	地震、噴火、洪水、津波等の天災、戦争、動乱、暴動、騒乱、労働争議、火災、停電、その他、不慮の事件、事故等の不可抗力により、本サービスの提供が提供できなくなった場合<br></br>
                5.	その他、運用上、又は技術上本サービスの中断、又は中止が必要と当社が判断した場合<br></br>


              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第15条 事業譲渡等
              </Typography>
              <Typography variant="h7" component="div">
                1.	当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用規約等上の地位、利用規約等に基づく権利及び義務並びにユーザの情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザは、かかる譲渡につき本項において予め同意したものとします。<br></br>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >
              <Typography variant="h6" component="div">
                第16条 通知または連絡
              </Typography>
              <Typography variant="h7" component="div">
                1.	ユーザーへの通知または連絡が必要な場合には、本サービス上のメッセージ機能、書面による郵送、各サービス上での掲示（お知らせ等を含む）、ユーザーが登録した電子メールアドレスまたはSNSなどへの連絡によって行います。ユーザーが、当社に対し連絡が必要であると判断した場合には、SNSまたはお問い合わせフォームによって連絡を行うものとします。当社が、各サービス上での掲示よりユーザーに通知・連絡等を行う場合は、当該通知・連絡等を掲載してから48時間を経過したときに、その他の手段による通知・連絡等の場合は、当社がユーザーに当該通知・連絡等を発信したときに、効力を生じるものとします。<br></br>
                2.	裁判官の発付する令状により強制処分として捜索・押収等がなされる場合、法律上の照会権限を有する公的機関からの照会（刑事訴訟法第197条第2項）がなされた場合その他法令の規定に基づき提供しなければならない場合、当社はユーザーの合意をとらずに照会事項を開示する場合があります。<br></br>

              </Typography>
            </Grid>
          </Grid>

        </Box>
      </Box>


    </HeadFooter>

  );
}
export default LpCreator;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
