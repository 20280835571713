import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../AuthContext';
import IfProxy from '../IfProxy';
import { Link } from 'react-router-dom'

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';


// 
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const createUserFunction = "https://us-central1-app3-819e4.cloudfunctions.net/sampleCallTest";
const checkStripeUserById = "https://us-central1-app3-819e4.cloudfunctions.net/checkStripeUserById";




// 標準の画面大枠レイアウト
const HeadFooter = (props) => {
  // コンポーネントのデザインオブジェクト（いつもレイアウトしてるやつ）はここに全部入っている
  const classes = useStyles();


  const [showDrower, setShowDrower] = useState(false);
  const [read, setRead] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [accountStatus, setAccountStatus] = useState(0);// 0:プラットフォーム未登録 1:Stripe未登録 2:Stripe登録ずみ
  const [nsfwFlg, setNsfwFlg] = useState(false);

  const ifProxy = new IfProxy();



  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const testMsg = "test==>>>>>>>"

  useEffect(() => {
    checkRead();
    checkNsfwFlg();
    setupCreatorRegistrationButton(); // STripe口座開設ボタンの表示制御、Stripeアカウントの取得
    return () => {
      setRead({});
      setNsfwFlg({});
    }
  }, []);

  const checkRead = () => {
    let ifProxy = new IfProxy();
    if (user) {
      ifProxy.checkRead(user.uid).then((result) => {
        setRead(result);
      })
    }

  }
  const checkNsfwFlg = () => {
    let ifProxy = new IfProxy();
    ifProxy.checkNsfwFlg().then((result) => {
      setNsfwFlg(result);
    })
  }

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };
  const handleBlock = () => {
    navigate('/UserBlockList');
  };

  const handleManagement = () => {
    navigate('/Management');
  };
  const handleLpCreator = () => {
    navigate('/LpCreator');
  };

  const goManual = () => {
    navigate('/ManualStripe');
  }

  const goProfile = () => {
    navigate('/User/' + user.uid);
  }



  const toggleOpen = () => {
    setShowDrower(!showDrower);
  }
  const createBtn = () => {
    navigate('/Create');
  }
  const notification = () => {
    navigate('/NotificationList');
  }
  const home = () => {
    navigate('/');
  }

  // クリエイター登録ボタン ======================
  /**
   * クリエイターボタンの表示切り替えのためのデータ取得等の前処理
   * 以下の処理の結果をStateに保持し、ボタン表示を更新する
   * このボタンは以下のケースで表示を切り替える
   * 本アプリ未登録のユーザ何も表示しない
   * 本アプリ登録、クリエイター登録がまだ:Stripeの事業者作成画面
   * クリエイター登録済み： Stripe口座管理画面へのリンク
   * @returns 何も表示しない/ボタンを表示する/決済管理情報を表示する
   */
  const setupCreatorRegistrationButton = async () => {

    if (!user) {
      // サインアップをしていない
      setAccountStatus(0);

    } else {
      let userData = await ifProxy.getUserData(user.uid);
      if (userData.stripeId === "") { //userDataを検索し Stripe idが設定済みかどうか確認
        // クリエイター登録をしていない
        setAccountStatus(1);
      } else {

        // クリエイター登録をしている
        console.log("body引数")
        console.log(userData.stripeId)
        // クリエイター登録が未完了かどうかを確認
        const addMessage = httpsCallable(functions, 'checkStripeUserById');
        addMessage({ stripeId: userData.stripeId })
          .then((result) => {
            // Read result of the Cloud Function.
            /** @type {any} */
            console.log("---ON CALL LOCAL TEST ========")
            const accountData = result.data.result.result;
            console.log("有効化状態")
            console.log(accountData.charges_enabled)
            if (accountData.charges_enabled) {
              setAccountStatus(2);
            } else {
              setAccountStatus(3);
            }

          })
          .catch((error) => {
            console.log("checkStripeUserById コール失敗");
            console.log(error);
          });
      }
    }
  }
  /**
   * ボタンの表示切り替え　
   * 
   * このボタンは以下のケースで表示を切り替える
   * 本アプリ未登録のユーザ何も表示しない
   * 本アプリ登録、クリエイター登録がまだ:Stripeの事業者作成画面
   * クリエイター登録済み： Stripe口座管理画面へのリンク
   * @returns 何も表示しない/ボタンを表示する/決済管理情報を表示する
   */
  const viewCreatorRegistrationButton = () => {
    if (accountStatus === 0) {
      // プラットフォーム未登録
      return null
    } else if (accountStatus === 1) {
      // Stripe登録が未完了
      // Stripeidをまだ持っていない
      return (
        <>
          <Button color="inherit" variant="outlined" onClick={goManual}>まずは、クリエイター登録マニュアル</Button>
          <Button sx={{ "backgroundColor": "#c71585" }} variant="contained" onClick={registerCreator}>クリエイター登録</Button>
        </>
      )
    } else if (accountStatus === 2) {
      // Stripeユーザ登録有効化済み
      return (
        <a href="https://dashboard.stripe.com/dashboard">口座設定</a>
      )
    } else if (accountStatus === 3) {
      // Stripe口座設定途中
      // stripeidがあるが、支払い受付が可能な状態まで設定されていない
      return (
        <a href="https://dashboard.stripe.com/dashboard">支払い口座の設定が未完了です。販売開始のためには口座開設の完了が必要です。</a>
      )
    }

  }
  /** 作品を投稿するボタン
   * クリエイター登録ができていないと投稿できない様にする
   * viewCreatorRegistrationButtonと同様の意図
   */
  const viewCreateButton = () => {
    if (user) {
      if (accountStatus === 2) {
        return (
          <Button color="inherit" variant="outlined" onClick={createBtn}>出品する</Button>
        )
      } else if (accountStatus === 1) {
        return (
          <Button color="inherit" variant="outlined" onClick={createBtn}>無料出品 (販売は要クリエイター登録)</Button>
        )
      }
    } else {
      return (
        <Button color="inherit" variant="outlined" disabled onClick={createBtn}>出品する(要ログイン)</Button>
      )
    }

  }


  // 検索フォーム ======================
  const handleForIconAmount = () => {
    console.log()
  }
  const handleSearchForm = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    console.log(event.target.value);
    setSearchParam(event.target.value);
  }
  const searchAction = () => {

    const encoded = encodeURIComponent(searchParam);
    console.log("SEARCH ENCODED :::" + encoded)

    console.log("searchAction");
    navigate(`/SearchedListView?type=search&word=${encoded}`);
  }

  /**
   * クリエイター登録をする：Stripe連結アカウントを作成する
   */
  const registerCreator = () => {
    //クリエイター登録ボタンを押した数
    //ifProxy.addKpi("addCreatorRegist", "");
    fetch(createUserFunction, {
      method: "get",
      headers: {
        'Content-type': 'application/json'
      },
    })
      .then(res => {
        let redirectAccountUrl = null;

        console.log(res);

        res.json().then(function (text) {

          console.log("========クリエーター口座作成==========")
          console.log("結果")

          console.log(text.data)
          let jsondata = JSON.parse(text.data);
          // StripeIdを userDataに保持
          ifProxy.getUserData(user.uid).then((userData) => {
            console.log("Stripe ID " + jsondata.result.id);
            userData.stripeId = jsondata.result.id
            ifProxy.updateUserData(userData);
          })

          console.log(jsondata)
          console.log(jsondata.result)
          console.log(jsondata.result.url)

          window.open(jsondata.result.url, '_blank');
          // クリエイター初期登録処理を実施


        });

        return null;
      })
      .then(result => {
        console.log(result);
      })
      .catch(error => {
        console.error(error);
      });

  }







  // ログイン中かどうかを確認しヘッダを黄rかえ
  if (!user) {
    {/* ログインしていない時は、LpCreatorのときに利用される */ }
    return (
      <Box>
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <Typography variant="h6" onClick={home} className={classes.applicationTitle}>
              <div className="title">アイコンマーケット</div>
            </Typography>


            <TextField
              id="standard-basic"
              label="検索"
              margin="dense"
              type="search"
              size="small"

              defaultValue=""
              onChange={handleSearchForm}
              sx={{
                borderRadius: 20
              }}
            />
            <IconButton edge="start" onClick={searchAction} color="inherit" >
              <SearchIcon />
            </IconButton>

            {user ? <Button color="inherit" variant="outlined" onClick={createBtn}>出品する</Button> : <Button color="inherit" variant="outlined" disabled onClick={createBtn}>出品する(要ログイン)</Button>}
            <Button color="inherit" variant="outlined">Login</Button>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                setNsfwFlg((pre) => {
                  ifProxy.setNsfwFlg(!pre);
                  return !pre
                });
              }}
              sx={{
                marginRight: 2,
                marginLeft: 2,
                backgroundColor: nsfwFlg ? '' : 'gray'
              }}
            >
              NSFW: {nsfwFlg ? "表示中" : "非表示"}

            </Button>
          </Toolbar>
        </AppBar>



        {/*============== 内容 ================- */}
        {props.children}
        {/*============== 内容 ================- */}

        <Box sx={{
          marginTop: 10,
          padding: 2,
          display: 'flex',
          borderTop: 1,
          borderColor: "#CCCCCC",
          height: 100
        }}>
          <Link to="/Kiyaku" className={classes.link}>利用規約</Link>
          <Link to="/Tokushoho" className={classes.link}>特定商取引法に基づく表示</Link>
          <Link to="/Privacypolicy" className={classes.link}>プライバシーポリシー</Link>


        </Box>
      </Box>
    )
  } else {


    return (
      <Box>
        <Drawer
          anchor='right'
          open={showDrower}
          //docked={false}
          width={200}
          onClose={() => setShowDrower(!showDrower)}
        //onRequestChange={this.handleToggle}
        >

          <MenuItem>メニュー</MenuItem>
          <MenuItem onClick={goProfile}>プロフィール</MenuItem>
          <MenuItem onClick={handleBlock}>ブロック解除</MenuItem>
          <MenuItem onClick={handleManagement}>ユーザ管理</MenuItem>
          <MenuItem onClick={handleLpCreator}>ご案内</MenuItem>
          <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
          <Box sx={{
            marginTop: 10,
            display: 'flex',
            borderTop: 1,
            borderColor: "#CCCCCC"
          }}>
            <MenuItem onClick={toggleOpen}>閉じる</MenuItem>
          </Box>

        </Drawer>
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            {/** チュートリアル intro.js */}

            <Grid container justify="space-between" spacing={3} sx={{ padding: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>

              <Grid item xs={12} sm={12} md={2} lg={6}>
                <Typography variant="h6" onClick={home} className={classes.applicationTitle}>
                  イラストレンタル（仮）
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1}>
                {viewCreatorRegistrationButton()}
              </Grid>
              <Grid item justify="flex-start" xs={12} sm={12} md={2} lg={1}>
                {/*検索*/}
                <Box sx={{ display: 'flex' }}>
                  <TextField
                    id="standard-basic"
                    label="検索"
                    margin="dense"
                    type="search"
                    size="small"

                    defaultValue=""
                    onChange={handleSearchForm}
                    sx={{
                      borderRadius: 20
                    }}
                  />
                  <IconButton edge="start" onClick={searchAction} color="inherit" >
                    <SearchIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1}>
                {user && <Button color="inherit" variant="outlined" onClick={notification} >
                  {read &&
                    <Typography
                      sx={{
                        color: 'red'
                      }} >
                      ●
                    </Typography>
                  }
                  お知らせ</Button>}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1}>
                {viewCreateButton()}
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={1}>
                {!user ?
                  <Button color="inherit" variant="outlined">Login</Button>
                  :
                  <Typography variant="body2" onClick={home} className={classes.headContent}>
                    ログイン中 : {user.displayName}
                  </Typography>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={1} lg={1} >
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={() => {
                    setNsfwFlg((pre) => {
                      ifProxy.setNsfwFlg(!pre);
                      return !pre
                    });
                  }}
                  sx={{
                    backgroundColor: nsfwFlg ? '' : 'gray'
                  }}
                >
                  NSFW: {nsfwFlg ? "表示中" : "非表示"}

                </Button>
              </Grid>
            </Grid>








            {user &&
              <IconButton edge="start" onClick={toggleOpen} className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
            }
          </Toolbar>
        </AppBar>

        {/*============== 内容 ================- */}
        {props.children}
        {/*============== 内容 ================- */}

        <Box sx={{
          marginTop: 10,
          padding: 2,
          display: 'flex',
          borderTop: 1,
          borderColor: "#CCCCCC",
          height: 100
        }}>

          <Link to="/Kiyaku" className={classes.link}>利用規約</Link>
          <Link to="/Tokushoho" className={classes.link}>特定商取引法に基づく表示</Link>
          <Link to="/Privacypolicy" className={classes.link}>プライバシーポリシー</Link>
          <Link to="/LpCreator" className={classes.link}>イラストレンタルとは</Link>
          {(user.uid === "7OZ7ado6mLgBdvQGTUKJZJmWLd13") && <Link to="/Controll" className={classes.link}>Controll</Link>}



        </Box>
      </Box >

    );
  }

}
export default HeadFooter;

// シンプルにカラーを設計する手順
// コンポーネント自体の色は、MainとSubの色を使い、強調が必要な箇所はAccentを使う
//　上に載せられる（被らない）文字の色を labelとして定義しておく
// カラー選択は、muiのカラーパターンから３色のパターンを選ぶ

// muiのカラーパターン
// https://mui.com/customization/color/
// primary
//  Main 9575cd
//  sub aa90d7
//  accent 68518f
//  label fff
//
// secondary
//  Main ef5350
//  sub f27573
//  accent a73a38
//  label fff

// グラデーションの出し方
//https://developer.mozilla.org/ja/docs/Web/CSS/CSS_Images/Using_CSS_gradients
// 各コンポーネントのデザインはここ
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  root: {
    flexGrow: 1,
  },
  // 各部分とその中身
  header: {
    background: 'linear-gradient(to bottom right,#9575cd,#f27573)',
    color: "#fff"
  },
  menuButton: {
    marginRight: 10,
  },
  loginBtn: {
    color: "#00bcd4"
  },
  applicationTitle: {
    flexGrow: 1,
  },
  headContent: {
    margin: 5,
  },
  // Footer
  link: {
    margin: 5,
  },
  new: {
    fontColor: 'red'
  },

  // コンテンツ部分
  // メイン
  button: {
    width: 200,
    borderRadius: 30,
    color: "#fff",
    background: "#f27573",
  }
  // サイドバー
}));
