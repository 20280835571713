import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import ProfileComp from '../comp/ProfileComp';
import RequestComp from '../comp/RequestComp';

// スピナーアニメーション
import { Puff } from 'react-loader-spinner'


// intoro.js https://www.npmjs.com/package/intro.js-react
// 例　https://codesandbox.io/embed/o2A4gwXE3?hidenavigation=1
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import { TagCloud } from 'react-tagcloud'

const data = [
  { value: 'JavaScript', count: 38 },
  { value: 'React', count: 30 },
  { value: 'Nodejs', count: 28 },
  { value: 'Express.js', count: 25 },
  { value: 'HTML5', count: 33 },
  { value: 'MongoDB', count: 18 },
  { value: 'CSS3', count: 20 },
]

const cardHeight = 300;
const cardWidth = 300;
const imageHeight = 150;
const cardMargin = 5;


const MainList = (props) => {
  console.log("props")
  console.log(props.nsfwFlg)

  const [listItems, setListItems] = useState(0);
  const [trendListItems, setTrendListItems] = useState(0);
  const [followListItems, setFollowListItems] = useState(0);
  const [followUsers, setFollowUsers] = useState(0);
  const [tagDataList, setTagDataList] = useState(0);
  const [nsfwFlg, setNsfwFlg] = useState();

  const [loading, setLoading] = useState(false);




  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const ifProxy = new IfProxy();
  useEffect((props) => {
    setLoading(true)
    if (!user) {
      // ログインしていなければログイン画面へ
      navigate('/login');
    } else {
      loadData();
      loadFollowData();
      loadTrendData();

      console.log("props")
      console.log(props)
      return () => {
        setFollowListItems({});
        setFollowUsers({});
        setTrendListItems({});
      }

    }

  }, [listItems.no]);

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
  };

  /**
   * 表示前の投稿イラストリストから、ブロック関係にあるユーザの投稿を除去する
   * @param {*} targetList データ型:投稿（Illustlation)の配列
   * @param {*} loginUid 
   * return フィルタ済みの 投稿リスト
   */
  const filterBlockData = (targetList, loginUid) => {
    return new Promise(async (resolve, error) => {
      let filterdIllustlationList = [];
      let blockList = await ifProxy.checkBlock(loginUid)

      // ブロックリストをフィルタする
      if (blockList != null) {
        filterdIllustlationList = targetList.filter((output, index) => {
          let result = true;

          for (let blackListData of blockList) {
            // ブラックリストに含まれている場合falseにする
            if (output.uid.includes(blackListData.blockUid)) {
              // 自分がブロックしたユーザのuidが作成者の場合false
              // ※ 他人が自分をブロックし、自分の絵が含まれるため除外(from->to両方をorで合わせてリストを持ってきているため)
              if (output.uid === loginUid) {
                result = true;
              } else {
                result = false
              }

            } else if (output.uid.includes(blackListData.tuhoUid)) {
              // 自分をブロックしているユーザのuidが作成者の場合はfalse
              // ※ 他人をブロックした自分のuidがtohoUidに含まれているためそれは除外
              if (output.uid === loginUid) {
                result = true;
              } else {
                result = false
              }
            }
          }
          return result;
        })
      } else {
        // ブラックリストを登録していない場合
        filterdIllustlationList = targetList;
      }

      resolve(filterdIllustlationList);
    });
  }

  const loadData = () => {

    ifProxy.getIllustlationList().then(async (result) => {
      console.log(result);


      let localstorageFlgNsfw = null;
      // まずローカルストレージからNSFWフラグを取得しフィルタをセット
      await ifProxy.checkNsfwFlg().then((result) => {
        // 更新が非同期となり安定しないため、直接変数にフラグを持つ
        localstorageFlgNsfw = result;
        setNsfwFlg(result);
      })

      // 表示データからブロックリストデータを排除     
      // ブロックは、双方向とも禁止するため、ブロックした、された両方排除する
      // ブロックリストをフィルタする
      let reportList = await ifProxy.filterBlockData(result, user.uid)


      // nsfwFlgがFalseのときは nsfw=trueのデータをフィルタする
      let nsfwFilterd = [];

      if (!localstorageFlgNsfw) {
        nsfwFilterd = reportList.filter((output, index) => {
          return !output.nsfw; // falseのものは除外される
        })
      } else {
        nsfwFilterd = reportList
      }

      // カードを作成
      let listItems = nsfwFilterd.map((item) =>
        createCard(item)
      );
      console.log(listItems);
      setListItems(listItems);
      setLoading(false)

    })
  }

  const loadFollowData = () => {
    ifProxy.getFollowIllustrationList(user.uid).then(async (result) => {
      console.log(result);
      if (!result) {
        return;
      }
      let localstorageFlgNsfw = null;
      // まずローカルストレージからNSFWフラグを取得しフィルタをセット
      await ifProxy.checkNsfwFlg().then((result) => {
        // 更新が非同期となり安定しないため、直接変数にフラグを持つ
        localstorageFlgNsfw = result;
        setNsfwFlg(result);
      })

      // 表示データからブロックリストデータを排除     
      let reportList = await ifProxy.filterBlockData(result, user.uid);

      console.log("フィルタ結果")
      console.log(reportList);
      // nsfwFlgがFalseのときは nsfw=trueのデータをフィルタする
      let nsfwFilterd = [];
      if (!localstorageFlgNsfw) {
        nsfwFilterd = reportList.filter((output, index) => {
          return !output.nsfw; // falseのものは除外される
        })
      } else {
        nsfwFilterd = reportList
      }
      console.log("フィルタ結果2")
      console.log(nsfwFilterd);

      // カードを作成
      let listItems = nsfwFilterd.map((item) =>
        createCard(item)
      );
      console.log(listItems);
      setFollowListItems(listItems);
    })
  }
  const loadTrendData = () => {

    ifProxy.getIllustrationByTrendShort().then(async (result) => {
      console.log(result);
      // タグクラウド用データ
      tagCloudData(result);

      let localstorageFlgNsfw = null;
      // まずローカルストレージからNSFWフラグを取得しフィルタをセット
      await ifProxy.checkNsfwFlg().then((result) => {
        // 更新が非同期となり安定しないため、直接変数にフラグを持つ
        localstorageFlgNsfw = result;
        setNsfwFlg(result);
      })

      // 表示データからブロックリストデータを排除     
      let reportList = await ifProxy.filterBlockData(result, user.uid);

      // nsfwFlgがFalseのときは nsfw=trueのデータをフィルタする
      let nsfwFilterd = [];
      if (!localstorageFlgNsfw) {
        nsfwFilterd = reportList.filter((output, index) => {
          return !output.nsfw; // falseのものは除外される
        })
      } else {
        nsfwFilterd = reportList
      }

      // カードを作成
      let listItems = nsfwFilterd.map((item) =>
        createCard(item)
      );
      console.log(listItems);
      setTrendListItems(listItems);
    })
  }

  const tagCloudData = (trendData) => {

    // temTagMap 一時的にキーバリューを保持するリスト
    const temTagMap = new Map();

    // resultTagMapList { value: 'CSS3', count: 20 }の形のリスト
    let resultTagMapList = [];
    // illustレコードを取得
    for (let illustRecord of trendData) {
      const recordTagList = illustRecord.tagStrings
      for (let tag of recordTagList) {
        // タグリストがすでにあるか確認
        if (temTagMap.has(tag)) {
          // ある場合は、valueを加算
          let count = temTagMap.get(tag);
          let newCount = parseInt(count, 10);
          newCount++;
          temTagMap.set(tag, newCount);
        } else {
          // ない場合は、value1でキーを追加
          temTagMap.set(tag, 1);
        }
      }
    }
    // mapをresultTagMapListの形に整形
    for (let [key, count] of temTagMap) {
      //console.log(key + ' : ' + count);
      let tagMap = { value: key, count: count }
      resultTagMapList.push(tagMap);
    }
    // ソートする
    //console.log("ソートする")
    resultTagMapList.sort((pre, post) => {
      if (pre.count < post.count) return 1;
      if (pre.count > post.count) return -1;
      return 0;
    });
    //console.log("ソート結果")
    //console.log(resultTagMapList)
    // 上位 slice
    let resultMapList = resultTagMapList.slice(0, 10)
    //console.log("slice結果１０件")
    //console.log(resultMapList)
    setTagDataList(resultMapList);
  }

  const onClickCard = (id) => {
    console.log("カードがクリックされた")
    console.log(id);
    navigate('/Detail/' + id);
  }

  const createCard = (item) => {
    return (

      <Grid item key={item.id}
      >
        <Card className={classes.card} onClick={() => onClickCard(item.id)}>
          <CardContent>
            <CardMedia
              component="img"
              height={imageHeight}
              image={item.thumbnail}
              alt="イラスト"
            />
            {item.sales &&
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                販売中
              </Typography>
            }
            {!item.sales &&
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                受付停止中
              </Typography>
            }
            {item.freeFlg &&
              <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
                無料
              </Typography>
            }
            {item.mediaEnableFlg &&
              <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
                媒体単位利用
              </Typography>
            }
            {item.monthlyFlg &&
              <Typography sx={{ fontSize: 14 }} color="red" gutterBottom>
                レンタル利用
              </Typography>
            }

            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              pv数 : {item.pv}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              お気に入り数: {item.bookmark}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small"
              to={`/Detail/${item.id}`}
            >詳細
            </Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  const onMoreLoadTag = (tag) => {
    console.log("onMoonMoreLoadTagreLoad")
    const encoded = encodeURIComponent(tag);
    navigate(`/SearchedListView?type=tag&word=${encoded}`);
  }
  const onMoreLoadTrend = () => {
    console.log("onMoreLoadTrend")
    navigate('/SearchedListView?type=trend');
  }
  const onMoreLoadFollow = () => {
    console.log("onMoreLoadFollow")
    navigate('/SearchedListView?type=follow');
  }

  // ヘッダから検索するため使わない
  const onMoreLoadSearch = (word) => {
    console.log("onMoreLoadSearch");
    navigate(`/SearchedListView?type=search&word=${word}`);
  }
  // 検索画面から行うためここでは使わない
  const onMoreLoadUser = (displayName) => {
    console.log("onMoreLoadUser");
    navigate(`/SearchedListView?type=usersearch&word=${displayName}`);
  }
  const onMoreLoadAll = () => {
    console.log("onMoreLoadAll");
    navigate(`/SearchedListView`);
  }

  // intoro.js
  /* intro.js 一旦利用は休止。コンポーネントのレベルが同じでないと使えない */

  const steps = [
    {
      element: '.header',
      intro: 'test 1',
      position: 'right',
      tooltipClass: 'myTooltipClass',
      highlightClass: 'myHighlightClass',
    },
    {
      element: '.selector2',
      intro: 'test 2',
    },
    {
      element: '.selector3',
      intro: 'test 3',
    },
  ];

  const intoroEnded = () => {

  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (<>
      ...読込中
      <Puff
        height="80"
        width="80"
        radius={1}
        color="#4fa94d"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />


    </>)
  } else {


    return (
      <HeadFooter className="header">

        <Grid container className={classes.contentWrapper} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid item>
            {/* intro.js 一旦利用は休止。コンポーネントのレベルが同じでないと使えない */}
            <Steps
              enabled={false}
              steps={steps}
              initialStep={0}
              onExit={() => intoroEnded()}
            />
            <h1>注目</h1>
            <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>

              {trendListItems}

              <Grid item
                xs={12} sm={12} md={12}
                sx={{
                  padding: 2,
                  alignItems: 'center'
                }}>
                <Button fullWidth variant="outlined" onClick={onMoreLoadTrend}>もっと表示する</Button>
              </Grid>

            </Grid>
            <h1>＃注目のタグ</h1>
            <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>
              {tagDataList && <TagCloud
                minSize={12}
                maxSize={40}
                tags={tagDataList}
                onClick={tag => onMoreLoadTag(tag.value)}
              />}

              <Grid item
                xs={12} sm={12} md={12}
                sx={{
                  padding: 2,
                  alignItems: 'center'
                }}>

              </Grid>

            </Grid>
            <Grid container alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item
                xs={12} sm={12} md={12}
              >
                <RequestComp uid="all" />

              </Grid>
            </Grid>
            <h1>フォロー中</h1>
            <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>


              {followListItems}

              <Grid item
                xs={12} sm={12} md={12}
                sx={{
                  padding: 2,
                  alignItems: 'center'
                }}>
                <Button fullWidth variant="outlined" onClick={onMoreLoadFollow}>もっと表示する</Button>
              </Grid>
            </Grid>

            <h1>新着</h1>
            <Grid container className={classes.cardList} alignItems="center" justify="center" columns={{ xs: 12, sm: 12, md: 12 }}>


              {listItems}

              <Grid item
                xs={12} sm={12} md={12}
                sx={{
                  padding: 2,
                  alignItems: 'center'
                }}>
                <Button fullWidth variant="outlined" onClick={onMoreLoadAll}>もっと表示する</Button>
              </Grid>
            </Grid>
            <Grid item>

              <ProfileComp xs={12} uid={user.uid} />
            </Grid>


          </Grid>

        </Grid>



      </HeadFooter>

    );
  }




}
export default MainList;
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    margin: 20,
  },
  cardList: {
    margin: 10//'0 auto',
  },
  card: {
    width: cardWidth,
    height: cardHeight,
    margin: cardMargin,
  },
  mainBox: {
    margin: 10
  },
  rightBox: {
    width: 300,
  },
  loadButton: {
    padding: 1,
  },



}));