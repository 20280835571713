import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import CardMedia from '@mui/material/CardMedia';

import { useDropzone } from 'react-dropzone';


/**
 * 画像アップロード
 * @property {returnBlob(blob), avatorMode} 
 * @returns { blobData, localUrl }
 * 
 * returnBlob 親コンポーネントに関数を定義。引数にblobデータをもらったら親コンポーネントのstateに引き渡す処理を親側で書き、このコンポーネントの属性に渡す
 * avatorMode デフォルトはfalse　trueは投稿後画像を表示しない
 * <要件>
 * ドラッグアンドドロップでファイルをアップロードできるui
 * アップロードするblobデータを親コンポーネントに返す
 * 
 * 
 * <実装メモ＞　呼び出し側に必要な処理
 * 
 * コンポーネントの組み込み
 * <UploadImage returnBlob={(blob) => returnBlob(blob) }></UploadImage>
 * → avatorModeはオプション
 * 渡す関数
 *   
 *  子コンポーネントが受け取った画像のblobデータを、親画面に持ってくる
 * param { blobData, localUrl } 
 * const returnBlob = ({ blobData, localUrl }) => {
 *   // 親画面の画像データを実際に取り扱うstateに設定
 *   setBlobDataResult(blobData)
 *   setIcon(localUrl) アップロードしたローカルのurl これをAvatorのsrcやimageのurlに指定すると画像を表示可能
 * }
 * 
 */
const UploadImage = ({ returnBlob, avatorMode = false }) => {

    const maxSize = 10 * 1024 * 1024;

    const [imageData, setImageData] = useState(0);
    const [fileUrl, setFileUrl] = useState();

    // ドラッグアンドドロップからファイルアップロード
    // https://qiita.com/reika_i/items/c6ec71f16c13ca295b28

    const onDrop = useCallback((acceptedFiles) => {

        if (acceptedFiles.length > 0) {
            setImageData(acceptedFiles[0]);

            const src = URL.createObjectURL(acceptedFiles[0]);
            setFileUrl(src);

            // 親コンポーネントにblobデータを返す
            returnBlob({ blobData: acceptedFiles[0], localUrl: src });
        }

    }, []);
    // ドラッグアンドドロップでアップロードファイルパスを登録
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg, image/gif, image/jpg',
        minSize: 1,
        maxSize: maxSize
    });

    // ファイルアップロードホバー中の表示を切り替え
    const uploadAreaStyle = useMemo(() => (
        { fileUploadStyle, ...(isDragActive ? borderDragStyle : fileUploadStyle) }
    ), [isDragActive]);


    return (
        <>
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {(!avatorMode && imageData) ? <div><CardMedia
                    component="img"
                    image={fileUrl}
                    alt="投稿画像"
                    sx={{
                        width: 500,
                        padding: 2,
                        boxShadow: 1,
                        alignItems: 'center'

                    }}
                /></div> : <div {...getRootProps({
                    className: fileUploadStyle
                })} style={uploadAreaStyle}>
                    <input {...getInputProps()} />
                    <p>ここへファイルをドラッグしてアップロード (10MBまで)</p>
                </div>
                }

                {isDragReject ? <div className="alert alert-danger" role="alert">ファイルタイプが一致しません</div> : null}
                {fileRejections.length > 0 ? <div className="alert alert-danger" role="alert">
                    {fileRejections[0].errors[0].message}
                </div> : null}
            </Box>

        </>
    )
}

// アップロードのためのドラッグエリア
const fileUploadStyle = {
    margin: 20,
    height: 150,
    border: "10px dotted #888",
    color: 'blue'
};
// ドラッグエリアにホバーした時のスタイル
const borderDragStyle = {
    margin: 20,
    height: 150,
    backgroundColor: '#E5E5FF',
    border: "1px solid #E5E5FF",
    transition: 'border .5s ease-in-out'
};

export default UploadImage;


