import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../AuthContext';
import HeadFooter from '../HeadFooter';
import Typography from '@mui/material/Typography';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';



const LpCreator = (props) => {
  const { user } = useAuthContext();
  const classes = useStyles();

  useEffect((props) => {
    return () => {
    }

  }, []);

  // Boxレイアウトのレスポンシブ対応
  // https://mui.com/components/grid/#responsive-values
  return (
    <HeadFooter>
      <Box className={classes.contentWrapper}>
        <Box className={classes.mainBox}>
          <Grid container justify="center" spacing={3} sx={{ marginTop: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Typography variant="h3" component="div" sx={{ margin: 2 }} id="tokushoho">
              特定商取引法に基づく表示
            </Typography>

            <Grid item xs={12} sm={12} md={12} sx={{ margin: 5 }} >


              <Typography variant="h7" component="div">
                販売業者：イラストレンタル
              </Typography>
              <Typography variant="h7" component="div">
                代表者：加藤 悠輝
              </Typography>
              <Typography variant="h7" component="div">
                販売業者の連絡先:illustrental@gmail.com
              </Typography>
              <Typography variant="h7" component="div">
                所在地：〒187-0031　東京都小平市小川東町 １−３０ー３
              </Typography>
              <Typography variant="h7" component="div">
                電話番号：080-5467-9223
              </Typography>
              <Typography variant="h7" component="div">
                販売価格：通常小売価格を商品ごとに個別記載
              </Typography>
              <Typography variant="h7" component="div">
                送料:不要
              </Typography>
              <Typography variant="h7" component="div">
                申込み有効期限：掲載期間中
              </Typography>
              <Typography variant="h7" component="div">
                解約条件:契約期間内であれば、いつでも解約することができます。
              </Typography>
              <Typography variant="h7" component="div">
                販売数量：無制限
              </Typography>
              <Typography variant="h7" component="div">
                引き渡し時期：クレジットカードまたは当社が指定・承認する決済代行会社による購入手続き完了後
              </Typography>
              <Typography variant="h7" component="div">
                お支払い方法：オンライン決済によるクレジットカードでの一括払いその他Stripe社が指定したもの
              </Typography>
              <Typography variant="h7" component="div">
                返品期限：返品はお断りしています。
              </Typography>
              <Typography variant="h7" component="div">
                返品送料：返品はお断りしています。
              </Typography>
            </Grid>

          </Grid>




        </Box>
      </Box>


    </HeadFooter>

  );
}
export default LpCreator;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
