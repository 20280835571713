import { Box } from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate } from 'react-router-dom';

import { TagCloud } from 'react-tagcloud'
import IfProxy from './../../IfProxy';

// タグインプットに関する設定
import { WithContext as ReactTags } from 'react-tag-input';
const KeyCodes = {
    comma: 188,
    enter: [10, 13],
};
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

/**
 * 画像アップロード
 * @property { type, resultTags, suggestion, tagViews, target} 
 * @returns {[{tags}]}
 * 
 * type 
 * suggestion　推奨タグをオブジェクト配列で渡す [{ id: 'オリジナル', text: 'オリジナル' }]
 * target tag/janru/zokusei/situation 検索するときに、この区分で検索対象の項目を切り替える　デフォルトはタグ
 * <要件>
 * 
 * 
 * 
 * <実装メモ＞　呼び出し側に必要な処理
 * 
 * コンポーネントの組み込み
 * 【タグ登録フォーム】
 * <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={[{ id: 'オリジナル', text: 'オリジナル' }]}></TagInput>
 * イラストタグ用のフォーム
 * 
 *  <TagInput type="formKikaku" resultTags={(tags) => returnTags(tags)} suggestion={[{ id: 'オリジナル', text: 'オリジナル' }]}></TagInput>
 * 企画名のタグ
 * 
 * 【タグクラウド表示フォーム】
 *  * <TagInput type="view" tagViews={[{ id: "id", tagsString: ["タグ1", "タグ2"] }]} target="janru"></TagInput>
 * tagViews は次の形式のデータを渡す
 * タグを持つレコードのリスト[
 * 　タグを持つレコード
 *   {
 *   tagsString: [タグ名の配列]
 *  }
 * ]
 *  const trendData = [
      {
        id: 1,
        tagsString: ['オリジナル', 'オリジナル2', 'オリジナル3', 'オリジナル4',]
      },
 * 

 * 
 * 渡す関数 form/view 登録フォーム/クラウドタグ
 *   
 *  子コンポーネントが受け取った画像のblobデータを、親画面に持ってくる
 * param {[]} tags 
 * const returnTags = (tag) => {
 *   // 親画面のstateに変更されたタグを反映
 *   setTags([])
 * }
 * 
 */
const TagInput = ({ type, resultTags, suggestion, tagViews, target = "tag" }) => {
    // タグ設定系
    const [tags, setTags] = useState([]);
    const [suggestions, setSuggestions] = useState(suggestion);

    const navigate = useNavigate();

    const [tagDataList, setTagDataList] = useState(0);
    const ifProxy = new IfProxy();

    // タグ設定まわり
    const handleDelete = (i) => {
        setTags((pre) => {
            let filterdTag = tags.filter((tag, index) => index !== i)
            resultTags(filterdTag);
            return filterdTag;
        });
    }

    useEffect((props) => {

        // タグクラウド用データ
        if (type === "view") tagCloudData(tagViews);

    }, []);


    const handleAddition = (tag) => {
        setTags((pre) => {
            resultTags([...pre, tag])
            return [...pre, tag]
        })

    }

    // 動いていないかも
    const handleDrag = (tag, currPos, newPos) => {
        const tagsData = tags;
        const newTags = tagsData.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
        //resultTags(newTags)
    }

    const onMoreLoadTag = (tag) => {
        console.log("onMoonMoreLoadTagreLoad")
        const encoded = encodeURIComponent(tag);
        // タグ押下時のリンクを指定
        navigate(`/SearchedListView?type=${target}&word=${encoded}`);
    }



    const tagCLoudView = () => {

        return (
            <>
                {tagDataList && <TagCloud
                    minSize={12}
                    maxSize={40}
                    tags={tagDataList}
                    onClick={tag => onMoreLoadTag(tag.value)}
                />}
            </>
        );
    }
    const tagInputForm = () => {
        return (
            <Box
                sx={{
                    fontSize: 20,
                    color: 'gray',
                    marginTop: 5,
                    marginBottom: 5,
                    padding: 2,
                    border: tags.length == 0 ? "5px dashed #888" : null,
                    borderColor: 'red'
                }}
            >
                {/**タグインプットフォームを要件に合わせて加工 */}
                {tags.length == 0 && <Typography sx={{ fontSize: 20, marginTop: 3 }} color="red" gutterBottom>
                    入力後エンターで確定してください
                </Typography>}
                <ReactTags tags={tags}
                    suggestions={suggestions}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    delimiters={delimiters}
                />

            </Box>
        )
    }
    const tagInputFormKikaku = () => {
        return (
            <Box
                sx={{
                    fontSize: 20,
                    color: 'gray',
                    marginTop: 5,
                    marginBottom: 5,
                }}
            >
                {/**タグインプットフォームを要件に合わせて加工 */}
                <Typography sx={{ fontSize: 20, marginTop: 3 }} color="text.secondary" gutterBottom>
                    もし該当するワンドロ企画名があれば登録してください。
                </Typography>


                <ReactTags tags={tags}
                    suggestions={suggestions}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    handleDrag={handleDrag}
                    delimiters={delimiters}
                />


            </Box>
        )
    }

    // タグクラウド
    /**
     * 
     * @param {*} trendData タグを保持しているレコードのリストをわたす
     * タグを保持するレコードは　tagsString　を持つ。tagsStringは検索のためにタグが配列で保たれている
     * 
     * 【データ形式】
     * const trendData = [
      {
        id: 1,
        tagsString: ['オリジナル', 'オリジナル2', 'オリジナル3', 'オリジナル4',]
      },
      {
        id: 2,
        tagsString: ['オリジナル', 'オリジナル2']
      }
      ,
      {
        id: 3,
        tagsString: ['オリジナル']
      }
    ]
     */
    const tagCloudData = (trendData) => {
        if (!trendData) return (<>loading</>)
        //ifProxy.sign(trendData)
        // temTagMap 一時的にキーバリューを保持するリスト
        const temTagMap = new Map();

        // resultTagMapList { value: 'CSS3', count: 20 }の形のリスト
        let resultTagMapList = [];
        // タグを保持するレコードを一つずつチェック。保持しているタグ配列を一つずつ取り出し集計
        for (let record of trendData) {

            const recordTagList = record.tagsString
            if (recordTagList === undefined) continue
            //ifProxy.sign(record)

            for (let tag of recordTagList) {

                // タグリストがすでにあるか確認
                if (temTagMap.has(tag)) {
                    // ある場合は、valueを加算
                    let count = temTagMap.get(tag);
                    let newCount = parseInt(count, 10);
                    newCount++;
                    temTagMap.set(tag, newCount);
                } else {
                    // ない場合は、value1でキーを追加
                    temTagMap.set(tag, 1);
                }
            }
        }
        // mapをresultTagMapListの形に整形
        for (let [key, count] of temTagMap) {
            console.log(key + ' : ' + count);
            let tagMap = { value: key, count: count }
            resultTagMapList.push(tagMap);
        }
        // ソートする
        //console.log("ソートする")
        resultTagMapList.sort((pre, post) => {
            if (pre.count < post.count) return 1;
            if (pre.count > post.count) return -1;
            return 0;
        });
        //console.log("ソート結果")
        //console.log(resultTagMapList)
        // 上位 slice
        let resultMapList = resultTagMapList.slice(0, 10)
        //console.log("slice結果１０件")
        //console.log(resultMapList)
        setTagDataList(resultMapList);
    }

    return (
        <>
            {type == "form" && tagInputForm()}
            {type == "view" && tagCLoudView()}
            {type == "formKikaku" && tagInputFormKikaku()}
        </>
    )
}

export default TagInput;


