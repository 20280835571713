import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';
import TagInput from "../comp/TagInput";


// Material UI
import { makeStyles } from '@mui/styles';

import { InputAdornment } from '@mui/material';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

const CreateRequest = (props) => {
  const [toUid, setToUid] = useState("");
  const [toName, setToName] = useState("");
  const [thema, setThema] = useState("");
  const [amount, setAmount] = useState("");
  const [tochu, setTochu] = useState(true);
  const [toIcon, setToIcon] = useState(true);
  const [toStripeId, setToStripeId] = useState(true);


  const [loading, setLoading] = useState(true);


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)
    loadData(props);
  }, []);


  const loadData = (props) => {
    console.log(params.id)
    console.log(params.uid)

    try {
      // イラスト作者（リクエスト宛先のuserData取得
      ifProxy.getUserData(params.uid).then(({ uid, displayName, photoURL, stripeId }) => {

        console.log("getUserData ====")
        console.log("getUserData ====")
        console.log("getUserData ====")
        console.log("getUserData ====")
        console.log("uid : " + uid)
        console.log("displayName : " + displayName)
        console.log("photoURL : " + photoURL)
        console.log("stripeId : " + stripeId)

        setToUid(uid);
        setToName(displayName);
        setToIcon(photoURL)
        setToStripeId(stripeId)
        setLoading(false)

      })
    } catch (e) {
      console.log("CreateRequest：loadData失敗")
      console.log(e)
    }
  }

  // フォームの入力値をStateに設定
  const handleThema = (event) => {
    setThema(event.target.value)
  }
  const handleAmount = (event) => {
    setAmount(event.target.value)
  }

  const insert = async () => {


    const data = {
      thema: thema,
      tochu: tochu,
      amount: amount,
      targetId: params.id,
      fromUid: user.uid,
      fromName: user.displayName,
      fromIcon: user.photoURL,
      toUid: toUid,
      toName: toName,
      toIcon: toIcon,
      toStripeId: toStripeId
    }
    await ifProxy.createRequest(data).then((result) => {
      navigate('/Request/' + result.id);

      // 通知
      const noti = {
        uid: params.uid, //通知の宛先
        fromUid: user.uid, // 自分
        fromDisplayName: user.displayName,
        fromPhotoURL: user.photoURL,
        fromIllustrationId: params.id, //元になったイラストのid
        requestId: result.id, //対象のリクエストid
        infomation: "部分変更リクエストを着信しました",
        type: "parcialRequest",
      }
      ifProxy.createNotification(noti);

    }).catch((e) => {
      console.log("createRequest に失敗")
      console.log(e)
    })


  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <h1>部分変更リクエスト申し込み</h1>
            <h2>変更差分の要望内容</h2>
            <TextField fullWidth multiline margin="nomal" rows={4} id="outlined-basic" label="リクエスト内容" onChange={handleThema} value={thema} variant="outlined" sx={styleDifinition.aInputForm} />
            ¥{amount}
            <TextField fullWidth margin="nomal" id="outlined-adornment-amount"
              label="オファー金額"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              onChange={handleAmount} variant="outlined" type="number" value={amount} sx={styleDifinition.aInputForm}
            />
            オファー金額は原則、1000円以上とするようにお願いいたします

            <h2>オファー条件</h2>
            <div>
              <label htmlFor="check">
                支払い条件:
              </label>
              <input
                type="checkbox"
                id="check"
                checked={tochu}
                onChange={() => setTochu(prevState => !prevState)}
              />
              {tochu ? "　【イメージに合えば報酬を支払う】" : "　【必ず報酬を支払う】"}
            </div>



            <Button
              variant="contained"
              fullWidth
              onClick={() => insert()}
              color="primary"
              sx={{ margin: 2 }}>
              リクエストを投稿する
            </Button>
          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default CreateRequest;
const styleDifinition = {
  aInputForm: {
    margin: "10px",
  },
}
const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {

    maxWidth: "1000px",
    margin: "0 auto",
    marginTop: 10,
  },
  cardList: {
  },
  card: {
    width: 500

  },
}));
