import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


import Typography from '@mui/material/Typography';


/**
 * 
 * @param {*} props uidを入れているが、実はcontextから取っているのでいらない
 * @returns 
 */
const UserBlockList = (props) => {

  const [listData, setListData] = useState(0);
  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();


  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  }, []);

  const liftBanAction = (data) => {
    ifProxy.deleteBlock(data.id).then(() => {
      window.location.reload();
    });
  }

  const loadData = async (props) => {
    //console.log(location);
    let blockUserData = await ifProxy.checkBlockingList(user.uid);
    let listItems = blockUserData.map((item) =>
      createCard(item)
    );
    setListData(listItems);

  }


  const createCard = (data) => {
    return (
      <Grid item xs={12} sm={12} md={12} >
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {data.blockDisplayName}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: 2 }}
              onClick={() => liftBanAction(data)}
            >
              ブロックを解除する
            </Button>
          </CardContent>
        </Card>
      </Grid>
    )
  }


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>

            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <h2>ブロック中のユーザ一覧</h2>
              {listData}
            </Grid>
          </Box>
        </Box>
      </HeadFooter>
    )
  }
}
export default UserBlockList;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  inputFileBtnHide: {
    opacity: 0,
    appearance: "none",
    position: "absolute"
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
  bottomCard: {
    width: 250
  },
}));
