import React, { useRef, useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import SuggestionListComponent from './SuggestionListComponent';
import Create from './../B/Create';


// Material UI
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

/** 用途：共通のプロフィール表示サイドバー
使用方法
  ページからのインポート　
  import RequestComp from '../comp/ProfileComp';

  設置
  <RequestComp uid={表示する対象のuid}/>
prop: 自分の & プロフィール画面の場合だけ uidを渡される 
    MainListでは "all" を設定する
    自分がリクエストしたを調べる場合は "my" を設定する
*/
const RequestComp = (props) => {
  const inputRef = useRef(null);
  const [inputError, setInputError] = useState(false);




  const [listItems, setListItems] = useState(0);
  const [formView, setFormView] = useState(false);
  const [requestContent, setRequestContent] = useState("");
  const [requestOffer, setRequestOffer] = useState("");


  const MAXINPUT = 120; // フォームの文字数上限
  const [requestContentCount, setRequestContentCount] = useState(0); //現在入力済みの文字数
  const [requestListComponents, setRequestListComponents] = useState([]); // リクエストリストのコンポーネントを保持する

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();



  useEffect(() => {
    loadData(props);
  }, []);


  // paramで受け取ったuidのユーザ情報を取得し表示する
  const loadData = () => {

    //データ取得
    getRequestAncCreateRequestCard();
  }


  const handelFormRequestContent = (event) => {
    // バリデーション
    if (inputRef.current) {
      const ref = inputRef.current;
      if (!ref.validity.valid) {
        setInputError(true);
      } else {
        setInputError(false);
      }
    }
    console.log(event.target.value);
    setRequestContent(event.target.value);
    setRequestContentCount(event.target.value.length);
  }
  const handelFormRequestOffer = (event) => {
    console.log(event.target.value);
    setRequestOffer(event.target.value);
  }

  /**
   * リクエストを投稿
   */
  const submitRequest = () => {

    // プライベートオファー識別
    const myRequest = props.uid;
    console.log("submitRequest")
    console.log(props.uid)
    let toUid = "";
    let openFlg = false;
    // ifProxyからリクエストデータを取得
    let requestList = [];
    if (myRequest === "all") {
      openFlg = true;
    } else {
      toUid = myRequest;
    }

    // 投入データ
    const insertData = {
      requestContent: requestContent,
      requestOffer: requestOffer,
      openFlg: openFlg,
      toUid: toUid,
      FromUid: user.uid,
    }
    ifProxy.insertRequest(insertData).then(() => {
      setRequestContent("");
      setRequestOffer(0);
      loadData();
    })

  }

  // リクエスト登録フォーム
  const formViewPage = () => {
    return (
      <Box

        sx={{
          padding: 2,
          boxShadow: 0,
          alignItems: 'center'

        }}>
        <form className={classes.root}>


          <TextField
            sx={{
              margin: 2,

            }}
            fullWidth
            error={inputError}
            inputProps={{ maxLength: 120 }}
            inputRef={inputRef}
            id="outlined-required"
            label="リクエスト本文"
            value={requestContent}
            defaultValue=""
            maxRows={4}
            onChange={handelFormRequestContent}
            placeholder="xxなキャラクターのxxなイラストがあれば、レンタルしたいのですがありますか？"
          />
          <TextField
            sx={{
              margin: 2,

            }}
            type="number"
            inputRef={inputRef}
            value={requestOffer}
            id="outlined-required"
            label="もしあったら、何円で使いたい"
            onChange={handelFormRequestOffer}
          />
        </form>
        <Button variant="contained" onClick={() => submitRequest()}
          sx={{
            margin: 2,

          }} > 投稿</Button>
        のこり文字数: {MAXINPUT - requestContentCount}

      </Box >

    )
  }



  // リクエストリスト
  const createRequestCard = (item) => {
    return (
      <>
        <Box
          sx={{
            margin: 2,
            padding: 2,
            boxShadow: 2,
            alignItems: 'center',
            overflowWrap: 'break-word'
          }}>
          <p>リクエストの内容 <br></br>
            {item.requestContent}
          </p>
          <p>
            {item.clearFlg ? `提案イラストあり ${item.suggestionCount}件の作品が投稿されました！` : "まだ提案なし"}
          </p>
          <p>
            オファー金額(月額) : ¥{item.requestOffer}
          </p>
        </Box>
        {/** リクエストに対する作品一覧 */}
        <Button variant="outlined" onClick={() => navigate(`/create/${item.id}`)} > リクエストに提案する</Button>

        {item.clearFlg && <SuggestionListComponent sugestionId={item.id}> </SuggestionListComponent>}

      </>
    )
  }

  const getRequestAncCreateRequestCard = async () => {

    const myRequest = props.uid;
    console.log("getRequestAncCreateRequestCard")
    console.log(props.uid)
    // ifProxyからリクエストデータを取得
    let requestList = [];
    if (myRequest === "all") {
      requestList = await ifProxy.getRequest();
    } else if (myRequest === "my") {
      requestList = await ifProxy.getRequestByFromUid(user.uid);
    } else {
      requestList = await ifProxy.getRequestByToUid(myRequest);
    }

    const itemView = requestList.map(item => createRequestCard(item));
    setRequestListComponents(itemView);
  }



  return (
    <Box

      sx={{
        margin: 2,
        padding: 2,
        boxShadow: 0,
        alignItems: 'center',
        overflowWrap: 'break-word'
      }}>
      <h2>リクエスト</h2>
      {(props.uid != "my") && <Typography color="text.secondary" gutterBottom>
        「もしこんなイラストがあったらつかうのにな？」と言う要望と、<br></br>
        「持ってるかもしれないので何が欲しいのか知りたい」<br></br><br></br>
        を結びつけます。探しているイラストがある方は投稿してみてください<br></br>
        用途に合う使い方がメニューにない時も、ここからリクエストをしてみてください<br></br>
        リクエストに合う絵をお持ちの作家様はご提案してみてください<br></br><br></br>

      </Typography>}
      {(props.uid == "all") && <Typography color="text.secondary" gutterBottom>
        オープンリクエストです。全体に向けてリクエストします。<br></br>
        個別のクリエイター向けのリクエストはプロフィール画面から行ってください

      </Typography>}
      {(props.uid != "my") && <Button variant={formView ? "outlined" : "contained"} onClick={() => { setFormView(pre => { setFormView(!pre) }) }} > リクエストフォーム</Button>}
      {/* リクエスト投稿フォーム */}
      {formView && formViewPage()}
      {/* リクエスト一覧 */}
      <Typography color="text.primary" >
        {(props.uid != "all" && props.uid != "my") && "あなた宛のリクエストを表示中"}
      </Typography>
      {requestListComponents}

    </Box >
  );
}

export default RequestComp;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));

