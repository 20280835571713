import React, {useState, useEffect} from "react";
import { useNavigate, useParams, Navigate,Link,useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';


// テーブル用
import { DataGrid } from '@mui/x-data-grid';
import { getLinkUtilityClass } from "@mui/material";
import { getDownloadURL } from "firebase/storage";


// テーブルの調査
// https://mui.com/components/tables/
// テーブル用サンプルデータ
/*
const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
   データを取得して整形して表示する方法
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
  
];
*/

// タブまわりの調査
// https://next--material-ui.netlify.app/ja/components/tabs/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


/*******************************************
 *  画面
 * @param {*} props 
 * @returns 
 ******************************************/
const Management = (props) => {
  /**
 * タブIndex0の表のカラム
 *   id :docId
  illustId :クリエイターのuid
  illustUid
  illustUrl
  holderUid :購入者のuid
  holderUser
  holderUserUrl :購入者のアイコンurl
  active :有効フラグ　支払いが済んでいるとtrue、未払いで月を跨ぐとfalse隣無効化、台帳から非表示
  settlement : Stripe決済id (毎月支払い毎に更新)
  createtAt: serverTimestamp()
 */

const columns0 = [
  { field: 'uid', headerName: 'ユーザ詳細',width: 150,disableClickEventBubbling: true, renderCell: (params) => <Button variant="outlined" color="secondary" onClick={() =>  navigate(`/User/${params.row.uid}`)}>詳細</Button> },
  { field: 'holderUser', headerName: 'ユーザ名', width: 130, disableClickEventBubbling: true, },
  { field: 'amount', headerName: '月額料金(JPY)', width: 130,disableClickEventBubbling: true},
  { field: 'id', headerName: 'ホルダー詳細',width: 150,disableClickEventBubbling: true, renderCell: (params) => <Button variant="outlined" color="secondary" onClick={() =>  navigate(`/holdDetail/${params.row.id}`)} >詳細</Button>},
];
const columns1 = [
  { field: 'id', headerName: 'id', width: 300, disableClickEventBubbling: true, },
  { field: 'holderUser', headerName: 'ユーザ名', width: 130, disableClickEventBubbling: true, },
  { field: 'uid', headerName: 'ユーザ詳細',width: 150,disableClickEventBubbling: true, renderCell: (params) => <Button variant="outlined" color="secondary" onClick={() =>  navigate(`/User/${params.row.uid}`)}>詳細</Button> },
];

  const [listItems, setListItems] = useState(0);
  const [value, setValue] = React.useState(0); // 現在表示しているタブのインデックス
  const [monthlySales, setMonthlySales] = React.useState(0); // 現在表示しているタブのインデックス


  const [rows0, setRows0] = useState(0); // タブIndex0のレコードデータ
  const [rows1, setRows1] = useState(0); // タブIndex1のレコードデータ


  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  useEffect((props) => {
    loadData(props);
  },[]);

  const loadData = (props) => {
    console.log("画面遷移後＝ params ＝＝＝＝＝＝＝＝＝＝")
    console.log(params);
    console.log(location);
    const ifProxy = new IfProxy();
    /*
    ifProxy.getDetailData().then((result) => {
      // paramsの値でユーザを検索

      // ユーザ情報を表示
      let item = {
        title: "TEST TITLE NAME",
        id: "1234567890",
        createUser: "createUser",
        uid: "1234567890",
        text: "lkasdjf;lajskdf;aksdjf;alsjkdf;klasjdf;l"
      }
      setListItems(item);
    })
      */

      // 表データ取得
      ifProxy.getRendingUser(user.uid).then((resultList) => {
        let detaList=[];
        // index2の利用者台帳のデータ生計と設定
        let holderList=[];
        let tempHolderUidList=[];
        for(let result of resultList){
          // index0 レンタル中一覧のデータ整形と設定
          let data = {
            id: result.id,
            uid: result.holderUid,
            holderUser:result.holderUser,
            amount:result.amount,
          }
          detaList.push(data);
          // 売り上げ合計を加算する
          setMonthlySales((pre) => {
            let preAmount = pre - 0;
            let newAmount = result.amount -0
            return preAmount+newAmount;
          })

          
          // ユーザ単位に契約情報をDISTINCTする
          // 既にいる
          if(tempHolderUidList.includes(result.holderUid)){
            //飛ばす
            continue;
          }
          let holderData = {
            id: result.holderUid,
            uid: result.holderUid,
            holderUser:result.holderUser,
            amount:result.amount,
          }
          holderList.push(holderData);
          // 既に登録済みかどうかを判定するために処理ずみを保持
          tempHolderUidList.push(result.holderUid);
        }
        setRows0(detaList);
        setRows1(holderList);
      });
      

  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

// Boxレイアウトのレスポンシブ対応
// https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>

        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{marginTop:2}} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={12} >
                <Card className={classes.card}>
                  <CardContent>
                    <h1>管理画面</h1>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      今月のレンタル収入
                    </Typography>
                    <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
                      ¥ {monthlySales}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      表示の内容は参考情報です。最新の売り上げ状況及び出金申請はStripe口座から実施可能です。
                    </Typography>
                    <a href="https://dashboard.stripe.com/dashboard">Stripe口座ダッシュボード</a>

                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="レンタル中一覧" {...a11yProps(0)} />
                          <Tab label="ユーザ台帳" {...a11yProps(1)} />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                          <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                              rows={rows0}
                              columns={columns0}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                            />
                          </div>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div style={{ height: 400, width: '100%' }}>
                          <DataGrid
                            rows={rows1}
                            columns={columns1}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                          />
                        </div>

                      </TabPanel>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </HeadFooter>

    );
  }




}
  export default Management;

  const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    contentWrapper:{
    },
    mainBox:{

    },
    cardList: {
    },
    card:{
    },
  }));
