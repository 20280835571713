import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from './../../AuthContext';
import IfProxy from './../../IfProxy';
import HeadFooter from './../HeadFooter';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';



import { createTheme, ThemeProvider } from '@mui/material/styles';

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const createCheckoutLink = "https://us-central1-app3-819e4.cloudfunctions.net/createCheckoutLink";


const Registration = (props) => {

  const [listItems, setListItems] = useState(0);
  const [mediaUseForm, setMediaUseForm] = useState("");
  const [mediaUrlForm, setMediaUrlForm] = useState("");

  // 連打防止
  const [clicking, setClicking] = useState(false);

  // チェックボックスの内容 使用者が選択したもの
  const [checkBox, setCheckBox] = useState({
    icon: true,
    material: false,
    processing: false,
    commers: false,
  })

  const [sum, setSum] = useState(0);
  const [period, setPeriod] = useState(1);

  // 初期表示時、stateの項目がまだDBから帰ってこない状態でアクセスしundefinedになるのを防ぐため、
  // stateの初期化時に、項目をからで初期化。async読み込み後にstate更新で表示する
  const [illustData, setIllustData] = useState({
    url: null,

    // 引数から設定
    uid: "",
    forIcon: "",

    freeFlg: false, //商品種別: 無料公開
    monthlyFlg: false,//商品種別: 通常レンタル
    mediaEnableFlg: false,//商品種別: 媒体単位許諾    

    MediaUseuse: "", // 媒体利用　利用媒体名
    MediaUsemediaUrl: "", // 媒体利用　利用媒体 url

    forIconAmount: "",
    forMaterial: "",
    forMaterialAmount: "",
    forProcessing: "",
    forProcessingAmount: "",
    forCommarce: "",
    forCommarceAmount: "",
    forElse: "",

    sales: "",
    nsfw: "",
    dedicated: "",

    tags: "",
    pv: "",
    bookmark: "",
  });

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();

  const location = useLocation();

  useEffect((props) => {
    loadData(params);

  }, []);

  const loadData = (props) => {
    console.log("画面遷移後＝ パスパラメータ：params ＝＝＝＝＝＝＝＝＝＝");

    console.log(params);
    console.log(location);
    const ifProxy = new IfProxy();
    ifProxy.getIllustlation(params.id).then((data) => {

      // sampleData
      let item = {
        url: data.url,

        // 引数から設定
        id: data.id,
        uid: data.uid,

        freeFlg: data.freeFlg, //商品種別: 無料公開
        monthlyFlg: data.monthlyFlg,//商品種別: 通常レンタル
        mediaEnableFlg: data.mediaEnableFlg,//商品種別: 媒体単位許諾     

        MediaUseuse: "", // 媒体利用　利用媒体名 初期値
        MediaUsemediaUrl: "", // 媒体利用　利用媒体 url　初期値

        forIcon: data.forIcon,
        forIconAmount: data.forIconAmount,
        forMaterial: data.forMaterial,
        forMaterialAmount: data.forMaterialAmount,
        forProcessing: data.forProcessing,
        forProcessingAmount: data.forProcessingAmount,
        forCommarce: data.forCommarce,
        forCommarceAmount: data.forCommarceAmount,
        forElse: data.forElse,

        thumbnail: data.thumbnail,// サムネイル

        sales: data.sales,
        nsfw: data.nsfw,
        dedicated: data.dedicated,

        tags: data.tags,
        pv: data.pv,
        bookmark: data.bookmark,
      }

      setIllustData(item);
      setSum(item.forIconAmount);

    })
  }

  // フォーム要素の操作
  //  アイコン使用について
  const handleForIcon = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(value);

    setCheckBox(prev => {
      console.log("PREV>")
      console.log(prev);
      console.log("<PREV")
      let data = {
        icon: event.target.checked,
        material: prev.material,
        processing: prev.processing,
        commers: prev.commers,
      }
      updateAmount(data);
      return data
    });


  }

  // 素材使用について
  const handleForMaterial = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log("setSelectMaterial ===" + event.target.checked)
    setCheckBox(prev => {
      console.log("PREV>")
      console.log(prev);
      console.log("<PREV")
      console.log(prev);
      let data = {
        icon: prev.icon,
        material: event.target.checked,
        processing: prev.processing,
        commers: prev.commers,
      }
      updateAmount(data);
      return data
    });
  }
  // 加工の許諾について

  const handleForProcessing = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(value)
    setCheckBox(prev => {
      console.log("PREV>")
      console.log(prev);
      console.log("<PREV")
      console.log(prev);
      let data = {
        icon: prev.icon,
        material: prev.material,
        processing: event.target.checked,
        commers: prev.commers,
      }
      updateAmount(data);
      return data
    });
  }

  // 商用利用の許諾
  const handleForCommarce = (event) => {
    console.log("FORM要素:handleForIcon event.target.value =====");
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    console.log(event.target.checked)
    setCheckBox(prev => {
      console.log("PREV>")
      console.log(prev);
      console.log("<PREV")
      console.log(prev);
      let data = {
        icon: prev.icon,
        material: prev.material,
        processing: prev.processing,
        commers: event.target.checked,
      }
      updateAmount(data);
      return data
    });
  }

  // 利用期間の選択
  const handleOneMonth = () => {
    console.log("checkboxSTSATEの中身----")
    console.log(checkBox);
    const amount = caluculateSumFromChecedMenu(checkBox);
    // 使用期間を追加
    setSum(1 * amount)
    setPeriod(1)

  }
  const handleOneYear = () => {
    console.log("checkboxSTSATEの中身----")
    console.log(checkBox);
    const amount = caluculateSumFromChecedMenu(checkBox);
    // 使用期間を追加
    setSum(12 * amount)
    setPeriod(12)
  }


  /**
   * 利用登録：無料
   */
  const settlementFree = async () => {
    // クリック処理中
    setClicking(true);

    const ifProxy = new IfProxy();
    const userData = await ifProxy.getUserData(illustData.uid);
    const holderUserData = await ifProxy.getUserData(user.uid);

    let data = {
      illustId: illustData.id,
      illustUid: illustData.uid, //イラスト提供者uid
      illustUrl: illustData.url,
      thumbnail: illustData.thumbnail,
      holderUid: user.uid,
      holderUser: user.displayName,
      holderUserUrl: user.photoURL,
      twitterid: holderUserData.name, // 購入者に期限切れを連絡するため、通知でメンション時に利用するためholderに持たせる
      settlement: "", //Stripe決済id

      // 購入した使用権
      freeFlg: illustData.freeFlg, //商品種別: 無料公開
      monthlyFlg: illustData.monthlyFlg,//商品種別: 通常レンタル
      mediaEnableFlg: illustData.mediaEnableFlg,//商品種別: 媒体単位許諾     

      MediaUseuse: mediaUseForm, // 媒体利用　利用媒体名
      MediaUsemediaUrl: mediaUrlForm, // 媒体利用　利用媒体 url

      icon: checkBox.icon,
      material: checkBox.material,
      processing: checkBox.processing,
      commers: checkBox.commers,

      // nullpointerを避けるため空値を設定
      stripeAccount: "", // 決済情報を取得するために、決裁を持つアカウントのidを保持
      paymentIntentId: "", // 決済情報のid 取れない
      cs_: "",// 対応するStripe上の決済はcs_ で識別
      paymentStatus: "",

      // 期間
      period: 1000,//stateから取得 何ヶ月分買ったかを持つ 無期限。一旦1000ヶ月を設定
      // 終了日deadlineは登録時に設定する
      // 購入した金額
      amount: 0,
      // 支払い先のStripeId
      stripeId: "free",
      paymentIntentId: "free"
    }
    await ifProxy.addHolder(data).then((responce) => {
      console.log("addHolder =======");
      console.log(responce);
      navigate('/HoldDetail/' + responce);
      if (responce == "error") {

      }
    });
  }

  /**
   * 利用登録：決済処理
   */
  const settlementRequest = async () => {
    // クリック処理中
    setClicking(true);

    console.log("USER======");
    console.log(user)
    // Stripe支払いの宛先をUserから取得する
    const ifProxy = new IfProxy();
    const userData = await ifProxy.getUserData(illustData.uid);
    const holderUserData = await ifProxy.getUserData(user.uid);


    let tmpPeriod = period
    // 媒体登録で期限が無期限の場合はperiodに"1000"区分を付与する
    if (illustData.mediaEnableFlg) {
      tmpPeriod = 1000;
    }
    let data = {
      illustId: illustData.id,
      illustUid: illustData.uid, //イラスト提供者uid
      illustUrl: illustData.url,
      thumbnail: illustData.thumbnail,
      holderUid: user.uid,
      holderUser: user.displayName,
      holderUserUrl: user.photoURL,
      twitterid: holderUserData.name, // 購入者に期限切れを連絡するため、通知でメンション時に利用するためholderに持たせる
      settlement: "", //Stripe決済id

      // 購入した使用権
      freeFlg: illustData.freeFlg, //商品種別: 無料公開
      monthlyFlg: illustData.monthlyFlg,//商品種別: 通常レンタル
      mediaEnableFlg: illustData.mediaEnableFlg,//商品種別: 媒体単位許諾     

      MediaUseuse: mediaUseForm, // 媒体利用　利用媒体名
      MediaUsemediaUrl: mediaUrlForm, // 媒体利用　利用媒体 url

      icon: checkBox.icon,
      material: checkBox.material,
      processing: checkBox.processing,
      commers: checkBox.commers,

      // 期間
      period: tmpPeriod,//stateから取得 何ヶ月分買ったかを持つ
      // 終了日deadlineは登録時に設定する
      // 購入した金額
      amount: sum,
      // 支払い先のStripeId
      stripeId: userData.stripeId
    }


    // Stripe決済画面へ遷移


    // 決済リンクを作成
    const addMessage = httpsCallable(functions, 'createCheckoutLink');
    await addMessage(data)
      .then(async (result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        console.log("---ON CALL LOCAL TEST ========")
        console.log(result);
        const accountData = result.data.result.result.url;
        // Stripe_eventを特定するためのpaymentIntentを取得
        const paymentIntent = result.data.result.result.payment_intent;
        const stripeAccount = userData.stripeId;
        console.log("Payment Intent")
        console.log(paymentIntent);
        console.log("ACCOUNT DATA URL")
        console.log(accountData);
        window.open(accountData, '_blank');
        // 購入の完了(Holder)を仮作成。
        // WebHocで決済処理完了で有効化する
        data.stripeAccount = stripeAccount;
        data.paymentIntentId = paymentIntent;
        data.paymentStatus = false; //いったんステータスはfalseにしておく
        data.cs_ = result.data.result.result.id;

        await ifProxy.addHolder(data).then((responce) => {
          console.log("addHolder =======");
          console.log(responce);
          navigate('/HoldDetail/' + responce);
          if (responce == "error") {

          }
        }).catch((error) => {
          console.log("addHolder コール失敗");
          console.log(error);
        })
      })
      .catch((error) => {
        console.log("createCheckoutLink コール失敗");
        console.log(error);
      });


  }

  // レンタル販売中のメニューと金額。イラストに設定されているメニューだけ表示する
  const menuLabel = () => {

    return (
      <Box>
        <ul>
          <FormControl component="fieldset">
            <FormGroup>

              {illustData.forIcon &&
                <li>
                  <FormControlLabel control={<Checkbox defaultChecked onChange={handleForIcon} />} label="SNSアイコンとしての使用" />

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    使用料 ¥{illustData.forIconAmount}
                  </Typography>
                </li>
              }
              {illustData.forMaterial &&
                <li>
                  <FormControlLabel control={<Checkbox onChange={handleForMaterial} />} label="画像素材としての利用(icon以外のSNS・ブログ・動画での利用)" />

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    使用料 ¥{illustData.forMaterialAmount} <br></br>
                    SNS投稿、ブログ、webサイトでの掲載
                  </Typography>
                </li>
              }
              {illustData.forProcessing &&
                <li>
                  <FormControlLabel control={<Checkbox onChange={handleForProcessing} />} label="加工(SNS投稿時に発生するトリミング以外の加工)" />

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    使用料 ¥{illustData.forProcessingAmount} <br></br>
                  </Typography>
                </li>
              }
              {illustData.forCommarce &&
                <li>
                  <FormControlLabel control={<Checkbox onChange={handleForCommarce} />} label="商用利用" />

                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    使用料 ¥{illustData.forCommarceAmount} <br></br>
                  </Typography>
                </li>
              }
              {illustData.forElse != "" &&
                <li>
                  その他使用制限<br></br>
                  {illustData.forElse}

                </li>
              }
            </FormGroup>
          </FormControl>
          {illustData.monthlyFlg &&
            <li>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">許諾を購入する使用期間</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="oneMonth" control={<Radio onChange={handleOneMonth} />} label="1ヶ月" />
                  <FormControlLabel value="oneYear" control={<Radio onChange={handleOneYear} />} label="1年" />
                </RadioGroup>
              </FormControl>
            </li>}

        </ul>

      </Box>

    )
  }
  const updateAmount = (data) => {
    const amount = caluculateSumFromChecedMenu(data);
    console.log("金額")
    console.log(amount);
    console.log("期間")
    console.log(period); // これはstateから持ってきている
    // 使用期間を追加
    const multipAmount = period * amount;
    setSum(multipAmount)
  }

  // 一月分の合計額を選んだメニューから算出する
  const caluculateSumFromChecedMenu = (data) => {
    let amount = 0;
    if (data.icon) {
      amount += parseInt(illustData.forIconAmount);
    }
    if (data.material) {
      amount += parseInt(illustData.forMaterialAmount);
    }
    if (data.processing) {
      amount += parseInt(illustData.forProcessingAmount);
    }
    if (data.commers) {
      amount += parseInt(illustData.forCommarceAmount);
    }
    return amount;
  }

  const debug = () => {
    if (false) {
      return (
        <Box>
          現在のcheckBoxステートの内容 <br></br>
          {JSON.stringify(checkBox)}
        </Box>
      )
    }
  }

  // 媒体登録フォーム
  const handleMediaUseForm = (event) => {
    console.log("FORM要素:媒体登録 フォーム入力値 event.target.value =====");
    console.log(event.target.value);
    setMediaUseForm(event.target.value);
  }
  const handleMediaUrlForm = (event) => {
    console.log("FORM要素:媒体登録 フォーム入力値 event.target.value =====");
    console.log(event.target.value);
    setMediaUrlForm(event.target.value);
  }

  /**
   * 販売イラストの種類ごとにメニューを出し分ける
   * @returns 
   */
  const rentalMenyu = () => {
    if (illustData.freeFlg) {
      return (
        <box>
          <Typography variant="h2" color="text.secondary" gutterBottom>
            無料
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            登録後は「利用可能な範囲」で無料でイラストの利用を行ってください
          </Typography>
          {illustData.forElse !== "" &&
            <li>
              利用可能な範囲<br></br>
              {illustData.forElse}

            </li>
          }
          < Typography sx={{ fontSize: 30 }
          } color="text.secondary" gutterBottom >
            ¥ 0
          </Typography >
        </box>
      )

    }
    if (illustData.monthlyFlg) {
      return (
        <box>
          <Typography variant="h2" color="text.secondary" gutterBottom>
            レンタル
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            購入から利用期限まで利用が可能です<br></br>
            期限を超過したら利用を終了するかまたは再購入を行ってください<br></br>
          </Typography>
          {menuLabel()}
          < Typography sx={{ fontSize: 30 }
          } color="text.secondary" gutterBottom >
            ¥ {sum} / 月額 (決済手数料含む)
          </Typography >
        </box>
      )
    }
    if (illustData.mediaEnableFlg) {
      return (
        <box>
          <Typography variant="h2" color="text.secondary" gutterBottom>
            媒体利用
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            以下いずれかの範囲での利用を、無期限(作者が公開を終了するまで）利用できます<br></br>
            動画・配信活動ならば、１チャンネル<br></br>
            SNSアカウントならば、１アカウント<br></br>
            (同人誌・ダウンロード作品での販売の場合は１作品(タイトル))<br></br>
          </Typography>
          {menuLabel()}

          <Typography sx={{ fontSize: 30 }} color="text.secondary" gutterBottom>
            ¥ {sum} / １チャンネル (決済手数料含む)
          </Typography>
          <TextField
            fullWidth
            sx={{ margin: 2 }}
            id="outlined-required"
            label="利用チャンネル・アカウント名(必須)"
            value={mediaUseForm}
            type="text"
            onChange={handleMediaUseForm}
          />
          <TextField
            fullWidth
            sx={{ margin: 2 }}
            id="outlined-required"
            label="利用サイトURL(必須)"
            value={mediaUrlForm}
            type="text"
            onChange={handleMediaUrlForm}
          />
          {mediaUseForm && mediaUrlForm ? "OK" : "必須項目未入力"}
        </box>

      )
    }
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={12} >
                <Card className={classes.card}>
                  <CardContent>
                    <h1>レンタル開始登録</h1>
                    <CardMedia
                      component="img"
                      image={illustData.url}
                      alt="画像"
                      sx={{
                        width: 300,
                        padding: 2,
                        boxShadow: 1,
                        alignItems: 'center'

                      }}
                    />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      データ番号:{illustData.id}
                    </Typography>
                    <Typography variant="h5" component="div">
                      使用権レンタルメニュー
                    </Typography>
                    {rentalMenyu()}


                  </CardContent>
                  <CardActions>
                    {(!clicking && illustData.freeFlg) &&
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        sx={{ margin: 2 }}
                        onClick={settlementFree}
                      >利用登録する</Button>
                    }
                    {clicking && <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled
                      sx={{ margin: 2 }}
                      onClick={settlementRequest}
                    >決済する 処理中</Button>
                    }

                    {(!clicking && illustData.mediaEnableFlg) && <Button
                      disabled={!(mediaUseForm && mediaUrlForm)}
                      size="large"
                      variant="contained"
                      color="primary"
                      sx={{ margin: 2 }}
                      onClick={settlementRequest}
                    >決済する</Button>
                    }
                    {(!clicking && illustData.monthlyFlg) && <Button
                      disabled={sum < 1}
                      size="large"
                      variant="contained"
                      color="primary"
                      sx={{ margin: 2 }}
                      onClick={settlementRequest}
                    >決済する</Button>
                    }


                  </CardActions>

                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>


      </HeadFooter>

    );

  }





}
export default Registration;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
