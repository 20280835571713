import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';
import ProfileComp from '../comp/ProfileComp';


// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const HoldDetail = (props) => {

  const [listItems, setListItems] = useState(0);
  const [illustUid, setIllustUid] = useState(0);

  const [kessaiStatus, setKessaiStatus] = useState(false);

  // 初期表示時、stateの項目がまだDBから帰ってこない状態でアクセスしundefinedになるのを防ぐため、
  // stateの初期化時に、項目をからで初期化。async読み込み後にstate更新で表示する
  // illustDataはHolderオブジェクト
  const [illustData, setIllustData] = useState({
    url: null,

    // 引数から設定
    id: "",
    uid: "",
    forIcon: "",

    freeFlg: "", //商品種別: 無料公開
    monthlyFlg: "",//商品種別: 通常レンタル
    mediaEnableFlg: "",//商品種別: 媒体単位許諾    

    MediaUseuse: "", // 媒体利用　利用媒体名
    MediaUsemediaUrl: "", // 媒体利用　利用媒体 url

    forIconAmount: "",
    forMaterial: "",
    forMaterialAmount: "",
    forProcessing: "",
    forProcessingAmount: "",
    forCommarce: "",
    forCommarceAmount: "",
    forElse: "",

    sales: "",
    nsfw: "",
    dedicated: "",

    tags: "",
    pv: "",
    bookmark: ""
  });

  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    loadData(params);
  }, []);

  const loadData = (props) => {
    console.log("画面遷移後＝ パスパラメータ：params ＝＝＝＝＝＝＝＝＝＝");

    console.log(params);
    console.log(location);
    ifProxy.getHold(params.id).then((data) => {

      let item = {
        id: data.id,
        illustId: data.illustId,
        illustUid: data.illustUid,
        illustUrl: data.illustUrl,
        holderUid: data.holderUid,
        holderUser: data.holderUser,
        holderUserUrl: data.holderUserUrl,
        // 保有権利
        // 購入した使用権
        freeFlg: data.freeFlg, //商品種別: 無料公開
        monthlyFlg: data.monthlyFlg,//商品種別: 通常レンタル
        mediaEnableFlg: data.mediaEnableFlg,//商品種別: 媒体単位許諾     

        MediaUseuse: data.MediaUseuse, // 媒体利用　利用媒体名
        MediaUsemediaUrl: data.MediaUsemediaUrl, // 媒体利用　利用媒体 url

        paymentIntentId: data.paymentIntentId, // pi ペイメントインテント　支払い情報のid(支払い先)
        stripeAccount: data.stripeAccount,// stripeAccount stripeユーザのid(支払い先)
        paymentStatus: data.paymentStatus,

        icon: data.icon,
        material: data.material,
        processing: data.processing,
        commers: data.commers,
        deadline: data.deadline, //失効日
        deadlineLabel: ifProxy.formatFirebaseTimestampToString(data.deadline), // 失効日表示値
        deadlineOverFlg: ifProxy.overFirebaseTimestampDate(data.deadline), // 過ぎているかどうか
        active: data.active,
        valid: data.valid,
        settlement: data.settlement, //Stripe決済id
        createtAt: data.createtAt
      }
      console.log("ILLUST UID ================")
      console.log(item.illustUid)
      setIllustUid(item.illustUid);
      setIllustData(item);
      // 決済ステータス。validそのままでもいいが、画面からpaymentIntentをとる場合もある。それをしない場合は、webhocの結果を入れる
      setKessaiStatus(item.valid);

    })
  }

  const checkKessaiStatus = (item) => {
    const ifProxy = new IfProxy();

    console.log("========== PAYMENT INTENT=")
    console.log(item.paymentIntentId)
    console.log("========== stripeAccount=")
    console.log(item.stripeAccount)

    // TODO 
    // 決済時にHolderにPaymentIntentを持たせる。
    // いったんサンプル値
    const pi_ = item.paymentIntentId;
    const stripeAccount = item.stripeAccount

    ifProxy.getPaymentIntent(pi_, stripeAccount).then((result) => {
      setKessaiStatus(result);
      illustData.paymentStatus = result;
      ifProxy.updatePaymentStatus(item)
    })
  }

  // 申し込み画面へ遷移
  const home = () => {
    navigate('/');
  }
  // LPへ遷移
  const goLp = () => {
    navigate('/LpCreator');
  }

  const onClickSaikounyu = () => {
    console.log("再購入_商品購入画面に遷移");
    navigate(`/Detail/${illustData.illustId}`);
  }

  const ejectHolder = () => {
    console.log("ID======>>>>>>")
    console.log(illustData.id)
    ifProxy.offHolder(illustData.id).then(() => {
      navigate('/Detail/' + illustData.illustId);
    })

  }
  const illustlationDetail = () => {
    console.log("ID======>>>>>>")
    console.log(illustData.id)
    navigate('/Detail/' + illustData.illustId);

  }

  // レンタル販売中のメニューと金額。イラストに設定されているメニューだけ表示する
  const menuLabelFree = () => {
    return (
      <box>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          無料利用登録済み
          <br></br>
          以下の利用範囲での使用が許諾されています
        </Typography>
        {illustData.forElse !== "" &&
          <li>
            利用可能な範囲<br></br>
            {illustData.forElse}

          </li>
        }

      </box>
    )
  }

  /**
   * 画像の用途、許諾されているチャンネルを表示
   */
  const useLabel = () => {
    if (illustData.mediaEnableFlg) {
      return (
        <Box>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {illustData.MediaUseuse}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <a href={illustData.MediaUsemediaUrl}> {illustData.MediaUsemediaUrl}</a>
          </Typography>
        </Box>
      )
    }


  }
  const menuLabel = () => {

    return (
      <Box>
        <ul>

          {illustData.icon &&
            <li>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                SNSアイコンとしての使用
              </Typography>
            </li>
          }
          {illustData.material &&
            <li>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                素材使用<br></br>
                SNS投稿、ブログ、webサイトでの掲載
              </Typography>
            </li>
          }
          {illustData.processing &&
            <li>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                加工 <br></br>
                上記使用に加え、画像加工の許諾あり (作成者クレジット 要)
              </Typography>
            </li>
          }
          {illustData.commers &&
            <li>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                商用利用 <br></br>
                イラストを利用した制作物の販売 (作成者クレジット 要)
              </Typography>
            </li>
          }
          {illustData.forElse != "" &&
            <li>
              その他使用制限<br></br>
              {illustData.forElse}

            </li>
          }
        </ul>
      </Box>

    )
  }

  //有効期限オーバーコンポーネント
  const deadlineOverComponent = (item) => {

    // 期限終了メッセージ
    if (item.deadlineOverFlg && item.active) {
      return (
        <Box>
          <Typography variant="h5" component="div" color="red">
            有効期間を過ぎています
          </Typography>
          <Typography sx={{ fontSize: 14, padding: 2 }} color="text.secondary" gutterBottom>
            有効期限超過時の対処方法 <br></br>
            使用期限が過ぎているイラストの使用は許諾されておりません。本画像を使用しているアイコン、掲載サイト等での掲示を終了してください。印刷物の場合は今後の頒布はお控えください。
            <br></br>
            <br></br>
            <b>継続して利用する場合</b><br></br>
            再度使用権を購入する必要があります。「再購入し使用を継続する」ボタンから再購入をしてください。<br></br>
            再購入決済が完了したら、執行した保有使用者証明画面に戻り、「使用を終了する」ボタンから終了してください。
            <br></br>
            <br></br>
            <b>利用を終了する場合</b><br></br>
            「使用を終了する」ボタンから終了してください。

          </Typography>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onClickSaikounyu}
          >再購入し使用を継続する
          </Button>
        </Box>
      )
    }

    // 公開終了メッセージ
    if (!item.active) {
      return (
        <Box>
          <Typography variant="h5" component="div" color="red">
            作者が公開を終了しました
          </Typography>
          <Typography sx={{ fontSize: 14, padding: 2 }} color="text.secondary" gutterBottom>
            権利者からの意向で利用を終了することとなりました。申し訳ございませんが作品の利用のを終了していただくようご理解ご協力をお願いいたします。
          </Typography>
        </Box>
      )
    }
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったら、証明専用画面とLPへのリンクだけ
  if (!user) {
    return (
      <Box>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={8} >
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      使用者証明画面
                    </Typography>


                    {(!illustData.freeFlg && kessaiStatus) && <Typography variant="body2" color="text.secondary" gutterBottom>
                      報酬支払い完了
                    </Typography>}
                    {(!illustData.freeFlg && !kessaiStatus) &&
                      <Box>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          仮登録　決済処理待ち
                        </Typography>
                        <Typography variant="body2" color="red" gutterBottom>
                          時間をおいて状態更新し改善がない場合。お支払いは行われておりません。画面下「使用を終了」し、再購入してください。
                        </Typography>
                        <Button variant="contained" onClick={() => checkKessaiStatus(illustData)}>決済状態を更新</Button>
                      </Box>
                    }
                    <CardMedia
                      component="img"
                      image={illustData.illustUrl}
                      alt="画像"
                      sx={{
                        width: 500,
                        padding: 2,
                        boxShadow: 1,
                        alignItems: 'center'
                      }}
                    />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      データ番号:{illustData.id} <br></br>
                      使用者は上画像を右クリックまたはタップ長押しでダウンロードしてください
                    </Typography>

                    <Typography variant="h5" component="div">
                      次のユーザは、クリエイターから次の権利を許諾されています
                    </Typography>

                    <Avatar src={illustData.holderUserUrl} sx={{ width: 100, height: 100 }} />
                    <Typography variant="h5" component="div">
                      {illustData.holderUser}
                    </Typography>
                    {illustData.freeFlg ? menuLabelFree() : menuLabel()}

                  </CardContent>

                  {(!illustData.freeFlg && !illustData.mediaEnableFlg) &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      有効期限:{illustData.deadlineLabel}
                    </Typography>}

                  {useLabel()}

                  {deadlineOverComponent(illustData)}

                  <CardActions>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      sx={{ margin: 2 }}
                      onClick={goLp}
                    >このイラストの利用申し込みをするには</Button>
                  </CardActions>

                </Card>
              </Grid>

              <Grid item xs={12} sm={12} md={4} >
                <Card className={classes.card}>
                  <Typography variant="h5" component="div">
                    この許諾は次の作者から承認されています
                  </Typography>
                  {illustUid && <ProfileComp uid={illustUid} />}
                </Card>

              </Grid>
            </Grid>

          </Box>
        </Box>
      </Box >

    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <Grid container justify="center" spacing={3} sx={{ marginTop: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid item xs={12} sm={12} md={8} >
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      使用者証明画面
                    </Typography>

                    {(!illustData.freeFlg && kessaiStatus) && <Typography variant="body2" color="text.secondary" gutterBottom>
                      報酬支払い完了
                    </Typography>}
                    {(!illustData.freeFlg && !kessaiStatus) &&
                      <Box>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          仮登録　決済処理待ち
                        </Typography>
                        <Typography variant="body2" color="red" gutterBottom>
                          時間をおいて状態更新し改善がない場合。お支払いは行われておりません。画面下「使用を終了」し、再購入してください。
                        </Typography>
                        <Button variant="contained" onClick={() => checkKessaiStatus(illustData)}>決済状態を更新</Button>
                      </Box>
                    }
                    <CardMedia
                      component="img"
                      image={illustData.illustUrl}
                      alt="画像"
                      sx={{
                        width: 500,
                        padding: 2,
                        boxShadow: 1,
                        alignItems: 'center'
                      }}
                    />
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      データ番号:{illustData.id} <br></br>
                      使用者は上画像を右クリックまたはタップ長押しでダウンロードしてください
                    </Typography>

                    <Typography variant="h5" component="div">
                      次のユーザは、クリエイターから次の権利を許諾されています
                    </Typography>

                    <Avatar src={illustData.holderUserUrl} sx={{ width: 100, height: 100 }} />
                    <Typography variant="h5" component="div">
                      {illustData.holderUser}
                    </Typography>
                    {illustData.freeFlg ? menuLabelFree() : menuLabel()}

                  </CardContent>
                  {(!illustData.freeFlg && !illustData.mediaEnableFlg) &&
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      有効期限:{illustData.deadlineLabel}
                    </Typography>}

                  {useLabel()}

                  {deadlineOverComponent(illustData)}

                  <CardActions>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      sx={{ margin: 2 }}
                      onClick={home}
                    >戻る</Button>
                    {illustData.holderUid === user.uid &&
                      <Button
                        size="large"
                        variant="outlined"
                        color="secondary"
                        sx={{ margin: 2 }}
                        onClick={ejectHolder}
                      > 使用を終了する</Button>
                    }
                    <Button
                      size="large"
                      variant="outlined"
                      color="secondary"
                      sx={{ margin: 2 }}
                      onClick={illustlationDetail}
                    > 作品ページへ</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4} >

                <Card className={classes.card}>
                  <Typography variant="h5" component="div">
                    この許諾は次の作者から承認されています
                  </Typography>
                  {illustUid && <ProfileComp uid={illustUid} />}
                </Card>

              </Grid>

            </Grid>
          </Box>
        </Box>


      </HeadFooter>

    );
  }




}
export default HoldDetail;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
  },
  mainBox: {

  },
  cardList: {
  },
  card: {
  },
}));
