import React from "react";
import { useRef } from 'react';
import { useState } from 'react';

// 標準(A~E)はファイル配置の階層が深くなるため、読み込みパスに注意
import IfProxy from '../../IfProxy';
import { useAuthContext } from '../../AuthContext';

import { Link, Navigate, useNavigate } from "react-router-dom";

// Material UI
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export default function (props) {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const classes = useStyles();

    // もし既にログイン中であれば、サインアップ画面ではなくメインに飛ばす
    if (user) {
        return <TmpTwitterToEmailLogin2 {...props} user={user} navigate={navigate} classes={classes} />;
    } else {
        return <Navigate to="/Login" />;
    }
}

/* 認証プロバイダ移行用 */
class TmpTwitterToEmailLogin2 extends React.Component {

    constructor(props) {
        super(props);

    }

    updateConpleated = async () => {
        this.props.navigate('../MainList');
    }

    render() {

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Typography component="h1" variant="h5">
                        Eメールログインへのアカウント切り替えが完了いたしました。ご協力いただきましてありがとうございました。
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.updateConpleated}
                            sx={{ mt: 3, mb: 2, marginTop: 5 }}
                        >
                            完了
                        </Button>




                    </Box>
                </Box>
            </Container>
        );

    };
}

const useStyles = makeStyles((theme) => ({
    // 全体レイアウト
    logo: {
        width: 200,
        margin: 20,
    },
    underLink: {
        margin: 5,
    },
}));
