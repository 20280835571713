import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Navigate, useLocation } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import { useAuthContext } from '../../AuthContext';

// TODO: StandardPageはパスが異なるため注意
import IfProxy from '../../IfProxy';
import HeadFooter from '../HeadFooter';

import TagInput from "../comp/TagInput";
import UploadImage from "../comp/UploadImage";


// Material UI
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';


const CreateProduct = (props) => {


  const [loading, setLoading] = useState(true);

  const [blobDataResult, setBlobDataResult] = useState(0);

  const [requestId, setRequestId] = useState("");
  const [image, setImage] = useState("");
  const [onedroTags, setOnedroTags] = useState(""); // productに設定したタグ
  const [tags, setTags] = useState("");// productに設定したタグ
  const [onedroTagsSag, setOnedroTagsSag] = useState(""); // productに設定したタグ
  const [tagsSag, setTagsSag] = useState("");// productに設定したタグ
  const [anserLimit, setAnserLimit] = useState("");
  const [dereveryLimit, setDereveryLimit] = useState("");
  const [completetTerm, setCompletetTerm] = useState("");
  const [status, setStatus] = useState("");
  const [fromUid, setFromUid] = useState("");
  const [toUid, setToUid] = useState("");
  const [toName, setToName] = useState("");
  const [fromIcon, setFromIcon] = useState("");
  const [toIcon, setToIcon] = useState("");

  const [nsfw, setNsfw] = useState(true);

  const [thema, setThema] = useState("");
  const [fromName, setFromName] = useState("");
  const [onedroTagsRequest, setOnedroTagsRequest] = useState("");// こっちはリクエストの持っていたタグ
  const [tagsRequest, setTagsRequest] = useState("");// こっちはリクエストの持っていたタグ
  const [requestAmount, setRequestAmount] = useState(0);



  //表示用
  const auth = getAuth();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const classes = useStyles();
  const params = useParams();
  const ifProxy = new IfProxy();

  const location = useLocation();

  useEffect((props) => {
    setLoading(true)

    loadData(props);
  }, []);


  const loadData = (props) => {

    ifProxy.getTag("ワンドロ企画", 10).then((result) => {
      setOnedroTagsSag(() => result)
      console.log(result)
    })
    ifProxy.getTag("タグ", 10).then((result) => {
      setTagsSag(() => result)
      console.log(result)
      setLoading(false)

    })
    // データコール
    ifProxy.getRequestByid(params.id).then((result) => {

      // 今日が受諾日から何日経ったか計算
      let today = new Date();//今日
      let diffMilliSec = today - result.createdAt.toDate();//リクエストの作成日
      const diffDays = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);//今日-作成日

      setRequestId(params.id);
      setAnserLimit(result.anserLimit);
      setDereveryLimit(result.dereveryLimit)
      setCompletetTerm(diffDays)
      setStatus("completed")
      setToUid(result.toUid)
      setToIcon(result.toIcon)
      setToName(result.toName)
      setFromUid(result.fromUid)
      setFromIcon(result.fromIcon)
      setFromName(result.fromName)
      setThema(result.thema)
      setOnedroTagsRequest(result.onedroTagString.join(',')) // こっちはリクエストの持っていたタグ
      setTagsRequest(result.tagsString.join(','))// こっちはリクエストの持っていたタグ

      setRequestAmount(result.amount)

      setLoading(false)

    })
  }

  const submit = () => {
    ifProxy.insertTag(tags, "タグ");
    ifProxy.insertTag(onedroTags, "ワンドロ企画");

    ifProxy.createProduct({
      requestId,
      blobDataResult,
      onedroTags,
      tags,
      anserLimit,
      dereveryLimit,
      completetTerm,
      status,
      fromUid,
      fromName,
      fromIcon,
      toUid,
      toName,
      toIcon,
      requestAmount,
      thema,
      nsfw
    }).then(result => navigate('/Product/' + result.id))

  }

  // 戻る
  const abort = async () => {
    //requwstのステータスをentrusted 受諾まで戻す
    await ifProxy.entrusted(params.id).then(() => {
      navigate('/Request/' + params.id)
    })
  }


  /*
  * @param {Blob} blobData 
  */
  const returnBlob = ({ blobData, localUrl }) => {
    // 親画面の画像データを実際に取り扱うstateに設定
    setBlobDataResult(blobData);
    setImage(localUrl)
  }


  const returnTags = (tag) => {
    // 作品名のタグ
    setTags(tag)
  }
  const returnKikakuTags = (tag) => {
    // 企画名のタグ
    setOnedroTags(tag)
  }



  // ログイン中かどうかを確認し、ログインユーザを保持していなかったらログイン画面へ
  if (!user) {
    return <Navigate to="/login" />;
  } else if (loading) {
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox}>
            <p>読み込み中...</p>
          </Box>
        </Box>
      </HeadFooter>
    )
  } else {

    // Boxレイアウトのレスポンシブ対応
    // https://mui.com/components/grid/#responsive-values
    return (
      <HeadFooter>
        <Box className={classes.contentWrapper}>
          <Box className={classes.mainBox} >
            <Card sx={{ margin: 2 }}>
              <CardContent>
                <h2>依頼概要</h2>
                <Typography variant="body2">

                </Typography>
                <h3>依頼者</h3>
                <Typography variant="body2">
                  {fromName}
                </Typography>
                <h3>内容</h3>
                <Typography variant="body2">
                  {thema}
                </Typography>
                <h3>ワンドロ名</h3>

                <h2>{onedroTagsRequest}</h2>
                <Typography variant="body2">
                  ※ 実際に参加するワンドロ企画はクリエイターの自由です<br></br>
                  作品を投稿する参加企画を限定するわけではなく。参考情報としての提示です
                </Typography>
                <h3>タグ</h3>

                <h2>{tagsRequest}</h2>
                <Typography variant="body2">
                  ※ 作品に付与されるタグを制限するものではなく、リクエストのオーダー内容についてのタグです<br></br>
                  クリエイターは指定されたタグはあくまで参考程度に利用します。
                </Typography>

              </CardContent>
            </Card>
            <UploadImage returnBlob={(blob) => returnBlob(blob)}></UploadImage>
            <h2>作品タグ</h2>
            <TagInput type="form" resultTags={(tags) => returnTags(tags)} suggestion={tagsSag}></TagInput>
            <h2>ワンドロ企画名（任意）</h2>
            <TagInput type="formKikaku" resultTags={(tags) => returnKikakuTags(tags)} suggestion={onedroTagsSag}></TagInput>
            <Box className={classes.leftBox} >
              <h2>NSFW : </h2>
              <input
                type="checkbox"
                id="check"
                checked={nsfw}
                onChange={() => setNsfw(prevState => !prevState)}
              />
              <h2>
                {nsfw ? "ON" : "OFF"}
              </h2>

            </Box>
            <Button
              variant="contained"
              fullWidth
              disabled={(image === "")}
              onClick={() => submit()}
              color="primary"
              sx={{ margin: 2 }}>
              登録
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={() => abort()}
              color="primary"
              sx={{ margin: 2 }}>
              戻る
            </Button>
          </Box>
        </Box>

      </HeadFooter >

    );
  }




}
export default CreateProduct;

const useStyles = makeStyles((theme) => ({
  // 全体レイアウト
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  mainBox: {
    maxWidth: "1000px",
    margin: "0 auto",
  },
  centerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'row',
    margin: "0 auto",
  },
  cardList: {
  },
  card: {
  },
}));
